import React, { useContext } from "react";
import { IoMdClose } from "react-icons/io";

export const PopupWindowContext = React.createContext();

const PopupWindow = () => {
  const [{ isVisible, title, element, handleClose }, dispatch] =
    useContext(PopupWindowContext);

  return (
    <div
      className={`${
        isVisible && "z-30 w-screen h-screen"
      } select-none absolute top-1/2 right-1/2 translate-x-1/2 -translate-y-1/2 flex justify-center items-center`}
    >
      <div
        className={`${
          !isVisible && "hidden"
        } bg-white border-2 border-dark-gray h-screen w-screen lg:h-fit lg:w-[80vw] shadow-xl rounded-lg flex flex-col space-y-4 items-start justify-start font-nunito p-6`}
      >
          <div className="flex items-center w-full border-b border-b-dark-gray pb-6">
                <h1 className="text-2xl font-bold">{title}</h1>
                <div className="flex-1"></div>
                <button onClick={e => handleClose(e)} className="border-red border-2 bg-light-red rounded-full p-1">
                    <IoMdClose size={16} className="text-red"/>
                </button>
          </div>
          <div className="w-full">
              {element}
          </div>
      </div>
    </div>
  );
};

export default PopupWindow;
