import React, { useContext, useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import API from '../../components/Api/Axios';
import { AUTH_REFRESH_TOKEN } from '../../components/Api/Endpoints';
import { AuthContext } from '../../components/Auth/AuthContext';

const ReviewerHome = () => {

    const location = useLocation();

    const { auth, setAuthState } = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {

        if (auth) {

            const { refreshToken } = auth;

            API.post(AUTH_REFRESH_TOKEN, {
                refreshToken: refreshToken
            }).then(res => {

                const data = res.data.data;

                if (data.role === "REVIEWER" || data.role === "CHAIR" || data.role === "ADMIN") {
                    setAuthState(data);
                    navigate(location.pathname);
                } else {
                    navigate('/login');
                }

            }).catch((err) => {
                navigate('/login');
            })

        } else {
            navigate('/login');
        }

    }, []);

    useEffect(() => {
        if (auth === null || auth === undefined || auth === {}) {
            navigate('/login');
        }
    }, [auth]);


    return (
        <Outlet />
    );
};

export default ReviewerHome;