import React, { useState, useContext } from "react";
import Inputfield from "../../components/Forms/Inputfield";
import { PopupContext } from "../../components/PopupSystem/Popup";
import { BsCheck2 } from "react-icons/bs";

const EventSettings = () => {
  const [eventName, setEventName] = useState("");
  const [eventNameError, setEventNameError] = useState("");
  const [eventDeadline, setEventDeadline] = useState(new Date());
  const [eventDeadlineError, setEventDeadlineError] = useState("");

  const [, dispatch] = useContext(PopupContext);

  const handleSetInformation = (e) => {
    e.preventDefault();
    dispatch({
      type: "SHOW",
      payload: {
        title: "Do you want to confirm the credentials?",
        body: "This will change the event information",
        theme: "violet",
        icon: <BsCheck2 />,
        successText: "Proceed",
        cancelText: "Cancel",
        handleSuccess: function (e) {
          dispatch({
            type: "HIDE",
          });
        },
        handleCancel: function (e) {
          dispatch({
            type: "HIDE",
          });
        },
      },
    });
  };

  const handleClearData = (e) => {
    e.preventDefault();
    dispatch({
      type: "SHOW",
      payload: {
        title: "Are you sure you want to clear data?",
        body: "You can't undo this action.",
        theme: "red",
        icon: <BsCheck2 />,
        successText: "Clear data",
        cancelText: "Cancel",
        handleSuccess: function (e) {
          dispatch({
            type: "HIDE",
          });
        },
        handleCancel: function (e) {
          dispatch({
            type: "HIDE",
          });
        },
      },
    });
  };

  return (
    <section className="pt-20 lg:pt-6 lg:px-[calc(100vw/12)] pb-6 font-nunito flex flex-col  lg:flex-row h-full">
      <nav className="text-sm text-dark-gray first-letter:w-full lg:w-1/4 px-6 py-4 lg:py-8 lg:flex flex-col space-y-2">
        This section is used to edit the event and this section has to be
        handled with caution. You cant't undo any action performed here and its
        suggested for the Administrator of the Event to handle this section.
      </nav>
      <article className="w-full lg:w-3/4 px-6 py-4 lg:py-8 h-full">
        <h1 className="text-3xl font-bold text-slate mb-8">Event Settings</h1>
        <div className="flex flex-col lg:flex-row space-y-4 lg:space-y-0 lg:space-x-4 w-full">
          <Inputfield
            errorState={[eventNameError, setEventNameError]}
            valueState={[eventName, setEventName]}
            title="Event Name"
            placeholder="Eg. International Conference 2022"
          />
          <Inputfield
            errorState={[eventDeadlineError, setEventDeadlineError]}
            valueState={[eventDeadline, setEventDeadline]}
            title="Deadline for Submission"
            placeholder="Eg. International Conference 2022"
            type="date"
          />
        </div>
        <div className="select-none my-4 flex space-x-4 w-full">
          <button
            onClick={(e) => handleSetInformation(e)}
            className="px-8 py-2 text-lg bg-violet text-white font-semibold w-fit rounded-lg shadow-lg"
          >
            Set information
          </button>
          <button
            onClick={(e) => handleClearData(e)}
            className="px-8 py-2 text-lg bg-red text-white font-semibold w-fit rounded-lg shadow-lg"
          >
            Clear data
          </button>
        </div>
      </article>
    </section>
  );
};

export default EventSettings;
