import React, { useContext, useEffect, useState } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import Checkbox from "../../components/Forms/Checkbox";
import { MdKeyboardArrowDown, MdKeyboardArrowRight } from "react-icons/md";
import SubmissionList from "./SubmissionList";
import API from "../../components/Api/Axios";
import { AuthContext, AUTH_LOCAL_STORAGE } from "../../components/Auth/AuthContext";
import { AUTH_REFRESH_TOKEN } from "../../components/Api/Endpoints";
import categoriesData from "../../data/categoriesData";

const AdminSubmissions = () => {

  const [viewport, setViewport] = useState({
    width: document.documentElement.clientWidth,
    height: document.documentElement.clientHeight,
  });

  useEffect(() => {

    let mounted = true;
    window.addEventListener("resize", () => {
      if (mounted) setViewport({
        width: document.documentElement.clientWidth,
        height: document.documentElement.clientHeight,
      });
    });

    return () => { mounted = false; }
  });

  const [statusFilter, setStatusFilter] = useState("EVERYTHING");
  const [dateFilter, setDateFilter] = useState("EVERYTHING");
  const [reviewCountFilter, setReviewCountFilter] = useState(-1);
  const [categoriesFilter, setCategoriesFilter] = useState([]);

  const [filterMenuHide, setFilterMenuHide] = useState(viewport.width < 768);

  const statusFilterState = [
    {
      value: "EVERYTHING",
      text: "Everything",
    },
    {
      value: "FINAL_SUBMITTED",
      text: "Final Submitted",
    },
    {
      value: "APPROVED",
      text: "Accepted",
    },
    {
      value: "APPROVED_PAID",
      text: "Accepted & Paid",
    },
    {
      value: "REJECTED",
      text: "Rejected",
    },
    {
      value: "REVISE",
      text: "Sent to Revision",
    },
    {
      value: "UNDER_REVIEW",
      text: "Under Review",
    },
    {
      value: "UNREAD",
      text: "Unread",
    },
  ];

  const dateFilterState = [
    {
      value: "EVERYTHING",
      text: "Everything",
    },
    {
      value: "TODAY",
      text: "Today",
    },
    {
      value: "THIS_WEEK",
      text: "Last 7 days",
    },
    {
      value: "THIS_MONTH",
      text: "Last month",
    },
  ];

  const reviewCountState = [
    { value: -1, text: "Everything" },
    { value: 0, text: "Review Count - 0" },
    { value: 1, text: "Review Count - 1" },
    { value: 2, text: "Review Count >= 2" }
  ];

  return (
    <section className="pt-20 lg:pt-6 lg:px-[calc(100vw/12)] pb-6 font-nunito flex flex-col  lg:flex-row h-full">
      <nav className="w-full lg:w-1/4 px-6 py-4 lg:py-8 lg:flex flex-col space-y-2">
        <div className="flex items-center w-full">
          <h2 className="text-dark-gray font-bold text-lg">Filter</h2>
          <div className="flex-1"></div>
          <button
            onClick={(e) => {
              e.preventDefault();
              setFilterMenuHide(!filterMenuHide);
            }}
            className="lg:hidden"
          >
            {filterMenuHide ? (
              <MdKeyboardArrowDown className="text-dark-gray" />
            ) : (
              <MdKeyboardArrowRight className="text-dark-gray" />
            )}
          </button>
        </div>
        <div
          className={`${filterMenuHide && viewport.width < 768
            ? "h-0 overflow-y-hidden"
            : "h-fit"
            } space-y-2 divide-y-2 divide-gray w-full`}
        >
          <div className="space-y-1 py-4">
            <h3 className="text-dark-gray text-sm">By Status</h3>
            <div className="flex flex-col space-y-1 w-full items-start ml-2">
              {statusFilterState.map((fltr, idx) => (
                <button
                  key={idx}
                  onClick={(e) => {
                    e.preventDefault();
                    setStatusFilter(fltr.value);
                  }}
                  className={`${fltr.value === statusFilter ? "text-slate" : "text-gray"
                    } `}
                >
                  {fltr.text}
                </button>
              ))}
            </div>
          </div>
          <div className="space-y-2 py-4 select-none">
            <h3 className="text-dark-gray text-sm">By Review Count</h3>
            <div className="flex flex-col space-y-1 w-full items-start ml-2">
              {reviewCountState.map((fltr, idx) => (
                <button
                  key={idx}
                  onClick={(e) => {
                    e.preventDefault();
                    setReviewCountFilter(fltr.value);
                  }}
                  className={`${fltr.value === reviewCountFilter ? "text-slate" : "text-gray"
                    } `}
                >
                  {fltr.text}
                </button>
              ))}
            </div>
          </div>
          <div className="space-y-2 py-4 select-none">
            <h3 className="text-dark-gray text-sm">By Date</h3>
            <div className="flex flex-col space-y-1 w-full items-start ml-2">
              {dateFilterState.map((fltr, idx) => (
                <button
                  key={idx}
                  onClick={(e) => {
                    e.preventDefault();
                    setDateFilter(fltr.value);
                  }}
                  className={`${fltr.value === dateFilter ? "text-slate" : "text-gray"
                    } `}
                >
                  {fltr.text}
                </button>
              ))}
            </div>
          </div>
          <div className="py-4">
            <div className="flex items-center">
              {" "}
              <h3 className="text-dark-gray text-sm">By Categories</h3>
              <div className="flex-1"></div>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setCategoriesFilter([]);
                }}
                className="bg-light-red text-red px-3 border border-red rounded-lg py-1 text-xs"
              >
                Clear
              </button>
            </div>
            <div className="overflow-y-scroll pr-2 h-[calc(100vh-32rem)] mt-4 gap-2 flex flex-col">
              {categoriesData.map((cat) =>
                categoriesFilter.includes(cat) ? (
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setCategoriesFilter(
                        categoriesFilter.filter((c) => c !== cat)
                      );
                    }}
                    className="rounded-md px-2 py-1 w-fit text-left text-ellipsis text-xs text-violet bg-light-violet"
                  >
                    {cat}
                  </button>
                ) : (
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setCategoriesFilter([...categoriesFilter, cat]);
                    }}
                    className="w-full px-2 text-left text-ellipsis  text-xs"
                  >
                    {cat}
                  </button>
                )
              )}
            </div>
          </div>
        </div>
      </nav>
      <article className="w-full lg:w-3/4 px-6 py-4 lg:py-8 h-full">
        <SubmissionList statusFilter={statusFilter} reviewCountFilter={reviewCountFilter} dateFilter={dateFilter} categoriesFilter={categoriesFilter} />
      </article>
    </section>
  );
};

export default AdminSubmissions;
