import React, { useState, useEffect, useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { BiHomeAlt, BiLogIn, BiLogOut } from "react-icons/bi";
import { AiOutlineUser, AiOutlineFileAdd } from "react-icons/ai";
import { IoMdClose, IoMdContact } from "react-icons/io";
import { GiHamburgerMenu } from "react-icons/gi";
import { RiLockPasswordLine } from "react-icons/ri";
import { BsTelephone } from "react-icons/bs";
import { FiSettings } from "react-icons/fi";
import { HiOutlineUserGroup } from "react-icons/hi";
import psglogo from "../assets/psg_logo.png";
import { isAdmin, isAuthor } from "../pages/Auth/utils";
import { AuthContext } from "./Auth/AuthContext";
import ContactUs from "../pages/Contact/ContactUs";
const Navbar = () => {

  const location = useLocation();
  const [mobileMenuIsClosed, setMobileMenuIsClosed] = useState(true);

  const [navLinks, setNavLinks] = useState([
    { text: "Login", to: "/login", icon: <BiLogIn /> },
    {
      text: "Forgot Password",
      to: "/forgot-password",
      icon: <RiLockPasswordLine />,
    },
    // { text: "Register", to: "/register", icon: <AiOutlineUser /> },
  ]);

  const { auth, setAuthState, logout } = useContext(AuthContext);

  useEffect(() => {

    if (auth === null || auth === undefined || auth === {}) {

      setNavLinks([
        { text: "Login", to: "/login", icon: <BiLogIn /> },
        {
          text: "Forgot Password",
          to: "/forgot-password",
          icon: <RiLockPasswordLine />,
        },
        { text: "Register", to: "/register", icon: <AiOutlineUser /> },
      ]);

    } else if (auth.role === 'CHAIR' || auth.role === 'ADMIN') {

      setNavLinks([
        {
          text: "Home",
          to: "/admin/submissions",
          icon: <BiHomeAlt />,
        },
        {
          text: "Event Settings",
          to: "/admin/event-settings",
          icon: <FiSettings />,
        },
        {
          text: "Reviewers",
          to: "/admin/reviewers",
          icon: <HiOutlineUserGroup />,
        },
        {
          text: "Review Panel",
          to: "/reviews",
          icon: <HiOutlineUserGroup />,
        }
      ]);

    } else if (auth.role === "REVIEWER") {

      setNavLinks([
        {
          text: "Home",
          to: "/notifications",
          icon: <BiHomeAlt />,
        },
        // {
        //   text: "New Submission",
        //   to: "/new-submission",
        //   icon: <AiOutlineFileAdd />,
        // },
        {
          text: "Review Panel",
          to: "/reviews",
          icon: <HiOutlineUserGroup />,
        },
      ]);

    } else {

      setNavLinks([
        {
          text: "Home",
          to: "/notifications",
          icon: <BiHomeAlt />,
        },
        // {
        //   text: "New Submission",
        //   to: "/new-submission",
        //   icon: <AiOutlineFileAdd />,
        // },
      ]);

    }

  }, [auth]);

  const navigate = useNavigate();

  return (
    <React.Fragment>
      <nav className="hidden lg:flex px-36 bg-cloud w-full font-nunito">
        <div className="flex divide-x-2 divide-gray">
          {navLinks.map((link, index) => (
            <Link to={link.to} key={index}>
              <button
                className={`px-6 py-2 h-full flex justify-center group items-center ${(
                  location.pathname !== "/"
                    ? location.pathname.startsWith(link.to)
                    : link.to === "/"
                )
                  ? "text-slate"
                  : "text-dark-gray"
                  } hover:text-slate `}
              >
                {React.cloneElement(link.icon, {
                  size: 18,
                  className: `${location.pathname.startsWith(link.to)
                    ? "text-slate"
                    : "text-dark-gray"
                    } group-hover:text-slate mr-2`,
                })}
                <p className="">{link.text}</p>
              </button>
            </Link>
          ))}
          {auth !== null && (
            <button
              onClick={(e) => {
                logout();
              }}
              className={`px-6 py-2 h-full flex justify-center group items-center text-dark-gray hover:text-slate `}
            >
              <BiLogOut
                size={18}
                className="text-dark-gray group-hover:text-slate mr-2"
              />
              <p className="">Logout</p>
            </button>
          )}
        </div>
        <div className="flex-1"></div>
        <Link to="/contact-us">
          <button
            className={`px-6 py-2 h-full flex justify-center group items-center ${location.pathname.startsWith("/contact-us")
              ? "text-slate"
              : "text-dark-gray"
              } hover:text-slate `}
          >
            {React.cloneElement(<BsTelephone />, {
              size: 18,
              className: `${location.pathname.startsWith("/contact-us")
                ? "text-slate"
                : "text-dark-gray"
                } group-hover:text-slate mr-2`,
            })}
            <p className="">Contact Us</p>
          </button>
        </Link>
      </nav>

      <nav className="fixed top-0 left-0 right-0 w-screen z-10 shadow-md">
        <div className="flex lg:hidden p-4 bg-cloud w-full items-center">
          <img src={psglogo} alt="" className="h-8 w-auto" />
          <div className="flex-1"></div>
          <button
            className="h-6 w-6 flex items-center justify-center text-slate"
            onClick={(e) => {
              e.preventDefault();
              setMobileMenuIsClosed(!mobileMenuIsClosed);
            }}
          >
            {mobileMenuIsClosed ? (
              <GiHamburgerMenu size={24} />
            ) : (
              <IoMdClose size={24} />
            )}
          </button>
        </div>
        <div
          className={`bg-cloud divide-y-2 divide-gray transition-all duration-200 ease-in-out ${mobileMenuIsClosed ? "h-0" : "h-fit pb-6"
            } overflow-y-hidden`}
        >
          {navLinks.map((link, index) => (
            <Link to={link.to} key={index} className="block w-full">
              <button
                className={`px-12 py-2 h-full flex justify-start group items-center w-full ${location.pathname.startsWith(link.to)
                  ? "text-slate"
                  : "text-dark-gray"
                  } hover:text-slate `}
              >
                {React.cloneElement(link.icon, {
                  size: 18,
                  className: `${location.pathname === link.to
                    ? "text-slate"
                    : "text-dark-gray"
                    } group-hover:text-slate mr-2`,
                })}
                <p className="">{link.text}</p>
              </button>
            </Link>
          ))}
          {auth !== null && (
            <button
              onClick={(e) => {
                logout();
              }}
              className={`px-12 py-2 w-full h-full flex justify-start group items-center text-dark-gray hover:text-slate `}
            >
              <BiLogOut
                size={18}
                className="text-dark-gray group-hover:text-slate mr-2"
              />
              <p className="">Logout</p>
            </button>
          )}
          <Link to="/contact-us" className="block w-full">
            <button
              className={`px-12 py-2 h-full flex justify-start group items-center w-full ${location.pathname.startsWith("/contact-us")
                ? "text-slate"
                : "text-dark-gray"
                } hover:text-slate `}
            >
              {React.cloneElement(<BsTelephone />, {
                size: 18,
                className: `${location.pathname === "/contact-us"
                  ? "text-slate"
                  : "text-dark-gray"
                  } group-hover:text-slate mr-2`,
              })}
              <p className="">Contact Us</p>
            </button>
          </Link>
        </div>
      </nav>
    </React.Fragment>
  );
};

export default Navbar;
