import React from "react";
import { AiFillExclamationCircle } from "react-icons/ai";
import PhoneInput from "react-phone-input-2";

const PhonenumberInput = ({ className, title, errorState, valueState, isDisabled = false }) => {
  const [error, setError] = errorState;
  const [value, setValue] = valueState;

  return (
    <div
      className={`${className} flex flex-col items-start justify-center font-nunito space-y-2`}
    >
      <label className="text-dark-gray text-base">{title}</label>
      <PhoneInput
        disabled={isDisabled}
        placeholder="Enter phone number"
        country={"in"}
        value={value}
        onChange={(e) => {
          setError("");
          setValue(e);
        }}
        containerStyle={{
          width: "100%",
          paddingTop: "0.25rem",
          paddingBottom: "0.25rem",
          background: "#f5f5f5",
          borderRadius: "0.5rem",
        }}
        inputStyle={{
          width: "100%",
          fontSize: "1rem",
          background: "#f5f5f5",
          border: "none",
          paddingBottom: "0.5rem",
          paddingTop: "0.5rem",
        }}
        buttonStyle={{
          border: "none",
          borderRadius: "0.5rem",
          paddingTop: "0rem",
          paddingBottom: "0rem",
        }}
        dropdownStyle={{ borderRadius: "0.5rem" }}
        searchStyle={{
          paddingTop: "0.25rem",
          paddingBottom: "0.25rem",
        }}
      />
      <div className="flex space-x-2 items-center w-full"></div>
      {error.length !== 0 && (
        <div className="flex items-center space-x-2 text-xs text-red">
          <AiFillExclamationCircle />
          <p className="">{error}</p>
        </div>
      )}
    </div>
  );
};

export default PhonenumberInput;
