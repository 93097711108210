import React, { useState, useContext, useEffect } from "react";
import Dropdown from "../../components/Forms/Dropdown";
import Inputfield from "../../components/Forms/Inputfield";
import TextArea from "../../components/Forms/TextArea";
import { NewSubmissionContext } from "./NewSubmission";
import { useLocation, useNavigate } from "react-router-dom";
import API from "../../components/Api/Axios";
import { SUBMISSION_UPDATE_DRAFT } from "../../components/Api/Endpoints";
import categoriesData from "../../data/categoriesData";
import subCategoriesData from "../../data/subCategoriesData";


const TypeTitleAbstract = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { draft, setDraft } = useContext(NewSubmissionContext);

  const [type, setType] = useState("");
  const [typeError, setTypeError] = useState("");

  const [title, setTitle] = useState("");
  const [titleError, setTitleError] = useState("");

  const [abstract, setAbstract] = useState("");
  const [abstractError, setAbstractError] = useState("");

  const [category, setCategory] = useState("");
  const [categoryError, setCategoryError] = useState("");

  const [categoryOthers, setCategoryOthers] = useState("");
  const [categoryOthersError, setCategoryOthersError] = useState("");

  const [subcategory, setSubcategory] = useState("");
  const [subcategoryError, setSubcategoryError] = useState("");

  const [subcategoryOthers, setSubcategoryOthers] = useState("");
  const [subcategoryOthersError, setSubcategoryOthersError] = useState("");

  const formatArticleType = (type) => {
    // console.log(type);
    if (type === "Review Article") {
      return "BR";
    } else {
      return "OA";
    }
  };

  const handleSaveAndProceed = (e) => {
    e.preventDefault();

    const isValid =
      type.length > 0 &&
      title.length > 0 &&
      abstract.length > 0 &&
      abstract.split(" ").length <= 300 &&
      title.split(" ").length <= 50 &&
      category.length > 0 &&
      subcategory.length > 0 &&
      (category !== "Others" ||
        (categoryOthers && categoryOthers.length > 0)) &&
      (subcategory !== "Others" ||
        (subcategoryOthers && subcategoryOthers.length > 0));

    if (type.length === 0) setTypeError("Please select a type");
    if (title.length === 0) setTitleError("Please enter a title");
    else if (title.split(" ").length > 50)
      setTitleError("Title must be less than 20 words");
    if (abstract.length === 0) setAbstractError("Please enter an abstract");
    else if (abstract.split(" ").length > 300)
      setAbstractError("Abstract must be less than 200 words");
    if (category.length <= 0) setCategoryError("Please select a category");
    if (subcategory.length <= 0)
      setSubcategoryError("Please select a subcategory");
    if (category === "Others" && categoryOthers && categoryOthers.length <= 0)
      setCategoryOthersError('Please enter a category for "Others"');
    if (
      subcategory === "Others" &&
      subcategoryOthers &&
      subcategoryOthers.length <= 0
    )
      setSubcategoryOthersError("Please enter a subcategory");

    if (!isValid) return;
    else {
      if (type && title && abstract && category && subcategory) {
        API.post(SUBMISSION_UPDATE_DRAFT, {
          id: draft.id,
          type: formatArticleType(type),
          title: title,
          abstract: abstract,
          category: category === "Others" ? categoryOthers : category,
          subCategory:
            subcategory === "Others" ? subcategoryOthers : subcategory,
        })
          .then((res) => {
            setDraft({
              ...draft,
              ...{
                type: type,
                title: title,
                abstract: abstract,
                category: category === "Others" ? categoryOthers : category,
                subCategory:
                  subcategory === "Others" ? subcategoryOthers : subcategory,
              },
            });
          })
          .then(() => {
            navigate("/new-submission/file-upload");
          })
          .catch((err) => {
            // console.log(err);
            // console.log("foghdi");
          });
      }
    }
  };

  // useEffect(() => {

  //   setDraft({
  //     ...draft,
  //     progressStep: false,
  //   })

  // }, []);

  useEffect(() => {
    // const { type, title, abstract, category, subCategory } = draft;

    if (draft.type) {
      setType(draft.type);
    }

    if (draft.title) {
      setTitle(draft.title);
    }

    if (draft.abstract) {
      setAbstract(draft.abstract);
    }

    if (draft.category) {
      if (categoriesData.includes(draft.category)) setCategory(draft.category);
      else {
        setCategory("Others");
        setCategoryOthers(draft.category);
      }
    }

    if (draft.subCategory) {
      if (subCategoriesData.includes(draft.subCategory))
        setSubcategory(draft.subCategory);
      else {
        setSubcategory("Others");
        setSubcategoryOthers(draft.subCategory);
      }
    }
  }, [draft]);

  return (
    <div className="">
      <h2 className="text-dark-gray text-lg">Step 1</h2>
      <h1 className="font-bold text-3xl">Type, Title and Abstract</h1>
      <form className="flex flex-col space-y-4 items-center lg:items-start w-full py-6">
        <div className="flex space-y-4 lg:space-y-0 lg:space-x-4 flex-col lg:flex-row w-full">
          <Dropdown
            errorState={[typeError, setTypeError]}
            valueState={[type, setType]}
            placeholder=""
            title="Manuscript Type"
            className="lg:w-4/12 w-full"
            options={["Original Article", "Review Article"]}
          />
          <Inputfield
            errorState={[titleError, setTitleError]}
            valueState={[title, setTitle]}
            placeholder="Max. 20 words"
            type="text"
            title="Title"
            className="lg:w-8/12 w-full"
          />
        </div>
        <TextArea
          errorState={[abstractError, setAbstractError]}
          valueState={[abstract, setAbstract]}
          placeholder="Max. 200 words"
          type="text"
          title="Abstract"
          className="w-full"
        />
        <div className="flex space-y-4 lg:space-y-0 lg:space-x-4 flex-col lg:flex-row w-full">
          <div className="flex flex-col items-start space-y-2 w-full lg:w-4/12">
            <Dropdown
              errorState={[categoryError, setCategoryError]}
              valueState={[category, setCategory]}
              placeholder="Choose Category"
              title="Category"
              className="w-full"
              options={[...categoriesData, "Others"]}
            />
            {category === "Others" && (
              <React.Fragment>
                <p
                  className={`font-bold ${
                    categoryOthersError.length <= 0
                      ? "text-dark-gray"
                      : "text-red"
                  } text-xs`}
                >
                  {categoryOthersError.length <= 0
                    ? 'If "Others", type it here'
                    : categoryOthersError}
                </p>
                <input
                  placeholder="Type category"
                  type="text"
                  value={categoryOthers}
                  onChange={(e) => setCategoryOthers(e.target.value)}
                  className="text-xs w-full marker:appearance-none py-2 px-2 border-slate border-b focus:outline-none outline-none"
                />
              </React.Fragment>
            )}
          </div>
          <div className="flex flex-col items-start space-y-2 w-full lg:w-8/12">
            <Dropdown
              errorState={[subcategoryError, setSubcategoryError]}
              valueState={[subcategory, setSubcategory]}
              placeholder="Choose Subcategory"
              title="Subcategory"
              className="w-full"
              options={[...subCategoriesData, "Others"]}
            />
            {subcategory === "Others" && (
              <React.Fragment>
                <p
                  className={`font-bold ${
                    subcategoryOthersError.length <= 0
                      ? "text-dark-gray"
                      : "text-red"
                  } text-xs`}
                >
                  {categoryOthersError.length <= 0
                    ? 'If "Others", type it here'
                    : subcategoryOthersError}
                </p>
                <input
                  placeholder="Type subcategory"
                  type="text"
                  value={subcategoryOthers}
                  onChange={(e) => {
                    setSubcategoryOthers(e.target.value);
                  }}
                  className="text-xs w-full marker:appearance-none py-2 px-2 border-slate border-b focus:outline-none outline-none"
                />
              </React.Fragment>
            )}
          </div>
        </div>
        <div className="flex flex-col lg:flex-row lg:space-x-4 items-center ">
          <button className="my-4 px-16 lg:px-8 py-2 text-lg border-2 border-violet text-violet bg-white font-semibold w-fit rounded-lg shadow-lg">
            Discard Information
          </button>
          <button
            onClick={(e) => handleSaveAndProceed(e)}
            className="my-4 px-16 lg:px-8 py-2 text-lg border-2 border-violet bg-violet text-white font-semibold w-fit rounded-lg shadow-lg"
          >
            Save and Proceed
          </button>
        </div>
      </form>
    </div>
  );
};

export default TypeTitleAbstract;
