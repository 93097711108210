import React, { useEffect, useState, useContext } from "react";
import API from "../../components/Api/Axios";
import { CHAIR_GET_REVIEWER_PANEL } from "../../components/Api/Endpoints";
import Inputfield from "../../components/Forms/Inputfield";
import { validateEmail } from "../../components/Forms/validations";
import { PopupWindowContext } from "../../components/PopupWindow/PopupWindow";
import categoriesData from "../../data/categoriesData";
import dummyReviewerPanel from "../../data/dummyReviewerPanel";
import AddReviewer from "../Admin/AddReviewer";
import { NilCard } from "../Admin/SubmissionList";

const ReviewersPanel = () => {
  const [, dispatch] = useContext(PopupWindowContext);

  // const [categoriesFilter, setCategoriesFilter] = useState([]);
  const [reviewers, setReviewers] = useState([]);
  const [filteredReviewers, setFilteredReviewers] = useState([]);
  const [reviewerInput, setReviewerInput] = useState("");
  const [reviewerInputError, setReviewerInputError] = useState("");

  useEffect(() => {

    API.get(CHAIR_GET_REVIEWER_PANEL)
    .then((res) => {

      const data = res.data.data;

      if (data) {

        // setReviewers(data.map((reviewer) => ({
        //   ...reviewer,
        //   invitedNow: true,
        // })));
        setReviewers(data);

      } else {
        setReviewers([]);
      }

    })
    .catch((err) => {
      // console.log(err);
    })

  }, []);

  useEffect(() => {
    setFilteredReviewers(
      reviewers.filter(
        (rev) =>
          (reviewerInput.length <= 0 ||
            rev.deptOrTeam.toLowerCase().includes(reviewerInput.toLowerCase()) ||
            rev.category.toLowerCase().includes(reviewerInput.toLowerCase()) ||
            rev.name.toLowerCase().includes(reviewerInput.toLowerCase()) ||
            rev.email.toLowerCase().includes(reviewerInput.toLowerCase()))
      )
    );
  }, [reviewers, reviewerInput]);

  const handleInviteReviewer = (e) => {
    e.preventDefault();
    let isValid = true;
    if (reviewerInput.length === 0) {
      setReviewerInputError("Required field to add.");
      isValid = false;
    }
    if (!validateEmail(reviewerInput)) {
      setReviewerInputError("Enter valid email address");
      isValid = false;
    }
    if (!isValid) return;
    else {
      setReviewerInputError("");
      // setReviewersAdded([
      //   ...reviewersAdded,
      //   {
      //     name: "",
      //     id: "",
      //     category: "",
      //     email: reviewerInput,
      //     invitedNow: true,
      //   },
      // ]);
      // setReviewerInput("");
      dispatch({
        type: "SHOW",
        payload: {
          title: "Invite Reviewer",
          element: (
            <AddReviewer
              emailInitial={reviewerInput}
              handleInvite={() => {
                setReviewerInput("");
                dispatch({
                  type: "HIDE",
                });
              }}
            />
          ),
          handleClose: function (e) {
            e.preventDefault();
            dispatch({
              type: "HIDE",
            });
          },
        },
      });
    }
  };

  return (
    <section className="pt-20 lg:pt-6 lg:px-[calc(100vw/12)] pb-6 font-nunito flex flex-col  lg:flex-row h-full">
      <nav className="w-full lg:w-1/4 px-6 py-4 lg:py-8 lg:flex flex-col space-y-3">
        <button className="mt-4 px-16 lg:px-8 py-2 text-lg border-2 border-violet bg-violet text-white font-semibold w-fit lg:w-full rounded-lg shadow-lg">
          Upload CSV file
        </button>
        <button className="mt-4 px-16 lg:px-8 py-2 text-lg border-2 border-violet bg-light-violet text-violet font-semibold w-fit lg:w-full rounded-lg shadow-lg">
          Download data
        </button>
        {/* <h3 className="text-dark-gray">Filter</h3>
        <div className="flex items-center">
          <h3 className="text-slate font-bold">By Categories</h3>
          <div className="flex-1"></div>
          <button
            onClick={(e) => {
              e.preventDefault();
              setCategoriesFilter([]);
            }}
            className="bg-light-red text-red px-3 border border-red rounded-lg py-1 text-xs"
          >
            Clear
          </button>
        </div>
        <div className="overflow-y-scroll pr-2 h-[calc(100vh-20rem)] mt-4 gap-2 flex flex-col">
          {categoriesData.map((cat) =>
            categoriesFilter.includes(cat) ? (
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setCategoriesFilter(
                    categoriesFilter.filter((c) => c !== cat)
                  );
                }}
                className="rounded-md px-2 py-1 w-fit text-left text-ellipsis text-xs text-violet bg-light-violet"
              >
                {cat}
              </button>
            ) : (
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setCategoriesFilter([...categoriesFilter, cat]);
                }}
                className="w-full px-2 text-left text-ellipsis  text-xs"
              >
                {cat}
              </button>
            )
          )}
        </div> */}
      </nav>
      <article className="w-full lg:w-3/4 px-6 py-4 lg:py-8 h-full space-y-4">
        <h1 className="text-3xl text-slate font-bold">Reviewers Panel</h1>
        <div className="flex flex-col lg:flex-row items-center lg:space-x-2  lg:items-start w-full mt-4">
          <Inputfield
            valueState={[reviewerInput, setReviewerInput]}
            errorState={[reviewerInputError, setReviewerInputError]}
            placeholder="Enter reviewer email"
            title="Add Reviewer"
            className="flex-1 w-full"
          />
          <button
            onClick={(e) => handleInviteReviewer(e)}
            className="flex items-center mt-4 lg:mt-[2rem] h-fit space-x-2 px-6 py-2 bg-dark-gray text-white font-semibold w-fit rounded-lg"
          >
            <p className="w-fit">Invite Reviewer</p>
          </button>
        </div>
        {filteredReviewers.length <= 0 ? (
          <div className="w-full py-4 px-4 flex items-center justify-center h-full">
            <NilCard text="No Reviewers found! :(" />
          </div>
        ) : (
          <div className="grid w-full grid-cols-1 lg:grid-cols-3">
            {filteredReviewers.map((rev) => (
              <div className="px-4 py-4 flex flex-col hover:bg-cloud cursor-pointer">
                <p className="font-bold text-slate text-lg">{rev.name}</p>
                <p className="text-dark-gray text-sm break-words">
                  {rev.email}
                </p>
                <p className="mt-2 text-dark-gray bg-cloud px-3 py-1 w-fit text-xs rounded-full">
                  {rev.category}
                </p>
              </div>
            ))}
          </div>
        )}
      </article>
    </section>
  );
};

export default ReviewersPanel;
