import React from "react";

const TextOutput = ({ text, subtext, className }) => {
  return (
    <div className={`flex flex-col space-y-2 ${className}`}>
      <p className="w-full text-sm text-dark-gray">{subtext}</p>
      <p className="w-full text-slate">{text}</p>
    </div>
  );
};

export default TextOutput;
