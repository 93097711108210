import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Checkbox from "../../components/Forms/Checkbox";
import API from "../../components/Api/Axios";
import { SUBMISSION_FILE_DOWNLOAD, SUBMISSION_SUBMIT } from "../../components/Api/Endpoints";
import TextOutput from "../../components/Forms/TextOutput";
import { NewSubmissionContext } from "./NewSubmission";
import { PopupContext } from '../../components/PopupSystem/Popup';
import { BiCheck, BiXCircle } from "react-icons/bi";

const Summary = () => {

  const { draft, setDraft } = useContext(NewSubmissionContext);
  const [, dispatch] = useContext(PopupContext);

  const navigate = useNavigate();

  const [type, setType] = useState("");
  const [title, setTitle] = useState("");
  const [abstract, setAbstract] = useState("");
  const [keywords, setKeywords] = useState([]);
  const [authors, setAuthors] = useState([]);
  const [coverLetter, setCoverLetter] = useState("");
  const [numberOfFigures, setNumberOfFigures] = useState("");
  const [numberOfTables, setNumberOfTables] = useState("");
  const [numberOfWords, setNumberOfWords] = useState("");
  const [fileUpload, setFileUpload] = useState({});

  const [confirm1, setConfirm1] = useState(false);
  const [confirm2, setConfirm2] = useState(false);
  const [confirm3, setConfirm3] = useState(false);

  const [confirm1Error, setConfirm1Error] = useState("");
  const [confirm2Error, setConfirm2Error] = useState("");
  const [confirm3Error, setConfirm3Error] = useState("");

  const handleConfirmAndSave = (e) => {

    e.preventDefault();

    if (confirm1 && confirm2 && confirm3) {

      API.post(SUBMISSION_SUBMIT, {
        id: draft.id
      }).then((res) => {

        dispatch({
          type: "SHOW",
          payload: {
            title: res.data.message,
            body: "Your submission has been submitted successfully.",
            theme: "green",
            icon: <BiCheck />,
            successText: "Ok",
            cancelText: "",
            noCancel: true,
            handleSuccess: function (e) {
              navigate("/submissions");
              dispatch({
                type: "HIDE",
              });
            },
            handleCancel: function (e) { },
          },
        });

      }).catch((err) => {

        dispatch({
          type: "SHOW",
          payload: {
            title: "Error, Submitting",
            body: "There was an error submitting your submission, try again after checking whether all fields are filled correctly.",
            theme: "red",
            icon: <BiXCircle />,
            successText: "Close",
            cancelText: "",
            noCancel: true,
            handleSuccess: function (e) {
              dispatch({
                type: "HIDE",
              });
            },
            handleCancel: function (e) { },
          },
        });

      })

    } else {
      setConfirm1Error("Please confirm that you have read the terms and conditions");
      setConfirm2Error("Please confirm that you have read the terms and conditions");
      setConfirm3Error("Please confirm that you have read the terms and conditions");
    }



  }

  const handleDownload = (e, file) => {
    e.preventDefault();

    if (file) {
      // href={SUBMISSION_FILE_DOWNLOAD.replace(':id', file.id)}
      API.get(
        SUBMISSION_FILE_DOWNLOAD.replace(':id', file.id),
        {
          responseType: 'blob'
        }
      ).then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', file.fileName); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
    }

  }

  // useEffect(() => {
  //   setDraft({
  //     ...draft,
  //     progressStep: false,
  //   })
  // }, []);

  useEffect(() => {

    if (draft.type) {
      setType(draft.type);
    }

    if (draft.title) {
      setTitle(draft.title);
    }

    if (draft.abstract) {
      setAbstract(draft.abstract);
    }

    if (draft.keywords) {
      setKeywords(draft.keywords);
    }

    if (draft.authors) {
      setAuthors([
        draft.author,
        ...draft.authors,
      ]);
    }

    if (draft.coverLetter) {
      setCoverLetter(draft.coverLetter);
    }

    if (draft.numberOfFigures) {
      setNumberOfFigures(draft.numberOfFigures);
    }

    if (draft.numberOfTables) {
      setNumberOfTables(draft.numberOfTables);
    }

    if (draft.numberOfWords) {
      setNumberOfWords(draft.numberOfWords);
    }

    if (draft.fileUpload) {
      setFileUpload(draft.fileUpload);
    }

  }, [draft]);


  return (
    <div className="">
      <h2 className="text-dark-gray text-lg">Step 7</h2>
      <h1 className="font-bold text-3xl">Review summary and Submit</h1>
      <form className="flex flex-col space-y-4 items-center lg:items-start w-full py-6">
        <div className="flex space-y-4 lg:space-y-0 lg:space-x-4 flex-col lg:flex-row w-full">
          <TextOutput
            subtext="Type"
            text={type}
            className="w-full lg:w-1/3"
          />
          <TextOutput
            subtext="Title"
            text={title}
            className="w-full lg:w-2/3"
          />
        </div>
        <div className="flex space-y-4 lg:space-y-0 lg:space-x-4 flex-col lg:flex-row w-full break-normal">
          <TextOutput
            subtext="Abstract"
            text={abstract}
            className="w-full"
          />
        </div>
        <div className="flex space-y-4 lg:space-y-0 lg:space-x-4 flex-col lg:flex-row w-full">
          <TextOutput
            subtext="Uploaded File"
            text={
              // <Link to="" className="text-aqua hover:underline break-words">
              <button onClick={(e) => { handleDownload(e, fileUpload) }} className="text-aqua hover:underline break-words">
                {fileUpload ? fileUpload.fileName : "No file uploaded"}
              </button>
              // </Link>
            }
            className="w-full lg:w-1/3"
          />
          <TextOutput
            subtext="Keywords"
            text={
              <div className="flex items-center flex-wrap gap-x-2 gap-y-2">
                {keywords.map((keyword, index) => (
                  <div className="h-fit py-1 px-3 rounded-full bg-cloud text-xs" key={index}>
                    {keyword}
                  </div>
                ))}
              </div>
            }
            className="w-full lg:w-2/3"
          />
        </div>
        <div className="text-sm text-dark-gray">Authors</div>
        <div className="flex flex-col w-full divide-y divide-dark-gray overflow-x-auto">
          <div className="flex w-fit lg:w-full bg-gray">
            <div className="flex overflow-x-hidden text-xs lg:text-sm w-[150px] lg:flex-none  lg:w-3/12 items-center justify-start px-2 py-1">
              Name
            </div>
            <div className="flex overflow-x-hidden text-xs lg:text-sm w-[200px] lg:flex-none  lg:w-3/12 items-center justify-start px-2 py-1">
              Email
            </div>
            <div className="flex overflow-x-hidden text-xs lg:text-sm  w-[200px] lg:flex-none  lg:w-3/12 items-center justify-start px-2 py-1">
              Institution
            </div>
          </div>
          {authors.map((author, index) => (
            <div className="flex w-fit lg:w-full bg-cloud" key={index}>
              <div className="flex overflow-x-hidden text-xs lg:text-sm w-[150px] lg:flex-none  lg:w-3/12 items-center justify-start px-2 py-1">
                {author.name}
              </div>
              <div className="flex overflow-x-hidden text-xs lg:text-sm w-[200px] lg:flex-none  lg:w-3/12 items-center justify-start px-2 py-1">
                {author.email}
              </div>
              <div className="flex overflow-x-hidden text-xs lg:text-sm  w-[200px] lg:flex-none  lg:w-3/12 items-center justify-start px-2 py-1">
                {author.collegeOrganisationName}
              </div>
            </div>
          ))}
        </div>
        <div className="flex space-y-4 lg:space-y-0 lg:space-x-4 flex-col lg:flex-row w-full">
          <TextOutput
            subtext="Cover Letter"
            text={
              <button onClick={(e) => { handleDownload(e, coverLetter) }} className="text-aqua hover:underline break-all">
                {coverLetter ? coverLetter.fileName : "No file uploaded"}
              </button>
            }
            className="w-full lg:w-1/4"
          />
          <TextOutput
            subtext="Number of Figures"
            text={numberOfFigures}
            className="w-full lg:w-1/4"
          />
          <TextOutput
            subtext="Number of Tables"
            text={numberOfTables}
            className="w-full lg:w-1/4"
          />
          <TextOutput
            subtext="Number of Words"
            text={numberOfWords}
            className="w-full lg:w-1/4"
          />
        </div>
        <h2 className="text-lg text-dark-gray">Confirm the following</h2>
        <Checkbox
          errorState={[confirm1Error, setConfirm1Error]}
          valueState={[confirm1, setConfirm1]}
          text="I, the corresponding author, confirm that all of the content, figures (drawings, charts, photographs, etc.), and tables in the submitted work are original work created by the authors listed in the manuscript portal. The given information is true to the best of my knowledge. I agree to abide"
          className="w-full"
        />
        <Checkbox
          errorState={[confirm2Error, setConfirm2Error]}
          valueState={[confirm2, setConfirm2]}
          text="The given information is true to the best of my knowledge. I agree to abide by the rules and regulations governing the programme."
          className="w-full"
        />
        <Checkbox
          errorState={[confirm3Error, setConfirm3Error]}
          valueState={[confirm3, setConfirm3]}
          text="All possible author identities have been removed from the manuscript."
          className="w-full"
        />
        <div className="flex flex-col lg:flex-row lg:space-x-4 items-center ">
          {/* <button className="my-4 px-16 lg:px-8 py-2 text-lg border-2 border-violet text-violet bg-white font-semibold w-fit rounded-lg shadow-lg">
            Clear all data
          </button> */}
          <button onClick={handleConfirmAndSave} className="my-4 px-16 lg:px-8 py-2 text-lg border-2 border-violet bg-violet text-white font-semibold w-fit rounded-lg shadow-lg">
            Confirm and Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default Summary;
