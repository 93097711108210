import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import API from "../../components/Api/Axios";
import { SUBMISSION_FILE_DOWNLOAD, SUBMISSION_FILE_UPLOAD, SUBMISSION_UPDATE_DRAFT } from "../../components/Api/Endpoints";
import Checkbox from "../../components/Forms/Checkbox";
import Inputfield from "../../components/Forms/Inputfield";
import Radiobox from "../../components/Forms/Radiobox";
import TextArea from "../../components/Forms/TextArea";
import { NewSubmissionContext } from "./NewSubmission";

const Details = () => {

  const { draft, setDraft } = useContext(NewSubmissionContext);
  const [file, setFile] = useState("");
  const [fileError, setFileError] = useState("");
  const navigate = useNavigate();

  const [figures, setFigures] = useState(0);
  const [figuresError, setFiguresError] = useState("");

  const [tables, setTables] = useState(0);
  const [tablesError, setTablesError] = useState("");

  const [words, setWords] = useState(0);
  const [wordsError, setWordsError] = useState("");

  const [hasConflicts, setHasConflicts] = useState("No");
  const [hasConflictsError, setHasConflictsError] = useState("");

  const [conflicts, setConflicts] = useState("");
  const [conflictsError, setConflictsError] = useState("");

  // useEffect(() => {
  //   setDraft({ ...draft, progressStep: false });
  // }, [])

  const handleSaveAndProceed = (e) => {

    e.preventDefault();

    if (figures !== null && tables !== null && words !== null) {

      // console.log(hasConflicts === "Yes" ? conflicts : "None");

      API.post(SUBMISSION_UPDATE_DRAFT, {
        id: draft.id,
        numberOfTables: tables,
        numberOfFigures: figures,
        numberOfWords: words,
        conflictOfInterest: hasConflicts === "Yes",
        conflicts: hasConflicts === "Yes" ? conflicts : "None",
      }).then((res) => {
        setDraft({
          ...draft,
          numberOfFigures: figures,
          numberOfTables: tables,
          numberOfWords: words,
          conflictOfInterest: hasConflicts === "Yes",
          conflicts: hasConflicts === "Yes" ? conflicts : "None",
        });
      }).then(() => {
        navigate("/new-submission/summary");
      }).catch((err) => {
        // console.log(err);
      })
    }

  }

  const handleUpload = (e) => {

    e.preventDefault();

    const file = e.target.files[0];

    const dataForm = new FormData();
    dataForm.append('file', file);

    // upload to server
    API.post(SUBMISSION_FILE_UPLOAD, dataForm, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then((res) => {

      const data = res.data.data;
      API.post(SUBMISSION_UPDATE_DRAFT, {
        id: draft.id,
        coverLetter: data.id,
      }).then((res) => {
        setFile(data);
        setDraft({ ...draft, ...{ coverLetter: data } });
      }).catch((err) => {
        // console.log(err);
      })

    }).catch((err) => {
      if (err.response) {
        if (err.response.status === 400) {
          setFileError(err.response.data.message);
        }
      }
    });

    if (file) {

    }

  }

  const handleDownload = (e) => {
    e.preventDefault();
    // href={SUBMISSION_FILE_DOWNLOAD.replace(':id', file.id)}
    API.get(
      SUBMISSION_FILE_DOWNLOAD.replace(':id', file.id),
      {
        responseType: 'blob'
      }
    ).then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', file.fileName); //or any other extension
      document.body.appendChild(link);
      link.click();
    })

  }

  useEffect(() => {

    // const { coverLetter, conflicts, conflictOfInterest, numberOfTables, numberOfFigures, numberOfWords } = draft;

    if (draft.coverLetter) {
      setFile(draft.coverLetter);
    }

    if (draft.numberOfFigures) {
      setFigures(draft.numberOfFigures);
    }

    if (draft.numberOfTables) {
      setTables(draft.numberOfTables);
    }

    if (draft.numberOfWords) {
      setWords(draft.numberOfWords);
    }

    if (draft.conflictOfInterest) {
      setHasConflicts(draft.conflictOfInterest ? "Yes" : "No");
    }

    if (draft.conflicts) {
      setConflicts(draft.conflicts);
    }

    // console.log("figures, tables, words", figures, tables, words);

  }, [draft]);



  return (
    <div className="">
      <h2 className="text-dark-gray text-lg">Step 5</h2>
      <h1 className="font-bold text-3xl">Add Details and Comments</h1>
      <form className="flex flex-col space-y-4 items-center lg:items-start w-full py-6">
        <h2 className="text-lg text-dark-gray">Add Turnitin Plagiarism Report with % of Similarity</h2>
        <p className="text-sm text-slate">Upload your file in DOC/DOCX/PDF format</p>
        <div className="bg-cloud rounded-lg w-full p-6 flex">
          <label className="cursor-pointer bg-dark-gray px-6 py-2 rounded-lg shadow-md text-white h-fit inline-block ">
            <input
              type="file"
              className="hidden"
              onChange={(e) => handleUpload(e)}
            />
            File Upload
          </label>
          {file && (
            <div className="flex-1 px-6">
              Uploaded{" "}
              <button onClick={(e) => { handleDownload(e) }}>
                <span className="text-aqua hover:underline">{file.fileName}</span>
              </button>
            </div>
          )}
        </div>

        <h2 className="text-lg text-dark-gray">Manuscript Information</h2>
        <div className="flex space-y-4 lg:space-y-0 lg:space-x-4 flex-col lg:flex-row w-full">
          <Inputfield
            errorState={[figuresError, setFiguresError]}
            valueState={[figures, setFigures]}
            placeholder="Figures"
            title="Number of Figures"
            type="number"
            className=" lg:w-1/3 w-full"
          />
          <Inputfield
            errorState={[tablesError, setTablesError]}
            valueState={[tables, setTables]}
            placeholder="Tables"
            title="Number of Tables"
            type="number"
            className=" lg:w-1/3 w-full"
          />
          <Inputfield
            errorState={[wordsError, setWordsError]}
            valueState={[words, setWords]}
            placeholder="Words"
            title="Number of Words"
            type="number"
            className=" lg:w-1/3 w-full"
          />
        </div>
        <div className="flex space-y-4 lg:space-y-0 lg:space-x-4 flex-col lg:flex-row w-full">
          <Radiobox
            errorState={[hasConflictsError, setHasConflictsError]}
            valueState={[hasConflicts, setHasConflicts]}
            options={["Yes", "No"]}
            text="Do you have any conflicts of interest?"
            className="lg:w-1/3 w-full"
          />
          <TextArea
            disabled={hasConflicts === "No"}
            errorState={[conflictsError, setConflictsError]}
            valueState={[conflicts, setConflicts]}
            placeholder=""
            title="If yes, please state:"
            className=" lg:w-2/3 w-full"
          />
        </div>
        <div className="flex flex-col lg:flex-row lg:space-x-4 items-center ">
          <button className="my-4 px-16 lg:px-8 py-2 text-lg border-2 border-violet text-violet bg-white font-semibold w-fit rounded-lg shadow-lg">
            Discard Information
          </button>
          <button onClick={handleSaveAndProceed} className="my-4 px-16 lg:px-8 py-2 text-lg border-2 border-violet bg-violet text-white font-semibold w-fit rounded-lg shadow-lg">
            Save and Proceed
          </button>
        </div>
      </form>
    </div>
  );
};

export default Details;
