// ------ BASE ------
// Local
// export const BASE_URL = "http://localhost:4000";

// Production
export const BASE_URL = '/';
// export const API_URL = BASE_URL + '/rc2022/api';
export const API_URL = "/api";

// ----- DELEGATE -----
export const DELEGATE_BASE_URL = API_URL + '/delegate';
export const DELEGATE_REGISTER = DELEGATE_BASE_URL + '/register';

// ------ AUTH ------
// User Role : Everyone
export const AUTH_BASE_URL = API_URL + "/auth";
export const AUTH_REGISTER = AUTH_BASE_URL + '/register';
export const AUTH_RESET_PASSWORD = AUTH_BASE_URL + '/reset-password';
export const AUTH_LOGIN = AUTH_BASE_URL + '/login';
export const AUTH_LOGOUT = AUTH_BASE_URL + '/logout';
export const AUTH_REFRESH_TOKEN = AUTH_BASE_URL + '/refresh-token';
export const AUTH_FORGOT_PASSWORD = AUTH_BASE_URL + '/forgot-password';

// ------ SUBMISSION ------
// User Role : Author
export const SUBMISSION_BASE_URL = API_URL + "/submission";
export const SUBMISSION_GET_SUBMISSIONS = SUBMISSION_BASE_URL + '/';
export const SUBMISSION_YET_TO_PAY = SUBMISSION_BASE_URL + '/pending_accepted_payments';
export const SUBMISSION_ADD_CERTIFICATE_DETAILS = SUBMISSION_BASE_URL + '/add/certificate_details';
export const SUBMISSION_PAY = SUBMISSION_BASE_URL + '/pay';
export const SUBMISSION_FILE_UPLOAD = SUBMISSION_BASE_URL + '/file/upload';
export const SUBMISSION_FILE_DELETE = SUBMISSION_BASE_URL + '/file/delete';
export const SUBMISSION_FIND_AUTHOR = SUBMISSION_BASE_URL + '/find/author';
export const SUBMISSION_NEW_DRAFT = SUBMISSION_BASE_URL + '/new/draft';
export const SUBMISSION_UPDATE_DRAFT = SUBMISSION_BASE_URL + '/update/draft';
export const SUBMISSION_DELETE_DRAFT = SUBMISSION_BASE_URL + '/delete/draft';
export const SUBMISSION_SUBMIT = SUBMISSION_BASE_URL + '/submit';
export const SUBMISSION_GET_DRAFT = SUBMISSION_BASE_URL + '/draft';
export const SUBMISSION_FILE_DOWNLOAD = SUBMISSION_BASE_URL + '/file/download/:id';
export const SUBMISSION_TEMPLATE_DOWNLOAD = SUBMISSION_BASE_URL + '/template';
export const SUBMISSION_FINAL_TEMPLATE_DOWNLOAD = SUBMISSION_BASE_URL + '/template-final-submission';
export const SUBMISSION_TEMPLATE_PDF_DOWNLOAD = SUBMISSION_BASE_URL + '/template-pdf';
export const SUBMISSION_DETAILS = SUBMISSION_BASE_URL + '/details/:id';
export const SUBMISSION_ADD_REVISION = SUBMISSION_BASE_URL + '/add/revision';
export const SUBMISSION_SUBMIT_REVISION = SUBMISSION_BASE_URL + '/submit/revision';

// ------ REVIEW ------
// User Role : Reviewer
export const REVIEW_BASE_URL = API_URL + "/review";
export const REVIEW_GET_REVIEWS = REVIEW_BASE_URL + '/submissions';
export const REVIEW_GET_SUBMISSION = REVIEW_BASE_URL + '/submission/:id';
export const REVIEW_COMMENT_SUBMISSION = REVIEW_BASE_URL + '/comment/submission';
export const REVIEW_SUBMISSION_DETAILS = REVIEW_BASE_URL + '/details/:id';


// ------ CHAIR ------
// User Role : Chair
export const CHAIR_BASE_URL = API_URL + "/chair";
export const CHAIR_GET_SUBMISSIONS = CHAIR_BASE_URL + '/submissions';
export const CHAIR_GET_SUBMISSION = CHAIR_BASE_URL + '/submission/:id';
export const CHAIR_GET_REVIEWER_PANEL = CHAIR_BASE_URL + '/reviewers';
export const CHAIR_FIND_AUTHOR = CHAIR_BASE_URL + '/find/author';
export const CHAIR_ADD_REVIEWERS = CHAIR_BASE_URL + '/add/reviewers';
export const CHAIR_COMMENT_SUBMISSION = CHAIR_BASE_URL + '/comment/submission';
export const CHAIR_SUBMISSION_DETAILS = CHAIR_BASE_URL + '/details/:id';
export const CHAIR_SUBMISSION_EXCEL = CHAIR_BASE_URL + '/submission-excel';
export const CHAIR_SUBMISSION_EXCEL_AUTHOR = CHAIR_BASE_URL + '/submission-author-details';


// ----- ADMIN -----
// User Role : Admin
export const ADMIN_BASE_URL = API_URL + "/admin";