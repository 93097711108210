import React, { useState, useEffect, createContext, useContext } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { MdOutlineSubtitles, MdOutlinePayment } from "react-icons/md";
import { FaUserFriends } from "react-icons/fa";
import { FiUpload } from "react-icons/fi";
import { VscSymbolKeyword, VscOpenPreview } from "react-icons/vsc";
import { CgDetailsMore } from "react-icons/cg";
import API from "../../components/Api/Axios";
import {
  SUBMISSION_GET_DRAFT,
  SUBMISSION_NEW_DRAFT,
} from "../../components/Api/Endpoints";
import { AuthContext } from "../../components/Auth/AuthContext";
import { HiOutlineTemplate } from "react-icons/hi";

export const NewSubmissionContext = createContext();

const initialNewSubmissionState = {
  id: "",
  type: "",
  title: "",
  abstract: "",
  fileUpload: null,
  keywords: [],
  authors: [],
  coverLetter: "",
  numberOfFigures: "",
  numberOfTables: "",
  author: "",
  numberOfWords: "",
  confirm1: false,
  confirm2: false,
  confirm3: false,
  conflictOfInterest: false,
  conflicts: "",
};

const links = [
  {
    progressCheck: 0,
    title: "Template Instructions",
    link: "/new-submission/template-instructions",
    icon: <HiOutlineTemplate />,
  },
  {
    progressCheck: 0,
    title: "Type, Title & Abstract",
    link: "/new-submission/type-title-abstract",
    icon: <MdOutlineSubtitles />,
  },
  {
    progressCheck: 1,
    title: "File Upload",
    link: "/new-submission/file-upload",
    icon: <FiUpload />,
  },
  {
    progressCheck: 2,
    title: "Attributes",
    link: "/new-submission/attributes",
    icon: <VscSymbolKeyword />,
  },
  {
    progressCheck: 3,
    title: "Authors & Institutions",
    link: "/new-submission/authors",
    icon: <FaUserFriends />,
  },
  {
    progressCheck: 4,
    title: "Details & Comments",
    link: "/new-submission/details",
    icon: <VscOpenPreview />,
  },
  {
    progressCheck: 5,
    title: "Review & Summary",
    link: "/new-submission/summary",
    icon: <CgDetailsMore />,
  },
];

const NewSubmission = () => {
  const location = useLocation();
  const { auth } = useContext(AuthContext);
  const navigate = useNavigate();

  const [draft, setDraft] = useState(initialNewSubmissionState);

  useEffect(() => {
    if (auth === null || auth === undefined || auth === {}) {
      navigate('/login');
    }
  }, [auth])

  useEffect(() => {
    
    API.post(SUBMISSION_NEW_DRAFT, {})
      .then((res) => {
        const data = res.data.data;
        setDraft({ ...initialNewSubmissionState, ...data });
      })
      .catch((err) => {
        // console.log("ERROR", err);
        if (err.response) {
          if (err.response.status === 400) {
            // console.log("Error failed with 400 error");
            API.get(SUBMISSION_GET_DRAFT)
              .then((res) => {
                const data = res.data.data;
                if (data) {
                  // console.log(data);
                  setDraft({
                    ...initialNewSubmissionState,
                    ...data,
                  });
                }
              })
              .catch((err) => {
                // console.log("ERROR", err);
                navigate('/');
              });
          } else {
            navigate("/");
          }
        } else {
          navigate('/');
        }

      });

  }, []);

  return (
    <NewSubmissionContext.Provider value={{ draft, setDraft }}>
      <section className="pt-20 lg:pt-6 lg:px-[calc(100vw/12)] pb-6 font-nunito flex flex-col divide-y-2 lg:divide-y-0 lg:flex-row divide-gray h-full">
        <nav className="w-full lg:w-1/4 px-6 py-8 lg:flex flex-col space-y-2">
          {/* {links.map((link, idx) => {
            return draft.progressStep >= link.progressCheck ? (
              <Link to="/new-submission/template-instructions">
                <button
                  className={`flex items-center space-x-4 w-full px-2 py-1 rounded-md ${location.pathname === link.link
                    ? "bg-light-violet text-violet"
                    : "text-slate"
                    }`}
                >
                  {link.icon}
                  <p className="text-left">{link.title}</p>
                </button>
              </Link>
            ) : (
              <div
                className={`flex items-center space-x-4 w-full px-2 py-1 rounded-md ${location.pathname === link.link
                  ? "bg-light-violet text-violet"
                  : "text-slate"
                  }`}
              >
                {link.icon}
                <p className="text-left">{link.title}</p>
              </div>
            );
          })} */}
          <Link to="/new-submission/template-instructions">
            <button
              className={`flex items-center space-x-4 w-full px-2 py-1 rounded-md ${location.pathname === "/new-submission" ||
                location.pathname === "/new-submission/template-instructions"
                ? "bg-light-violet text-violet"
                : "text-slate"
                }`}
            >
              <HiOutlineTemplate />
              <p className="text-left">Template Instructions</p>
            </button>
          </Link>
          <Link to="/new-submission/type-title-abstract">
            <button
              className={`flex items-center space-x-4 w-full px-2 py-1 rounded-md ${location.pathname === "/new-submission/type-title-abstract"
                ? "bg-light-violet text-violet"
                : "text-slate"
                }`}
            >
              <MdOutlineSubtitles />
              <p className="text-left">Type, title & Abstract</p>
            </button>
          </Link>
          <Link to="/new-submission/file-upload">
            <button
              className={`flex items-center space-x-4 w-full px-2 py-1 rounded-md ${location.pathname === "/new-submission/file-upload"
                ? "bg-light-violet text-violet"
                : "text-slate"
                }`}
            >
              <FiUpload />
              <p className="text-left">File Upload</p>
            </button>
          </Link>
          <Link to="/new-submission/attributes">
            <button
              className={`flex items-center space-x-4 w-full px-2 py-1 rounded-md ${location.pathname === "/new-submission/attributes"
                ? "bg-light-violet text-violet"
                : "text-slate"
                }`}
            >
              <VscSymbolKeyword />
              <p className="text-left">Attributes</p>
            </button>
          </Link>
          <Link to="/new-submission/authors">
            <button
              className={`flex items-center space-x-4 w-full px-2 py-1 rounded-md ${location.pathname === "/new-submission/authors"
                ? "bg-light-violet text-violet"
                : "text-slate"
                }`}
            >
              <FaUserFriends />
              <p className="text-left">Authors & Institutions</p>
            </button>
          </Link>
          <Link to="/new-submission/details">
            <button
              className={`flex items-center space-x-4 w-full px-2 py-1 rounded-md ${location.pathname === "/new-submission/details"
                ? "bg-light-violet text-violet"
                : "text-slate"
                }`}
            >
              <CgDetailsMore />
              <p className="text-left">Details & Comments</p>
            </button>
          </Link>
          {/* <Link to="/new-submission/payment">
            <button
              className={`flex items-center space-x-4 w-full px-2 py-1 rounded-md ${location.pathname === "/new-submission/payment"
                ? "bg-light-violet text-violet"
                : "text-slate"
                }`}
            >
              <MdOutlinePayment />
              <p className="text-left">Payment</p>
            </button>
          </Link> */}
          <Link to="/new-submission/summary">
            <button
              className={`flex items-center space-x-4 w-full px-2 py-1 rounded-md ${location.pathname === "/new-submission/summary"
                ? "bg-light-violet text-violet"
                : "text-slate"
                }`}
            >
              <VscOpenPreview />
              <p className="text-left">Review & Submit</p>
            </button>
          </Link>
        </nav>
        <article className="w-full lg:w-3/4 px-6 py-8 h-full">
          <Outlet />
        </article>
      </section>
    </NewSubmissionContext.Provider>
  );
};

export default NewSubmission;
