import React, { useContext, useEffect, useState } from 'react'
import Inputfield from '../../components/Forms/Inputfield'
import { AiOutlineCheckCircle, AiFillCheckCircle } from "react-icons/ai";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import API from '../../components/Api/Axios';
import { AUTH_RESET_PASSWORD } from '../../components/Api/Endpoints';
import { PopupContext } from '../../components/PopupSystem/Popup';
import { BiCheck } from 'react-icons/bi';


const ChangePassword = () => {

  const navigate = useNavigate();

  // const search = useLocation().search;
  const { token } = useParams();

  const [, dispatch] = useContext(PopupContext);

  const [password1, setPassword1] = useState("")
  const [password2, setPassword2] = useState("")
  const [password1Error, setPassword1Error] = useState("")
  const [password2Error, setPassword2Error] = useState("")

  const hasMin8 = pw => pw.length >= 8
  const hasUpper = pw => /[A-Z]/.test(pw)
  const hasNumber = pw => /[0-9]/.test(pw)
  const hasSpecial = pw => /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(pw)

  const changePassword = async (e) => {

    e.preventDefault();

    try {

      const res = await API.post(AUTH_RESET_PASSWORD, {
        token,
        password: password1
      });

      const data = res.data;

      dispatch({
        type: "SHOW",
        payload: {
          title: "Password has been changed succesfully",
          body: "Now you can login using the new password",
          theme: "green",
          icon: <BiCheck />,
          successText: "Ok",
          cancelText: "",
          noCancel: true,
          handleSuccess: function (e) {
            navigate('/login');
            dispatch({
              type: "HIDE",
            });
          },
          handleCancel: function (e) { },
        },
      })

    } catch (err) {
      // console.log(err);
    }

  }

  useEffect(() => {
    if (!token) {
      // console.log("No Token!");
    }
  }, []);

  return (
    <div className="">
      <h1 className="font-bold text-3xl">Forgot your password ?</h1>
      <h2 className="text-dark-gray text-lg">Change Password</h2>
      <form className="flex flex-col space-y-4 items-center lg:items-start w-full py-6">
        <div className="flex w-full flex-col lg:flex-row space-y-4 lg:space-y-0 lg:space-x-4">
          <div className="flex space-y-4 flex-col w-full lg:w-1/2">
            <Inputfield
              errorState={[password1Error, setPassword1Error]}
              valueState={[password1, setPassword1]}
              placeholder="aBc!1234"
              type="password"
              title="Enter new password"
              className=" w-full"
            />
            <Inputfield
              errorState={[password2Error, setPassword2Error]}
              valueState={[password2, setPassword2]}
              placeholder="aBc!1234"
              type="password"
              title="Confirm password"
              className="w-full"
            />
          </div>
          <div className="flex space-y-4 flex-col w-full lg:w-1/2 h-full justify-center lg:p-6">
            <div className="flex space-x-2 items-center text-violet">
              {hasMin8(password1) ? (
                <AiFillCheckCircle />
              ) : (
                <AiOutlineCheckCircle />
              )}
              <p className="text-sm">Has Minimum 8 characters</p>
            </div>
            <div className="flex space-x-2 items-center text-violet">
              {hasUpper(password1) ? (
                <AiFillCheckCircle />
              ) : (
                <AiOutlineCheckCircle />
              )}
              <p className="text-sm">Has an Uppercase letter</p>
            </div>
            <div className="flex space-x-2 items-center text-violet">
              {hasNumber(password1) ? (
                <AiFillCheckCircle />
              ) : (
                <AiOutlineCheckCircle />
              )}
              <p className="text-sm">Has a number</p>
            </div>
            <div className="flex space-x-2 items-center text-violet">
              {hasSpecial(password1) ? (
                <AiFillCheckCircle />
              ) : (
                <AiOutlineCheckCircle />
              )}
              <p className="text-sm">Has a special character</p>
            </div>
          </div>
        </div>
        <button onClick={changePassword} className="my-4 px-16 lg:px-8 py-2 text-lg bg-violet text-white font-semibold w-fit rounded-lg shadow-lg">
          Change Password
        </button>
      </form>
    </div>
  );
}

export default ChangePassword