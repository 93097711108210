export const collegeData = [
    "PSG College of Technology",
    "PSG College of Arts and Science",
    "Hindusthan College of Arts & Science",
    "Adhiyamaan college of engineering",
    "Anna University",
    "Ardor Biomed India PVT LTD",
    "Bannari Amman Institute of Technology",
    "Bharathiar University",
    "Capgemini Technology Services India Limited",
    "Coimbatore Institute of Technology",
    "Dr.N.G.P. Institute of Technology",
    "EASA College of Engineering and Technology",
    "Francis Xavier Engineering College",
    "Government College of Engineering",
    "Government College of Engineering, Dharmapuri",
    "JCT College of Engineering and Technology",
    "K S R Institute for Engineering and Technology",
    "Kamaraj College of Engineering and Technology",
    "Karpagam Academy of Higher Education",
    "Karpagam College of Engineering",
    "Kalaignarkarunanidhi Institute of Technology",
    "Kongu Engineering College",
    "KPR Institute of Engineering and Technology",
    "Kumaraguru College of Technology",
    "Madurai Kamaraj University",
    "Madras Institute of Technology, Anna University",
    "Multicoreware Inc",
    "Nallamuthu Gounder Mahalingam College",
    "National Institute of Fashion Technology",
    "Nirmala College for Women",
    "PSG Institue of Technology and Applied Research",
    "PSG Institute of Management",
    "PSG Polytechnic College",
    "Rajalakshmi Engineering College",
    "Sethu Institute of Technology",
    "SNS College of Engineering",
    "SNS college of Technology",
    "Sri Eshwar College of Engineering",
    "Sri Krishna College of Engineering and Technology",
    "Sri Krishna College of Technology",
    "Sri Ramakrishna Institute of Technology",
    "Sri Shakthi institute of Engineering and Technology",
    "SRM Easwari Engineering College",
    "Vallurupalli Nageswara Rao Vignana Jyothi Institute of Engineering and Technology",
    "Bannari Amman Institute of Technology",
    "Jansons Institute of Technology, Coimbatore",
    "Bellcorp Studio",
    "Saveetha Engineering College"
]