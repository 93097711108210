import React from 'react'

const DialogCard = ({children, theme="violet"}) => {
  return (
    <div className={`bg-${theme} w-full rounded-lg shadow-lg p-0.5 pl-6`}>
        <div className="bg-white w-full rounded-md h-full px-4 lg:px-6 py-2">
            {children}
        </div>
    </div>
  )
}

export default DialogCard