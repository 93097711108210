import React, { useContext, useEffect, useState } from "react";
import Dropdown from "../../components/Forms/Dropdown";
import Inputfield from "../../components/Forms/Inputfield";
import { validateEmail } from "../../components/Forms/validations";
import { AiFillExclamationCircle } from "react-icons/ai";
import { HiOutlineTrash } from "react-icons/hi";
import { FaLongArrowAltUp, FaLongArrowAltDown } from "react-icons/fa";
import { PopupWindowContext } from "../../components/PopupWindow/PopupWindow";
import AddCoAuthor from "./AddCoAuthor";
import { NewSubmissionContext } from "./NewSubmission";
import API from "../../components/Api/Axios";
import {
  SUBMISSION_FIND_AUTHOR,
  SUBMISSION_UPDATE_DRAFT,
} from "../../components/Api/Endpoints";
import { useNavigate } from "react-router-dom";
// const initialAuthors = [];

const Authors = () => {
  const [, dispatch] = useContext(PopupWindowContext);

  const navigate = useNavigate();

  const { draft, setDraft } = useContext(NewSubmissionContext);
  const [authors, setAuthors] = useState([]);
  const [authorError, setAuthorError] = useState("");

  const [existingAuthorEmail, setExistingAuthorEmail] = useState("");
  const [existingAuthorEmailError, setExistingAuthorEmailError] = useState("");

  const [isAuthorExists, setIsAuthorExists] = useState(false);

  const [authorEmail, setAuthorEmail] = useState("");
  const [authorName, setAuthorName] = useState("");
  const [authorInstitution, setAuthorInstitution] = useState("");
  const [authorRole, setAuthorRole] = useState("");
  const [authorEmailError, setAuthorEmailError] = useState("");
  const [authorNameError, setAuthorNameError] = useState("");
  const [authorInstitutionError, setAuthorInstitutionError] = useState("");
  const [authorRoleError, setAuthorRoleError] = useState("");

  const [maxAuthorError, setMaxAuthorError] = useState("");

  // const handleAdd = (e) => {

  //   e.preventDefault();

  //   const isValid =
  //     authors.length <= 3 &&
  //     authorEmail.length > 0 &&
  //     authorName.length > 0 &&
  //     authorInstitution.length > 0 &&
  //     authorRole.length > 0 &&
  //     validateEmail(authorEmail);
  //   if (authorEmail.length === 0) setAuthorEmailError("Please enter email");
  //   if (authors.length === 4)
  //     setMaxAuthorError("Maximum 4 authors only allowed");
  //   if (!validateEmail(authorEmail))
  //     setAuthorEmailError("Enter a valid email address");
  //   if (authorName.length === 0) setAuthorNameError("Please enter name");
  //   if (authorInstitution.length === 0)
  //     setAuthorInstitutionError("Please enter institution");
  //   if (authorRole.length === 0) setAuthorRoleError("Please enter role");

  //   if (!isValid) return;
  //   else {
  //     setAuthors([
  //       ...authors,
  //       {
  //         name: authorName,
  //         email: authorEmail,
  //         institution: authorInstitution,
  //       },
  //     ]);
  //   }
  // };

  // const checkLength = () => {
  //   console.log(authors);
  //   if (authors.length > 3) {
  //     setExistingAuthorEmailError("Maximum 4 authors only allowed");
  //     return false;
  //   } else {
  //     setExistingAuthorEmailError("");
  //     return true;
  //   }
  // }

  const handleSaveAndProceed = (e) => {

    e.preventDefault();

    // const filteredAuthors = authors.map(author => {
    //   return Object.keys(author).filter(key => key !== 'isUser').reduce((obj, key) => {
    //     obj[key] = author[key];
    //     return obj;
    //   }, {});
    // })

    if (authors && authors.length > 0) {
      API.post(SUBMISSION_UPDATE_DRAFT, {
        id: draft.id,
        authors: authors,
      })
        .then((res) => {
          setDraft({
            ...draft,
            authors,
          });
        })
        .then(() => {
          navigate("/new-submission/details");
        })
        .catch((err) => {
          // console.log(err);
        });
    }
  };

  // useEffect(() => {

  //   setDraft({
  //     ...draft,
  //     progressStep: false,
  //   })

  // }, [])

  useEffect(() => {

    if (draft.authors && draft.authors.length > 0) {
      setAuthors([
        ...draft.authors
      ]);
    }

  }, [draft]);

  const handleFetch = (e) => {
    e.preventDefault();

    API.post(SUBMISSION_FIND_AUTHOR, { email: existingAuthorEmail })
      .then((res) => {
        const data = res.data.data;

        let isOkay = true;
        authors.forEach((author) => {
          if (author.email === data.email) {
            // setIsAuthorExists(true);
            setExistingAuthorEmailError("Author already exists");
            isOkay = false;
            return;
          }
        });

        if (isOkay) {
          setAuthors((authors) => [...authors, data]);
        }

        setExistingAuthorEmail("");
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  return (
    <div className="">
      <h2 className="text-dark-gray text-lg">Step 4</h2>
      <h1 className="font-bold text-3xl">Add Authors/Institutions</h1>
      <form className="flex flex-col space-y-4 items-center lg:items-start w-full py-6">
        <div className="flex flex-col w-full divide-y divide-dark-gray overflow-x-auto">
          <div className="flex w-fit lg:w-full bg-gray">
            <div className="flex overflow-x-hidden text-xs lg:text-sm w-[150px] lg:flex-none  lg:w-1/12 items-center justify-start px-2 py-1">
              Order
            </div>
            <div className="flex overflow-x-hidden text-xs lg:text-sm w-[150px] lg:flex-none  lg:w-3/12 items-center justify-start px-2 py-1">
              Name
            </div>
            <div className="flex overflow-x-hidden text-xs lg:text-sm w-[150px] lg:flex-none  lg:w-3/12 items-center justify-start px-2 py-1">
              Email
            </div>
            <div className="flex overflow-x-hidden text-xs lg:text-sm  w-[200px] lg:flex-none  lg:w-4/12 items-center justify-start px-2 py-1">
              Institution
            </div>
            <div className="flex overflow-x-hidden text-xs lg:text-sm w-[50px] lg:flex-none lg:w-1/12 items-center justify-start px-2 py-1"></div>
          </div>
          {authors.map((author, index, self) => (
            <div className="flex w-fit lg:w-full bg-cloud" key={index}>
              <div className="flex  overflow-x-hidden text-xs lg:text-sm w-[50px] lg:w-1/12 items-center justify-start px-2 py-1">
                <p className="mr-1">{index + 1}</p>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    let temp = [...authors];
                    if (index !== 0) {
                      temp[index] = authors[index - 1];
                      temp[index - 1] = authors[index];
                    }
                    setAuthors(temp);
                  }}
                  className="hover:text-aqua"
                >
                  <FaLongArrowAltUp />
                </button>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    let temp = [...authors];
                    if (index !== self.length - 1) {
                      temp[index] = authors[index + 1];
                      temp[index + 1] = authors[index];
                    }
                    setAuthors(temp);
                  }}
                  className="hover:text-aqua"
                >
                  <FaLongArrowAltDown />
                </button>
              </div>
              <div className="flex overflow-x-hidden text-xs lg:text-sm w-[150px] lg:flex-none  lg:w-3/12 items-center justify-start px-2 py-1">
                {author.name}
              </div>
              <div className="flex overflow-x-hidden text-xs lg:text-sm w-[150px] lg:flex-none  lg:w-3/12 items-center justify-start px-2 py-1">
                {author.email}
              </div>
              <div className="flex overflow-x-hidden text-xs lg:text-sm  w-[200px] lg:flex-none  lg:w-4/12 items-center justify-start px-2 py-1">
                {author.collegeOrganisationName}
              </div>
              <div className="flex overflow-x-hidden text-xs lg:text-sm w-[50px] lg:flex-none lg:w-1/12 items-center justify-start px-2 py-1">
                {(draft.author !== author?.id) && <button
                  onClick={(e) => {
                    e.preventDefault();
                    setAuthors(
                      authors.filter((itm) => itm.email !== author.email)
                    );
                  }}
                  className="hover:text-red"
                >
                  <HiOutlineTrash />
                </button>}
              </div>
            </div>
          ))}
        </div>
        <h2 className="text-lg text-dark-gray">Add Co-Authors</h2>
        {maxAuthorError.length > 0 && (
          <div className="flex space-x-2 items-center">
            <AiFillExclamationCircle className="text-red" />

            <h3 className="text-red text-xs font-bold">{maxAuthorError}</h3>
          </div>
        )}

        <div className="flex flex-col space-y-4 w-full">
          <div className="flex flex-col lg:flex-row lg:items-start w-full lg:space-x-4">
            <Inputfield
              errorState={[
                existingAuthorEmailError,
                setExistingAuthorEmailError,
              ]}
              valueState={[existingAuthorEmail, setExistingAuthorEmail]}
              type="email"
              placeholder="Enter co-author's email"
              title="Already an existing user?"
              className="w-full lg:w-1/2"
            />
            <button
              onClick={(e) => handleFetch(e)}
              className="flex items-center mt-4 lg:mt-[2rem] h-fit space-x-2 px-6 py-2 bg-dark-gray text-white font-semibold w-fit rounded-lg"
            >
              <p className="">Fetch Author</p>
            </button>
          </div>
          <button
            className="text-aqua text-left hover:underline"
            onClick={(e) => {
              e.preventDefault();
              dispatch({
                type: "SHOW",
                payload: {
                  title: "Add CoAuthor",
                  element: (
                    <AddCoAuthor
                      authorsState={[authors, setAuthors]}
                      onAdd={() => {
                        dispatch({ type: "HIDE" });
                      }}
                      errorState={[
                        existingAuthorEmailError,
                        setExistingAuthorEmailError,
                      ]}
                    />
                  ),
                  handleClose: function (e) {
                    e.preventDefault();
                    dispatch({
                      type: "HIDE",
                    });
                  },
                },
              });
            }}
          >
            Not an existing user ?
          </button>
        </div>
        <div className="flex flex-col lg:flex-row lg:space-x-4 items-center ">
          <button className="my-4 px-16 lg:px-8 py-2 text-lg border-2 border-violet text-violet bg-white font-semibold w-fit rounded-lg shadow-lg">
            Discard Information
          </button>
          <button
            onClick={handleSaveAndProceed}
            className="my-4 px-16 lg:px-8 py-2 text-lg border-2 border-violet bg-violet text-white font-semibold w-fit rounded-lg shadow-lg"
          >
            Save and Proceed
          </button>
        </div>
      </form>
    </div>
  );
};

export default Authors;
