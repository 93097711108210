import React, { useContext, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import DialogCard from "../../components/Forms/DialogCard";
import { BsFillPatchCheckFill, BsCheck2 } from "react-icons/bs";
import { RiShareBoxLine } from "react-icons/ri";
import { IoMdCloseCircle, IoMdClose } from "react-icons/io";
import { HiDownload } from 'react-icons/hi';
import { MdPending } from "react-icons/md";
import { HiOutlinePencil } from "react-icons/hi";
import { PopupContext } from "../../components/PopupSystem/Popup";
import ErrorCloud from "../../assets/icons8-error-cloud-80.png";
import { DateTime, Interval } from "luxon";
import RevisionWindow from "./RevisionWindow";
import { PopupWindowContext } from "../../components/PopupWindow/PopupWindow";
import API from "../../components/Api/Axios";
import { CHAIR_GET_SUBMISSIONS, CHAIR_SUBMISSION_EXCEL, CHAIR_SUBMISSION_EXCEL_AUTHOR } from "../../components/Api/Endpoints";
import dummySubmissionData from "../../data/dummySubmissionData";
import { FiSend } from "react-icons/fi";

const checkDateFilter = (dateInput, filterCheck) => {
  const [date, time] = dateInput.split("T");
  const [year, month, day] = date.split("-").map((item) => parseInt(item));
  // const [hour, minute, second] = time.split(":").map((val) => parseInt(val));
  const now = DateTime.now();
  const later = DateTime.local(year, month, day);
  let i = Interval.fromDateTimes(later, now);
  const days = i.length("day");
  return (
    (days <= 1 && filterCheck === "TODAY") ||
    (days <= 7 && filterCheck === "THIS_WEEK") ||
    (days <= 30 && filterCheck === "THIS_MONTH")
  );
};

const SubmissionList = ({ statusFilter, dateFilter, reviewCountFilter, categoriesFilter }) => {

  const [submissionData, setSubmissionData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filterCount, setFilterCount] = useState(0);

  useEffect(() => {
    let tempData = submissionData.filter((sub, idx, self) => {
      return statusFilter === "EVERYTHING" || sub.status === statusFilter;
    });
    // tempData = tempData.filter((sub, idx, self) => {
    //   return (
    //     dateFilter === "EVERYTHING" ||
    //     checkDateFilter(sub.submittedAt, dateFilter)
    //   );
    //   // console.log(getDateFilter(sub.submittedAt));
    //   // return true;
    // });
    tempData = tempData.filter((sub, idx, self) => {
      return (
        categoriesFilter.length <= 0 || categoriesFilter.includes(sub.category)
      );
    });

    tempData = tempData.filter((sub, idx, self) => {

      if (reviewCountFilter === 0) {
        return sub.comments_count === 0;
      } else if (reviewCountFilter === 1) {
        return sub.comments_count === 1;
      } else if (reviewCountFilter === 2) {
        return sub.comments_count >= 2;
      } else {
        return true;
      }

    });


    tempData = tempData.sort((a, b) => {
      return (
        parseInt(a.manuscriptId.split("").slice(-6).join("")) - parseInt(b.manuscriptId.split("").slice(-6).join(""))
      )
    });

    setFilteredData(tempData);
    setFilterCount(tempData.length);
  }, [submissionData, statusFilter, dateFilter, reviewCountFilter, categoriesFilter]);


  useEffect(() => {

    let mounted = true;

    API.get(CHAIR_GET_SUBMISSIONS)
      .then((res) => {
        const submissions = res.data.data;
        if (mounted) setSubmissionData(submissions);
      }).catch((err) => {
        // console.log(err);
      })

    return () => { mounted = false; }

  }, []);

  const dowloadcsv = (e) => {
    // CHAIR_SUBMISSION_EXCEL
    e.preventDefault();
    API.get(
      CHAIR_SUBMISSION_EXCEL,
      {
        responseType: 'blob'
      }
    ).then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `RC2022_${new Date().toISOString().split('T')[0]}.csv`); //or any other extension
      document.body.appendChild(link);
      link.click();
    })
  }

  const dowloadcsv1 = (e) => {
    // CHAIR_SUBMISSION_EXCEL
    e.preventDefault();
    API.get(
      CHAIR_SUBMISSION_EXCEL_AUTHOR,
      {
        responseType: 'blob'
      }
    ).then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `RC2022_${new Date().toISOString().split('T')[0]}.csv`); //or any other extension
      document.body.appendChild(link);
      link.click();
    })
  }

  // useEffect(() => {
  //   setSubmissionData(dummySubmissionData);
  // }, []);

  return (
    <div className="">
      <div className="flex flex-row items-end w-full space-x-2">
        <h1 className="font-bold text-3xl text-slate grow">Admin Portal</h1>
        {/* <h1 className="text-slate">{`Count : ${filterCount}`}</h1> */}
        <h1 className="text-slate px-2 py-2 bg-violet text-white rounded-lg">{filterCount.toString()}</h1>
        <button onClick={dowloadcsv} className="text-slate px-2 py-3 bg-violet text-white rounded-lg"><HiDownload></HiDownload></button>
        <button onClick={dowloadcsv1} className="text-slate px-2 py-3 bg-red text-white rounded-lg"><HiDownload></HiDownload></button>
      </div>
      <div className="flex flex-col space-y-2 py-4">
        {submissionData.length <= 0 ? (
          <NilCard text="No submissions, yet :(" />
        ) : filteredData.length <= 0 ? (
          <NilCard text="No matches found !" />
        ) : (
          filteredData.map((sub) => {
            if (sub.status === "APPROVED") {
              return <ApprovedCard context={sub} />;
            } else if (sub.status === "APPROVED_PAID") {
              return <ApprovedCard context={sub} />;
            } else if (sub.status === "FINAL_SUBMITTED") {
              return <FinalSubmittedCard context={sub} />;
            } else if (sub.status === "REJECTED") {
              return <RejectedCard context={sub} />;
            } else if (sub.status === "UNDER_REVIEW") {
              return <UnderReviewCard context={sub} />;
            } else if (sub.status === "REVISE") {
              return <RevisedCard context={sub} />;
            } else if (sub.status === "UNREAD") {
              return <UnreadCard context={sub} />;
            }
            return "No Data";
          })
        )}
      </div>
    </div>
  );
};

export default SubmissionList;

export const NilCard = ({ text }) => {
  return (
    <div className="w-full flex flex-col items-center">
      <img src={ErrorCloud} alt="" className="" />
      <h1 className="text-gray font-bold text-lg">{text}</h1>
    </div>
  );
};

const UnreadCard = ({ context }) => {
  const navigate = useNavigate();

  const [, dispatch] = useContext(PopupContext);

  const color = context.revisions_count - 1 === 0 ? "violet" : "fuschia";

  return (
    <DialogCard theme={color}>
      <div className="flex flex-col lg:flex-row w-full lg:items-center select-none">
        <div className="flex flex-col">
          {/* <h2 className="text-dark-gray text-sm">
            Last updated:{" "}
            {`${context.submittedAt.split("T")[0]}, ${context.submittedAt.split("T")[1].split("-")[0]
              }`}
          </h2> */}
          <h2 className="text-dark-gray text-sm">
            ID: {" "}{context.manuscriptId}
          </h2>
          <h1 className="font-semibold text-slate text-lg">{context.title}</h1>
        </div>
        <div className="flex-1"></div>
        <div className="flex items-center space-x-4">
          <Link to={`/admin/paper-details/${context.id}`}>
            <button className="text-gray hover:text-dark-gray flex items-center">
              <RiShareBoxLine size={24} />
            </button>
          </Link>
          <div className="flex items-center space-x-2">
            {/* <MdPending className="text-violet" size={20} /> */}
            <BsFillPatchCheckFill className={`text-${color}`} size={20} />
            <div className={`font-semibold text-lg text-${color}`}>Unread</div>
          </div>
          <div className="flex items-center space-x-2">
            {/* <button
              onClick={(e) => {
                e.preventDefault();
                dispatch({
                  type: "SHOW",
                  payload: {
                    title: "Are you sure you want to approve?",
                    body: "You won't be able to undo this action",
                    theme: "green",
                    icon: <BsCheck2 />,
                    successText: "Approve",
                    cancelText: "Cancel",
                    handleSuccess: function (e) {
                      dispatch({
                        type: "HIDE",
                      });
                    },
                    handleCancel: function (e) {
                      dispatch({
                        type: "HIDE",
                      });
                    },
                  },
                });
              }}
              className="flex items-center p-1 border-2 border-green rounded-lg group bg-green hover:bg-light-green"
            >
              <BsCheck2
                className="text-white group-hover:text-green"
                size={20}
              />
            </button> */}
            {/* <button
              onClick={(e) => {
                e.preventDefault();
                dispatch({
                  type: "SHOW",
                  payload: {
                    title: "Are you sure you want to reject?",
                    body: "You won't be able to undo this action",
                    theme: "red",
                    icon: <IoMdClose />,
                    successText: "Reject",
                    cancelText: "Cancel",
                    handleSuccess: function (e) {
                      dispatch({
                        type: "HIDE",
                      });
                    },
                    handleCancel: function (e) {
                      dispatch({
                        type: "HIDE",
                      });
                    },
                  },
                });
              }}
              className="flex items-center p-1 rounded-lg bg-red hover:bg-light-red group border-2 border-red"
            >
              <IoMdClose
                className="text-white group-hover:text-red"
                size={20}
              />
            </button> */}
            {/* <button
              onClick={(e) => {
                e.preventDefault();
                dispatchWindow({
                  type: "SHOW",
                  payload: {
                    title: "Send to Revision",
                    element: <RevisionWindow paperID={context.id} />,
                    handleClose: function (e) {
                      e.preventDefault();
                      dispatchWindow({
                        type: "HIDE",
                      });
                    },
                  },
                });
              }}
              className="flex items-center p-1 rounded-lg bg-yellow hover:bg-light-yellow group border-2 border-yellow"
            >
              <HiOutlinePencil
                className="text-white group-hover:text-yellow"
                size={20}
              />
            </button> */}
            <button
              onClick={(e) => {
                e.preventDefault();
                navigate(`/admin/sendto-review/${context.id}`);
              }}
              className="flex items-center p-1 rounded-lg bg-aqua hover:bg-light-aqua group border-2 border-aqua"
            >
              <FiSend className="text-white group-hover:text-aqua" size={20} />
            </button>
            <p className={`text-slate p-1 bg-${color} text-white rounded-sm`}>{context.revisions_count - 1}</p>
          </div>
        </div>
      </div>
    </DialogCard>
  );
};

const FinalSubmittedCard = ({ context }) => {
  return (
    <DialogCard theme="teal">
      <div className="flex flex-col lg:flex-row w-full lg:items-center select-none">
        <div className="flex flex-col">
          {/* <h2 className="text-dark-gray text-sm">
            Last updated:{" "}
            {`${context.submittedAt.split("T")[0]}, ${context.submittedAt.split("T")[1].split("-")[0]
              }`}
          </h2> */}
          <h2 className="text-dark-gray text-sm">
            ID: {" "}{context.manuscriptId}
          </h2>
          <h1 className="font-semibold text-slate text-lg">{context.title}</h1>
        </div>
        <div className="flex-1"></div>
        <div className="flex items-center space-x-4">
          <Link to={`/admin/paper-details/${context.id}`}>
            <button className="text-gray hover:text-dark-gray flex items-center">
              <RiShareBoxLine size={24} />
            </button>
          </Link>
          <div className="flex items-center space-x-2">
            <BsFillPatchCheckFill className="text-teal" size={20} />
            <div className="font-semibold text-lg text-teal">Final Submitted</div>
            {/* <p className=" text-slate p-1 bg-green text-white rounded-sm">{context.revisions_count - 1}</p> */}
          </div>
        </div>
      </div>
    </DialogCard>
  );
};


const ApprovedCard = ({ context }) => {
  return (
    <DialogCard theme="green">
      <div className="flex flex-col lg:flex-row w-full lg:items-center select-none">
        <div className="flex flex-col">
          {/* <h2 className="text-dark-gray text-sm">
            Last updated:{" "}
            {`${context.submittedAt.split("T")[0]}, ${context.submittedAt.split("T")[1].split("-")[0]
              }`}
          </h2> */}
          <h2 className="text-dark-gray text-sm">
            ID: {" "}{context.manuscriptId}
          </h2>
          <h1 className="font-semibold text-slate text-lg">{context.title}</h1>
        </div>
        <div className="flex-1"></div>
        <div className="flex items-center space-x-4">
          <Link to={`/admin/paper-details/${context.id}`}>
            <button className="text-gray hover:text-dark-gray flex items-center">
              <RiShareBoxLine size={24} />
            </button>
          </Link>
          <div className="flex items-center space-x-2">
            <BsFillPatchCheckFill className="text-green" size={20} />
            <div className="font-semibold text-lg text-green">Accepted</div>
            <p className=" text-slate p-1 bg-green text-white rounded-sm">{context.revisions_count - 1}</p>
          </div>
        </div>
      </div>
    </DialogCard>
  );
};

const RejectedCard = ({ context }) => {
  return (
    <DialogCard theme="red">
      <div className="flex flex-col lg:flex-row w-full lg:items-center select-none">
        <div className="flex flex-col">
          {/* <h2 className="text-dark-gray text-sm">
            Last updated:{" "}
            {`${context.submittedAt.split("T")[0]}, ${context.submittedAt.split("T")[1].split("-")[0]
              }`}
          </h2> */}
          <h2 className="text-dark-gray text-sm">
            ID: {" "}{context.manuscriptId}
          </h2>
          <h1 className="font-semibold text-slate text-lg">{context.title}</h1>
        </div>
        <div className="flex-1"></div>
        <div className="flex items-center space-x-4">
          <Link to={`/admin/paper-details/${context.id}`}>
            <button className="text-gray hover:text-dark-gray flex items-center">
              <RiShareBoxLine size={24} />
            </button>
          </Link>
          <div className="flex items-center space-x-2">
            <IoMdCloseCircle className="text-red" size={20} />
            <div className="font-semibold text-lg text-red">Rejected</div>
            <p className=" text-slate p-1 bg-red text-white rounded-sm">{context.revisions_count - 1}</p>
          </div>
        </div>
      </div>
    </DialogCard>
  );
};

const UnderReviewCard = ({ context }) => {
  const navigate = useNavigate();

  const [, dispatch] = useContext(PopupContext);
  const [, dispatchWindow] = useContext(PopupWindowContext);
  return (
    <DialogCard theme="aqua">
      <div className="flex flex-col lg:flex-row w-full lg:items-center select-none">
        <div className="flex flex-col">
          {/* <h2 className="text-dark-gray text-sm">
            Last updated:{" "}
            {`${context.submittedAt.split("T")[0]}, ${context.submittedAt.split("T")[1].split("-")[0]
              }`}
          </h2> */}
          <h2 className="text-dark-gray text-sm">
            ID: {" "}{context.manuscriptId}
          </h2>
          <h1 className="font-semibold text-slate text-lg">{context.title}</h1>
        </div>
        <div className="flex-1"></div>
        <div className="flex items-center space-x-4">
          <Link to={`/admin/paper-details/${context.id}`}>
            <button className="text-gray hover:text-dark-gray flex items-center">
              <RiShareBoxLine size={24} />
            </button>
          </Link>
          <div className="flex items-center space-x-2">
            <MdPending className="text-aqua" size={20} />
            <div className="font-semibold text-lg text-aqua">Under Review</div>
          </div>
          <p>{context.comments_count} / {context.reviewers_count}</p>
          <div className="flex space-x-2 items-center">
            {/* <button
              onClick={(e) => {
                e.preventDefault();
                dispatch({
                  type: "SHOW",
                  payload: {
                    title: "Are you sure you want to approve?",
                    body: "You won't be able to undo this action",
                    theme: "green",
                    icon: <BsCheck2 />,
                    successText: "Approve",
                    cancelText: "Cancel",
                    handleSuccess: function (e) {
                      dispatch({
                        type: "HIDE",
                      });
                    },
                    handleCancel: function (e) {
                      dispatch({
                        type: "HIDE",
                      });
                    },
                  },
                });
              }}
              className="flex items-center p-1 border-2 border-green rounded-lg group bg-green hover:bg-light-green"
            >
              <BsCheck2
                className="text-white group-hover:text-green"
                size={20}
              />
            </button>
            <button
              onClick={(e) => {
                e.preventDefault();
                dispatch({
                  type: "SHOW",
                  payload: {
                    title: "Are you sure you want to reject?",
                    body: "You won't be able to undo this action",
                    theme: "red",
                    icon: <IoMdClose />,
                    successText: "Reject",
                    cancelText: "Cancel",
                    handleSuccess: function (e) {
                      dispatch({
                        type: "HIDE",
                      });
                    },
                    handleCancel: function (e) {
                      dispatch({
                        type: "HIDE",
                      });
                    },
                  },
                });
              }}
              className="flex items-center p-1 rounded-lg bg-red hover:bg-light-red group border-2 border-red"
            >
              <IoMdClose
                className="text-white group-hover:text-red"
                size={20}
              />
            </button> */}
            <button
              onClick={(e) => {
                e.preventDefault();
                navigate(`/admin/sendto-review/${context.id}`);
              }}
              className="flex items-center p-1 rounded-lg bg-aqua hover:bg-light-aqua group border-2 border-aqua"
            >
              <FiSend className="text-white group-hover:text-aqua" size={20} />
            </button>
            <button
              onClick={(e) => {
                e.preventDefault();
                dispatchWindow({
                  type: "SHOW",
                  payload: {
                    title: "Send to Revision",
                    element: <RevisionWindow paperID={context.id} navigate={navigate} />,
                    handleClose: function (e) {
                      e.preventDefault();
                      dispatchWindow({
                        type: "HIDE",
                      });
                    },
                  },
                });
              }}
              className="flex items-center p-1 rounded-lg bg-yellow hover:bg-light-yellow group border-2 border-yellow"
            >
              <HiOutlinePencil
                className="text-white group-hover:text-yellow"
                size={20}
              />
            </button>
            <p className=" text-slate p-1 bg-aqua text-white rounded-sm">{context.revisions_count - 2}</p>
          </div>
        </div>
      </div>
    </DialogCard>
  );
};

const RevisedCard = ({ context }) => {
  return (
    <DialogCard theme="yellow">
      <div className="flex flex-col lg:flex-row w-full lg:items-center select-none">
        <div className="flex flex-col">
          {/* <h2 className="text-dark-gray text-sm">
            Last updated:{" "}
            {`${context.submittedAt.split("T")[0]}, ${context.submittedAt.split("T")[1].split("-")[0]
              }`}
          </h2> */}
          <h2 className="text-dark-gray text-sm">
            ID: {" "}{context.manuscriptId}
          </h2>
          <h1 className="font-semibold text-slate text-lg">{context.title}</h1>
        </div>
        <div className="flex-1"></div>
        <div className="flex items-center space-x-4">
          <Link to={`/admin/paper-details/${context.id}`}>
            <button className="text-gray hover:text-dark-gray flex items-center">
              <RiShareBoxLine size={24} />
            </button>
          </Link>
          <div className="flex items-center space-x-2">
            <HiOutlinePencil className="text-yellow" size={20} />
            <div className="font-semibold text-lg text-yellow">
              Sent to Revision
            </div>
            <p className=" text-slate p-1 bg-yellow text-white rounded-sm">{context.revisions_count - 2}</p>
          </div>
        </div>
      </div>
    </DialogCard>
  );
};
