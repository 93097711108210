export const departmentData = [
    "Department of Computer Applications",
    "Department of Applied Sciences",
    "Department of Applied Mathematics and Computational Sciences",
    "Department of Apparel and Fashion Design",
    "Department of Apparel Technology",
    "Department of Electrical and Electronics Engineering",
    "Department of Artificial Intelligence and Data Science",
    "Department of Automobile Engineering",
    "Department of Automotive Engineering",
    "Department of Robotics and Automation Engineering",
    "Department of Biomedical Engineering",
    "Department of Biotechnology",
    "Department of Printing Technology",
    "Department of Chemistry",
    "Department of Civil Engineering",
    "Department of Computer Networking",
    "Department of Computer Science and Engineering",
    "Department of Electronics and Communication Engineering",
    "Department of Humanities",
    "Department of Information Technology",
    "Department of Production Engineering",
    "Department of Textile Technology",
    "Department of Electronics",
    "Department of Physics",
    "Department of Mechanical Engineering",
    "Department of English",
    "Department of Fashion Technology",
    "Department of Instrumentation and Control Systems Engineering",
    "Department of Information Science and Engineering",
    "Department of Library",
    "Department of Management",
    "Department of Management Studies",
    "Department of Mathematics",
    "Department of Science and Humanities",
    "Department of Structural Engineering",
    "School of Mathematics",
    "Business Solutions Group",
    "Center for Water Resources",
]