import React, { useEffect, useState, useReducer, useContext } from "react";
import "./styles/tailwind.output.css";
import { BrowserRouter } from "react-router-dom";
import Header from "./components/Header";

import Navbar from "./components/Navbar";
import Footer from "./components/Footer";

import Popup, { PopupContext } from "./components/PopupSystem/Popup";
import {
  initialPopupState,
  popupReducer,
} from "./components/PopupSystem/popupReducer";
import { AuthContext, AuthProvider } from "./components/Auth/AuthContext";
import PopupWindow, {
  PopupWindowContext,
} from "./components/PopupWindow/PopupWindow";
import {
  initialPopupWindowState,
  popupWindowReducer,
} from "./components/PopupWindow/popupWindowReducer";
import AppRoute from "./AppRoute";

const App = () => {
  const [viewport, setViewport] = useState({
    width: document.documentElement.clientWidth,
    height: document.documentElement.clientHeight,
  });

  useEffect(() => {
    window.addEventListener("resize", () => {
      setViewport({
        width: document.documentElement.clientWidth,
        height: document.documentElement.clientHeight,
      });
    });
  });

  const { auth } = useContext(AuthContext);

  return (
    <AuthProvider>
      <PopupWindowContext.Provider
        value={useReducer(popupWindowReducer, initialPopupWindowState)}
      >
        <PopupContext.Provider
          value={useReducer(popupReducer, initialPopupState)}
        >
          <main className="flex flex-col w-screen lg:h-screen  lg:overflow-y-hidden overflow-y-auto overflow-x-hidden p-0 m-0">
            <Header className="" />
            <BrowserRouter>
              <Navbar />
              <div
                className="flex-1 h-full lg:overflow-y-auto"
                style={
                  viewport.width < 768
                    ? { minHeight: `calc(100vh - 3rem)` }
                    : {}
                }
              >
                <AppRoute />
              </div>
            </BrowserRouter>
            <PopupWindow />
            <Popup />
            <Footer />
          </main>
        </PopupContext.Provider>
      </PopupWindowContext.Provider>
    </AuthProvider>
  );
};

export default App;
