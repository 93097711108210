import React from "react";
import {
  AiFillCheckCircle,
  AiFillCloseCircle,
} from "react-icons/ai";
import { MdPending } from "react-icons/md";
import { BiEditAlt } from "react-icons/bi";
import { RiShareBoxFill, RiShareBoxLine } from 'react-icons/ri'
import { Link } from "react-router-dom";
import { BsFillPatchCheckFill } from "react-icons/bs";
import { IoMdCloseCircle } from "react-icons/io";
import { HiOutlinePencil } from "react-icons/hi";

const SquareTile = ({ status, tagline, title, link }) => {
  const statusEnum = {
    APPROVED: {
      color: "#27d827",
      icon: <BsFillPatchCheckFill className="text-green" />,
      text: "Accepted",
    },
    APPROVED_PAID: {
      color: "#27d827",
      icon: <BsFillPatchCheckFill className="text-green" />,
      text: "Accepted & Paid",
    },
    FINAL_SUBMITTED: {
      color: "#0D9488",
      icon: <BsFillPatchCheckFill className="text-teal" />,
      text: "Final Submitted",
    },
    REJECTED: {
      color: "#d32736",
      icon: <IoMdCloseCircle className="text-red" />,
      text: "Rejected",
    },
    REVISE: {
      color: "#d3d027",
      icon: <HiOutlinePencil className="text-yellow" />,
      text: "Awaiting Revision",
    },
    UNDER_REVIEW: {
      color: "#79cce3",
      icon: <MdPending className="text-aqua" />,
      text: "Under Review",
    },
    UNREAD: {
      color: "#6e3cbc",
      icon: <BsFillPatchCheckFill className="text-violet" />,
      text: "Submitted",
    },
  };

  return (
    <div className="shadow-lg flex flex-col h-full w-full rounded-lg">
      <div className="flex-1 w-full  aspect-square text-ellipsis overflow-y-hidden rounded-t-lg p-6 bg-gradient-to-br from-violet  to-[#5dcceb]">
        <h1 className="font-bold text-white text-lg">{title}</h1>
      </div>
      <div className="flex items-end px-6 py-2">
        <div className="">
          <p className="text-xs text-dark-gray">Status</p>
          <div
            className={`flex space-x-2 text-sm items-center`}
            style={{ color: statusEnum[status].color }}
          >
            {statusEnum[status].icon}
            <p className="">{statusEnum[status].text}</p>
          </div>
        </div>
        <div className="flex-1"></div>
        <div className="text-base">
          {status === "incomplete" ? (
            <Link to={link}>
              <button className="">
                <BiEditAlt
                  size={24}
                  className="hover:text-slate text-dark-gray"
                />
              </button>
            </Link>
          ) : (
            <Link to={link}>
              <button className="">
                <RiShareBoxFill
                  size={24}
                  className="hover:text-slate text-dark-gray"
                />
              </button>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default SquareTile;
