import React, { useState, useContext, useEffect } from "react";
import Dropdown from "../../components/Forms/Dropdown";
import Inputfield from "../../components/Forms/Inputfield";
import { PopupContext } from "../../components/PopupSystem/Popup";
import {
  validateEmail,
  validateNumber,
} from "../../components/Forms/validations";
import axios from "axios";
import { createSearchParams, useNavigate } from "react-router-dom";
import { BiError, BiCheck } from "react-icons/bi";
import { BsExclamationCircle } from "react-icons/bs"
import API from "../../components/Api/Axios";
import {
  AUTH_REGISTER,
  CHAIR_FIND_AUTHOR,
  SUBMISSION_FIND_AUTHOR,
} from "../../components/Api/Endpoints";
import PhonenumberInput from "../../components/Forms/PhonenumberInput";
import categoriesData from "../../data/categoriesData";
import ErrorDialog from "../../components/Forms/ErrorDialog";

const AddReviewer = ({ emailInitial, handleInvite, addedReviewerState, errorState = ["", () => { }] }) => {

  const [reviewersAdded, setReviewersAdded] = addedReviewerState;

  const [authorId, setAuthorId] = useState("");
  const [error, setError] = errorState;

  const [available, setAvailable] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [firstNameError, setFirstNameError] = useState("");

  const [lastName, setLastName] = useState("");
  const [lastNameError, setLastNameError] = useState("");

  const [title, setTitle] = useState("Mr.");
  const [titleError, setTitleError] = useState("");

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState("");

  const [orgName, setOrgName] = useState("");
  const [orgNameError, setOrgNameError] = useState("");

  const [orgCity, setOrgCity] = useState("");
  const [orgCityError, setOrgCityError] = useState("");

  const [profession, setProfession] = useState("Student");
  const [professionError, setProfessionError] = useState("");

  const [designation, setDesignation] = useState("");
  const [designationError, setDesignationError] = useState("");

  const [department, setDepartment] = useState("");
  const [departmentError, setDepartmentError] = useState("");

  const [expertiseCategory, setExpertiseCategory] = useState("");
  const [expertiseCategoryError, setExpertiseCategoryError] = useState("");

  useEffect(() => {

    // setEmail(emailInitial);
    API.post(CHAIR_FIND_AUTHOR, { email: emailInitial })
      .then((res) => {
        const data = res.data.data;
        if (data) {
          setAuthorId(data.id);
          setFirstName(data.firstName);
          setLastName(data.lastName);
          setTitle(data.title);
          setEmail(data.email);
          setPhone(data.mobileNumber);
          setOrgName(data.collegeOrganizationName);
          setOrgCity(data.collegeOrganizationCity);
          setProfession(data.profession);
          setDesignation(data.programmeOrDesignation);
          setDepartment(data.deptOrTeam);
          setAvailable(true);
        }
      })
      .catch((err) => {
        setEmail(emailInitial);
      });

  }, []);

  const handleSaveAndProceed = (e) => {
    e.preventDefault();

    const formatTitle = (title) => {
      return title.toUpperCase().slice(0, title.length - 1);
    };

    const formatProfession = (profession) => {
      return profession.toUpperCase();
    };

    const isValid = () =>
      title.length > 0 &&
      firstName.length > 0 &&
      lastName.length > 0 &&
      email.length > 0 &&
      validateEmail(email) &&
      phone.length > 0 &&
      orgName.length > 0 &&
      orgCity.length > 0 &&
      profession.length > 0 &&
      designation.length > 0 &&
      expertiseCategory.length > 0 &&
      department.length > 0;

    if (title.length <= 0) setTitleError("Select Designation");
    if (firstName.length <= 0) setFirstNameError("Enter field");
    if (lastName.length <= 0) setLastNameError("Enter field");
    if (email.length <= 0) setEmailError("Enter field");
    if (!validateEmail(email)) setEmailError("Invalid Email address");
    if (phone.length <= 0) setPhoneError("Enter field");
    if (orgName.length <= 0) setOrgNameError("Enter field");
    if (orgCity.length <= 0) setOrgCityError("Enter field");
    if (profession.length <= 0) setProfession("Choose profession");
    if (designation.length <= 0) setDesignationError("Enter field");
    if (department.length <= 0) setDepartmentError("Enter field");
    if (expertiseCategory.length <= 0) setExpertiseCategoryError("Enter field");

    if (!isValid()) {
      return;
    } else {
      // TODO: Register a reviewer

      if (available) {

        setReviewersAdded([
          ...reviewersAdded,
          {
            id: authorId,
            email: email,
            category: expertiseCategory,
            invitedNow: true,
          }
        ]);

      } else {

        setReviewersAdded([
          ...reviewersAdded,
          {
            firstName,
            lastName,
            title: formatTitle(title),
            email,
            mobileNumber: phone,
            collegeOrganisationCity: orgCity,
            collegeOrganisationName: orgName,
            profession: formatProfession(profession),
            programmeOrDesignation: designation,
            deptOrTeam: department,
            category: expertiseCategory,
            invitedNow: true
          }
        ]);

      }

      handleInvite();

    }
  };

  return (
    <div className="">
      <h2 className="text-dark-gray text-lg">Account details</h2>

      <form className="flex flex-col space-y-4 items-center lg:items-start w-full py-6">
        <div className="flex lg:items-start space-y-4 lg:space-y-0 lg:space-x-4 flex-col lg:flex-row w-full">
          <Dropdown
            errorState={[titleError, setTitleError]}
            valueState={[title, setTitle]}
            placeholder=""
            title="Title"
            isDisabled={available}
            className="lg:w-2/12 w-full"
            options={["Mr.", "Dr.", "Ms.", "Prof."]}
          />
          <Inputfield
            errorState={[firstNameError, setFirstNameError]}
            valueState={[firstName, setFirstName]}
            placeholder="John"
            type="text"
            isDisabled={available}
            title="First Name"
            className="lg:w-5/12 w-full"
          />
          <Inputfield
            errorState={[lastNameError, setLastNameError]}
            valueState={[lastName, setLastName]}
            placeholder="Doe"
            isDisabled={available}
            type="text"
            title="Last Name"
            className="lg:w-5/12 w-full"
          />
        </div>
        <div className="flex lg:items-start space-y-4 lg:space-y-0 lg:space-x-4 flex-col lg:flex-row w-full">
          <Inputfield
            errorState={[emailError, setEmailError]}
            valueState={[email, setEmail]}
            placeholder="johndoe@xmail.com"
            isDisabled={available}
            type="email"
            title="Email ID"
            className="lg:w-6/12 w-full"
          />
          <PhonenumberInput
            errorState={[phoneError, setPhoneError]}
            valueState={[phone, setPhone]}
            isDisabled={available}
            placeholder="+911234567890"
            className="lg:w-1/2 w-full"
            title="Mobile Number"
          />
        </div>
        <div className="flex lg:items-start space-y-4 lg:space-y-0 lg:space-x-4 flex-col lg:flex-row w-full">
          <Inputfield
            errorState={[orgNameError, setOrgNameError]}
            valueState={[orgName, setOrgName]}
            isDisabled={available}
            placeholder=""
            type="text"
            title="College/Organization Name"
            className="lg:w-4/12 w-full"
          />
          <Inputfield
            errorState={[orgCityError, setOrgCityError]}
            valueState={[orgCity, setOrgCity]}
            isDisabled={available}
            placeholder=""
            type="text"
            title="College/Organization City"
            className="lg:w-4/12 w-full"
          />
          <Dropdown
            errorState={[expertiseCategoryError, setExpertiseCategoryError]}
            valueState={[expertiseCategory, setExpertiseCategory]}
            placeholder="Choose Category"
            title="Expertise Category"
            className="lg:w-4/12 w-full"
            options={categoriesData}
          />
        </div>
        <div className="flex lg:items-start space-y-4 lg:space-y-0 lg:space-x-4 flex-col lg:flex-row w-full">
          <Dropdown
            errorState={[professionError, setProfessionError]}
            valueState={[profession, setProfession]}
            isDisabled={available}
            placeholder=""
            title="Profession"
            className="lg:w-3/12 w-full"
            options={["Student", "Faculty", "Industry"]}
          />
          <Inputfield
            errorState={[designationError, setDesignationError]}
            valueState={[designation, setDesignation]}
            placeholder=""
            isDisabled={available}
            type="text"
            title="Programmee/Designation"
            className="lg:w-5/12 w-full"
          />
          <Inputfield
            errorState={[departmentError, setDepartmentError]}
            valueState={[department, setDepartment]}
            placeholder=""
            isDisabled={available}
            type="text"
            title="Department/Team"
            className="lg:w-4/12 w-full"
          />
        </div>
        <div className="flex flex-col lg:flex-row lg:space-x-4 items-center w-full">
          <button
            onClick={(e) => handleSaveAndProceed(e)}
            className="my-4 px-16 lg:px-8 py-2 text-lg bg-violet text-white font-semibold w-fit rounded-lg shadow-lg"
          >
            Invite
          </button>
          {available && <ErrorDialog
            theme="violet"
            className="w-full"
            icon={<BsExclamationCircle />}
          >
            The user already exists. Kindly choose the appropriate "Expertise
            Category"
          </ErrorDialog>}
        </div>
      </form>
    </div>
  );
};

export default AddReviewer;
