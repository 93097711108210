import React, { useState, useContext } from "react";
import Dropdown from "../../components/Forms/Dropdown";
import Inputfield from "../../components/Forms/Inputfield";
import { PopupContext } from "../../components/PopupSystem/Popup";
import {
  validateEmail,
  validateNumber,
} from "../../components/Forms/validations";
import axios from "axios";
import { createSearchParams, useNavigate } from "react-router-dom";
import { BiError, BiCheck } from "react-icons/bi";
import API from "../../components/Api/Axios";
import {
  AUTH_REGISTER,
  SUBMISSION_FIND_AUTHOR,
} from "../../components/Api/Endpoints";
import PhonenumberInput from "../../components/Forms/PhonenumberInput";

const AddCoAuthor = ({
  authorsState,
  onAdd,
  errorState,
  currentAuthorState = [{}, () => { }],
}) => {
  const [authors, setAuthors] = authorsState;
  const [error, setError] = errorState;
  const [currentAuthor, setCurrentAuthor] = currentAuthorState;

  const [firstName, setFirstName] = useState("");
  const [firstNameError, setFirstNameError] = useState("");

  const [lastName, setLastName] = useState("");
  const [lastNameError, setLastNameError] = useState("");

  const [title, setTitle] = useState("Mr.");
  const [titleError, setTitleError] = useState("");

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState("");

  const [orgName, setOrgName] = useState("");
  const [orgNameError, setOrgNameError] = useState("");

  const [orgCity, setOrgCity] = useState("");
  const [orgCityError, setOrgCityError] = useState("");

  const [profession, setProfession] = useState("Student");
  const [professionError, setProfessionError] = useState("");

  const [designation, setDesignation] = useState("");
  const [designationError, setDesignationError] = useState("");

  const [department, setDepartment] = useState("");
  const [departmentError, setDepartmentError] = useState("");

  const handleSaveAndProceed = (e) => {
    e.preventDefault();

    const formatTitle = (title) => {
      return title.toUpperCase().slice(0, title.length - 1);
    };

    const formatProfession = (profession) => {
      return profession.toUpperCase();
    };

    const isValid = () =>
      title.length > 0 &&
      firstName.length > 0 &&
      lastName.length > 0 &&
      email.length > 0 &&
      validateEmail(email) &&
      phone.length > 0 &&
      // validateNumber(phone) &&
      orgName.length > 0 &&
      orgCity.length > 0 &&
      profession.length > 0 &&
      designation.length > 0 &&
      department.length > 0;

    if (title.length <= 0) setTitleError("Select Designation");
    if (firstName.length <= 0) setFirstNameError("Enter field");
    if (lastName.length <= 0) setLastNameError("Enter field");
    if (email.length <= 0) setEmailError("Enter field");
    if (!validateEmail(email)) setEmailError("Invalid Email address");
    if (phone.length <= 0) setPhoneError("Enter field");
    // if (!validateNumber(phone)) setPhoneError("Invalid phone number");
    if (orgName.length <= 0) setOrgNameError("Enter field");
    if (orgCity.length <= 0) setOrgCityError("Enter field");
    if (profession.length <= 0) setProfession("Choose profession");
    if (designation.length <= 0) setDesignationError("Enter field");
    if (department.length <= 0) setDepartmentError("Enter field");
    if (!isValid()) {
      return;
    } else {

      onAdd();

      API.post(SUBMISSION_FIND_AUTHOR, { email: email })
        .then((res) => {
          const data = res.data.data;
          if (data.email === currentAuthor.email) {
            setError("Author already exists");
            return;
          }

          let isOkay = true;
          authors.forEach((author) => {
            if (author.email === data.email) {
              // setIsAuthorExists(true);
              setError("Author already exists");
              isOkay = false;
              return;
            }
          });

          if (isOkay) {
            setAuthors((authors) => [...authors, data]);
          }

        })
        .catch((err) => {
          let newAuthor = {
            email: email,
            title: formatTitle(title),
            name: `${firstName} ${lastName}`,
            mobileNumber: phone,
            collegeOrganisationName: orgName,
            collegeOrganisationCity: orgCity,
            profession: formatProfession(profession),
            programmeOrDesignation: designation,
            deptOrTeam: department,
          };

          if (newAuthor.email === currentAuthor.email) {
            setError("Author already exists");
            // onAdd();
            return;
          }

          let isOkay = true;
          authors.forEach((author) => {
            if (author.email === newAuthor.email) {
              // setIsAuthorExists(true);
              setError("Author already exists");
              isOkay = false;
              return;
            }
          });

          if (isOkay) {
            setAuthors((authors) => [...authors, newAuthor]);
          }
          // onAdd();
        });
    }
  };

  return (
    <div className="">
      <h2 className="text-dark-gray text-lg">Account details</h2>
      <form className="flex flex-col space-y-4 items-center lg:items-start w-full py-6">
        <div className="flex lg:items-start space-y-4 lg:space-y-0 lg:space-x-4 flex-col lg:flex-row w-full">
          <Dropdown
            errorState={[titleError, setTitleError]}
            valueState={[title, setTitle]}
            placeholder=""
            title="Title"
            className="lg:w-2/12 w-full"
            options={["Mr.", "Dr.", "Ms.", "Prof."]}
          />
          <Inputfield
            errorState={[firstNameError, setFirstNameError]}
            valueState={[firstName, setFirstName]}
            placeholder="John"
            type="text"
            title="First Name"
            className="lg:w-5/12 w-full"
          />
          <Inputfield
            errorState={[lastNameError, setLastNameError]}
            valueState={[lastName, setLastName]}
            placeholder="Doe"
            type="text"
            title="Last Name"
            className="lg:w-5/12 w-full"
          />
        </div>
        <div className="flex lg:items-start space-y-4 lg:space-y-0 lg:space-x-4 flex-col lg:flex-row w-full">
          <Inputfield
            errorState={[emailError, setEmailError]}
            valueState={[email, setEmail]}
            placeholder="johndoe@xmail.com"
            type="email"
            title="Email ID"
            className="lg:w-6/12 w-full"
          />
          <PhonenumberInput
            errorState={[phoneError, setPhoneError]}
            valueState={[phone, setPhone]}
            placeholder="+911234567890"
            className="lg:w-1/2 w-full"
            title="Mobile Number"
          />
        </div>
        <div className="flex lg:items-start space-y-4 lg:space-y-0 lg:space-x-4 flex-col lg:flex-row w-full">
          <Inputfield
            errorState={[orgNameError, setOrgNameError]}
            valueState={[orgName, setOrgName]}
            placeholder=""
            type="text"
            title="College/Organization Name"
            className="lg:w-6/12 w-full"
          />
          <Inputfield
            errorState={[orgCityError, setOrgCityError]}
            valueState={[orgCity, setOrgCity]}
            placeholder=""
            type="text"
            title="College/Organization City"
            className="lg:w-6/12 w-full"
          />
        </div>
        <div className="flex lg:items-start space-y-4 lg:space-y-0 lg:space-x-4 flex-col lg:flex-row w-full">
          <Dropdown
            errorState={[professionError, setProfessionError]}
            valueState={[profession, setProfession]}
            placeholder=""
            title="Profession"
            className="lg:w-3/12 w-full"
            options={["Student", "Faculty", "Industry"]}
          />
          <Inputfield
            errorState={[designationError, setDesignationError]}
            valueState={[designation, setDesignation]}
            placeholder=""
            type="text"
            title="Programmee/Designation"
            className="lg:w-5/12 w-full"
          />
          <Inputfield
            errorState={[departmentError, setDepartmentError]}
            valueState={[department, setDepartment]}
            placeholder=""
            type="text"
            title="Department/Team"
            className="lg:w-4/12 w-full"
          />
        </div>
        <div className="flex flex-col lg:flex-row lg:space-x-4 items-center ">
          <button
            onClick={(e) => handleSaveAndProceed(e)}
            className="my-4 px-16 lg:px-8 py-2 text-lg bg-violet text-white font-semibold w-fit rounded-lg shadow-lg"
          >
            Add
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddCoAuthor;
