export const initialPopupWindowState = {
  isVisible: false,
  title: "",
  element: <div></div>,
  handleClose: function (e) {
    // console.log("Cancel");
  },
};

export const popupWindowReducer = (state, action) => {
  switch (action.type) {
    case "SHOW":
      return {
        ...state,
        isVisible: true,
        title: action.payload.title,
        element: action.payload.element,
        handleClose: action.payload.handleClose,
      };
    case "HIDE":
      return {
        ...state,
        isVisible: false,
        ...initialPopupWindowState,
      };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};
