import React from "react";
import PSGLogo from "../assets/psg_logo.png";

const Header = ({ className = "" }) => {
  return (
    <header
      className={`font-nunito px-36 py-4 hidden lg:flex space-x-6 items-center justify-center w-full ${className} h-24 bg-gradient-to-r from-violet to-[#B8E4F0]`}
    >
      <img
        src={PSGLogo}
        alt="PSG College of Technology"
        className="h-full w-auto invert filter"
      />
      <div className="">
        <h1 className="text-2xl font-bold text-white ">
          PSG College of Technology
        </h1>
        <h2 className="text-white text-lg">Manuscript Submission and Review Portal</h2>
      </div>
      <div className="flex-1"></div>
    </header>
  );
};

export default Header;
