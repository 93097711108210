export const initialPopupState = {
  isVisible: false,
  title: "",
  body: "",
  theme: "",
  icon: <div />,
  successText: "",
  cancelText: "",
  noCancel : false,
  handleSuccess: function (e) {
    // console.log("Success");
  },
  handleCancel: function (e) {
    // console.log("Cancel");
  },
};

export const popupReducer = (state, action) => {
  switch (action.type) {
    case "SHOW":
      return {
        ...state,
        isVisible: true,
        title: action.payload.title,
        body: action.payload.body,
        successText: action.payload.successText,
        cancelText: action.payload.cancelText,
        handleSuccess: action.payload.handleSuccess,
        handleCancel: action.payload.handleCancel,
        noCancel : action.payload.noCancel,
        theme: action.payload.theme,
        icon: action.payload.icon,
      };
    case "HIDE":
      return {
        ...state,
        ...initialPopupState,
      };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};
