import React, { useEffect, useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { HiDownload } from "react-icons/hi";
import TextOutput from "../../components/Forms/TextOutput";
import { IoMdClose } from "react-icons/io";
import { BsCheck2 } from "react-icons/bs";
import { HiOutlinePencil } from "react-icons/hi";
import { PopupContext } from "../../components/PopupSystem/Popup";
import { CHAIR_SUBMISSION_DETAILS, SUBMISSION_FILE_DOWNLOAD } from '../../components/Api/Endpoints';
import API from "../../components/Api/Axios";

const PaperDetails = () => {

  const { paperID } = useParams();
  const [, dispatch] = useContext(PopupContext);
  const navigate = useNavigate();

  const [paperDetails, setPaperDetails] = useState({
    id: "",
    status: "",
    type: "",
    title: "",
    abstract: "",
    fileUpload: null,
    keywords: [],
    author: {},
    authors: [],
    coverLetter: null,
    numberOfFigures: "",
    numberOfTables: "",
    numberOfWords: "",
    category: "",
    subCategory: "",
  });

  const getColorFromStatus = (status) => {
    const colorCodes = [
      {
        value: "APPROVED",
        text: "Accepted",
        color: "green",
      },
      {
        value: "APPROVED_PAID",
        text: "Accepted & Paid",
        color: "green",
      },
      {
        value: "FINAL_SUBMITTED",
        text: "Final Submitted",
        color: "teal",
      },
      {
        value: "REJECTED",
        text: "Rejected",
        color: "red",
      },
      {
        value: "REVISE",
        text: "Sent to Revision",
        color: "yellow",
      },
      {
        value: "UNDER_REVIEW",
        text: "Under Review",
        color: "aqua",
      },
      {
        value: "UNREAD",
        text: "Unread",
        color: "violet",
      },
    ];

    let a = colorCodes.find((color) => color.value === paperDetails.status);
    return a ? a.color : "";
  };

  const [comments, setComments] = useState([]);

  useEffect(() => {

    API.get(CHAIR_SUBMISSION_DETAILS.replace(':id', paperID))
      .then((res) => {
        const data = res.data.data;
        setPaperDetails(data);
        setComments(data.comments ? data.comments : []);
        // console.log(res);
      })
      .catch((err) => {
        navigate('/submissions');
      });

  }, []);

  // useEffect(() => {
  //   setPaperDetails({
  //     id: paperID,
  //     status: "UNREAD",
  //     type: "Book Review",
  //     title: "Visual Analytics for Human-Centered Machine Learning",
  //     abstract: `We introduce a new research area in visual analytics (VA) aiming to bridge existing gaps between methods of interactive machine learning (ML) and eXplainable Artificial Intelligence (XAI), on one side, and human minds, on the other side. The gaps are, first, a conceptual mismatch between ML/XAI outputs and human mental models and ways of reasoning, and second, a mismatch between the information quantity and level of detail and human capabilities to perceive and understand. A grand challenge is to adapt ML and XAI to human goals, concepts, values, and ways of thinking. Complementing the current efforts in XAI towards solving this challenge, VA can contribute by exploiting the potential of visualization as an effective way of communicating information to humans and a strong trigger of human abstractive perception and thinking. We propose a cross-disciplinary research framework and formulate research directions for VA.`,
  //     fileUpload: null,
  //     keywords: ["amet", "enim", "quis", "ea", "sint", "laboris", "quis"],
  //     authors: [
  //       {
  //         name: "Francis Sharp",
  //         email: "francissharp@endipin.com",
  //         collegeOrganisationName: "BILLMED",
  //       },
  //       {
  //         name: "Frances Dunlap",
  //         email: "francesdunlap@billmed.com",
  //         collegeOrganisationName: "SNIPS",
  //       },
  //       {
  //         name: "Helene Holder",
  //         email: "heleneholder@snips.com",
  //         collegeOrganisationName: "ZENTRY",
  //       },
  //       {
  //         name: "Florine Delaney",
  //         email: "florinedelaney@zentry.com",
  //         collegeOrganisationName: "STREZZO",
  //       },
  //     ],
  //     coverLetter:
  //       "Quis pariatur tempor magna duis in ipsum velit duis incididunt commodo. Velit aliquip Lorem id exercitation id laboris enim laboris. Laboris quis fugiat duis minim excepteur ipsum. Culpa esse sint enim amet nulla anim ut sit dolor cupidatat exercitation irure sit. Ad ullamco id commodo ullamco ex excepteur et veniam aliqua. Sint Lorem adipisicing consequat deserunt excepteur cupidatat reprehenderit sint quis.",
  //     numberOfFigures: "10",
  //     numberOfTables: "20",
  //     numberOfWords: "300",
  //     author: {
  //       name: "Gretchen Bruce",
  //       email: "gretchenbruce@strezzo.com",
  //       collegeOrganisationName: "PORTICO",
  //     },
  //     conflictOfInterest: false,
  //     conflicts: "",
  //   });
  // }, []);

  const handleDownload = (e, file) => {

    // window.href = SUBMISSION_FILE_DOWNLOAD.replace(':id', paperID);
    e.preventDefault();

    // API.get(
    //   `/file/download-new/${paperID}`, {
    //   responseType: 'blob',
    // }
    // )
    //   .then(response => {
    //     console.log(response.headers); // does not include content-disposition
    //     console.log("File downloading successfully!");
    //   })
    //   .catch((error) => {
    //     console.error("File could not be downloaded:", error);
    //   });

    API.get(
      SUBMISSION_FILE_DOWNLOAD.replace(':id', file.id),
      // `/file/download-new/${paperID}`,
      {
        responseType: 'blob'
      }
    ).then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', file.fileName); //or any other extension
      document.body.appendChild(link);
      link.click();
    })

  };

  return (
    <section className="pt-20 lg:pt-6 lg:px-[calc(100vw/12)] pb-6 font-nunito flex flex-col  lg:flex-row h-full">
      <nav className="w-full lg:w-1/4 px-6 py-4 lg:py-8 lg:flex flex-col space-y-3">
        <button
          onClick={(e) => {
            handleDownload(e, paperDetails.fileUpload);
          }}
          className="border-red border-2 rounded-lg bg-light-red text-red px-4 w-full py-2 flex items-center justify-center space-x-2"
        >
          <HiDownload className="" />
          <p className="">Download Paper</p>
        </button>
        <button
          onClick={(e) => {
            handleDownload(e, paperDetails.coverLetter);
          }}
          className="border-violet border-2 rounded-lg bg-light-violet text-violet px-4 w-full py-2 flex items-center justify-center space-x-2"
        >
          <HiDownload className="" />
          <p className="">Download Turnitin Plagiarism Report with % of Similarity</p>
        </button>
        <div className="flex items-start space-x-2">
          <p className="text-dark-gray w-[10ch] ">Type</p>
          <div className="flex-1 ">
            <p className="font-bold">{paperDetails.type}</p>
          </div>
        </div>
        <div className="flex items-start space-x-2">
          <p className="text-dark-gray w-[10ch] ">Category</p>
          <div className="flex-1  ">
            <p className="font-bold">{paperDetails.category}</p>
          </div>
        </div>
        <div className="flex items-start space-x-2">
          <p className="text-dark-gray w-[10ch] ">Subcategory</p>
          <div className="flex-1  ">
            <p className="font-bold">{paperDetails.subCategory}</p>
          </div>
        </div>
        <div className="flex items-start space-x-2">
          <p className="text-dark-gray w-[10ch] ">Posted By</p>
          <div className="flex-1 ">
            <p className="font-bold">{paperDetails.author.name}</p>
            <p className="text-xs font-normal">{paperDetails.author.email}</p>
          </div>
        </div>
        <div className="flex items-center space-x-2">
          <p className="text-dark-gray w-[10ch] ">Status</p>
          <div className="">
            <p
              className={`py-1 px-2 text-xs rounded-xl bg-light-${getColorFromStatus()} text-${getColorFromStatus()}`}
            >
              {paperDetails.status}
            </p>
          </div>
        </div>
        <div className="flex items-start space-x-2">
          <p className="text-dark-gray w-[10ch] ">Authors</p>
          <div className="flex-1 ">
            {paperDetails.authors.map((author) => (
              <React.Fragment>
                {" "}
                <p className="font-bold">{author.name}</p>
                <p className="text-xs font-normal">{author.email}</p>
              </React.Fragment>
            ))}
          </div>
        </div>
      </nav>
      <article className="w-full lg:w-3/4 px-6 py-4 lg:py-8 h-full space-y-4">
        <div className="flex-1 ">
          <h2 className="text-dark-gray">Paper ID: {paperDetails.id}</h2>
          <h1 className="font-bold text-3xl text-slate">
            {paperDetails.title}
          </h1>
        </div>
        <TextOutput
          text={paperDetails.abstract}
          subtext="Abstract"
          className="w-full"
        />
        <TextOutput
          subtext="Keywords"
          text={
            <div className="flex items-center flex-wrap gap-x-2 gap-y-2">
              {paperDetails.keywords.map((keyword, index) => (
                <div
                  className="h-fit py-1 px-3 rounded-full bg-cloud text-sm"
                  key={index}
                >
                  {keyword}
                </div>
              ))}
            </div>
          }
          className="w-full lg:w-2/3"
        />
        <div className="grid grid-cols-2 lg:grid-cols-3 gap-4 w-full">
          <TextOutput
            text={paperDetails.numberOfFigures}
            subtext="Number of Figures"
            className="w-full"
          />
          <TextOutput
            text={paperDetails.numberOfTables}
            subtext="Number of Tables"
            className="w-full"
          />
          <TextOutput
            text={paperDetails.numberOfWords}
            subtext="Number of Words"
            className="w-full"
          />
        </div>
        {comments.length > 0 && <h2 className="text-lg text-dark-gray">Reviewers' Comments</h2>}
        <div className="space-y-4">
          {comments.map((cmnt, idx) => (
            <TextOutput className={'whitespace-pre-line'} text={cmnt} subtext={`Reviewer #${idx + 1}`} />
          ))}
        </div>
        {/* <TextOutput text={paperDetails.coverLetter} subtext="Cover Letter" /> */}
      </article>
    </section>
  );
};

export default PaperDetails;
