import React, { useEffect, useState } from 'react'
import SquareTile from '../../components/Forms/SquareTile'
import API from '../../components/Api/Axios';
import { SUBMISSION_GET_SUBMISSIONS } from '../../components/Api/Endpoints';
import { NilCard } from '../Admin/SubmissionList';

const Submissions = () => {

  const [submissions, setSubmissions] = useState([]);
  const [coAuthoredSubmissions, setCoAuthoredSubmissions] = useState([]);

  // const formatStatus = (status) => {

  //   if (status === "REVISE") {
  //     return "revise"
  //   } else if (status === "APPROVED") {
  //     return "approved"
  //   } else if (status === "REJECTED") {
  //     return "rejected"
  //   } else {
  //     return "submitted"
  //   }

  // }

  useEffect(() => {

    API.get(SUBMISSION_GET_SUBMISSIONS)
      .then((res) => {
        const data = res.data.data;
        // console.log(data);
        setSubmissions(data.submissions);
        setCoAuthoredSubmissions(data.coAuthoredSubmissions);
      })
      .catch((err) => {
        // console.log(err);
        setSubmissions([]);
        setCoAuthoredSubmissions([]);
      })

  }, []);

  return (
    <React.Fragment>
      <h1 className='font-bold text-3xl text-slate mb-4'>Submissions</h1>
      <div className="grid grid-cols-1 lg:grid-cols-4 gap-x-4 gap-y-4 mb-5">
        {(submissions && submissions.length > 0) && submissions.map((submission, index) => (
          <SquareTile key={index}
            title={submission.title}
            status={submission.status}
            link={`/submission-details/${submission.id}`}
          />
        ))}
      </div>
      {(!(submissions && submissions.length > 0)) &&
        <div className="flex flex-col space-y-2 py-4">
          <NilCard text="No submissions, yet :(" />
        </div>
      }
      <h1 className='font-bold text-3xl text-slate mb-4 mt-8'>Co Authored Submissions</h1>
      <div className="grid grid-cols-1 lg:grid-cols-4 gap-x-4 gap-y-4">
        {(coAuthoredSubmissions && coAuthoredSubmissions.length > 0) && coAuthoredSubmissions.map((submission, index) => (
          <SquareTile key={index}
            title={submission.title}
            status={submission.status}
            link={`/submission-details/${submission.id}`}
          />
        ))}
      </div>
      {(!(coAuthoredSubmissions && coAuthoredSubmissions.length > 0)) &&
        <div className="flex flex-col space-y-2 py-4">
          <NilCard text="No co-authored submissions, yet :(" />
        </div>
      }
    </React.Fragment>
  );
}

export default Submissions