import React from "react"
import { useNavigate } from "react-router-dom";
import API from "../../components/Api/Axios";
import { SUBMISSION_TEMPLATE_DOWNLOAD, SUBMISSION_TEMPLATE_PDF_DOWNLOAD } from "../../components/Api/Endpoints";
import TextOutput from "../../components/Forms/TextOutput";

const TemplateInstructions = () => {

    const navigate = useNavigate();

    const handleDownload = (e, isDocx) => {

        e.preventDefault();

        if (isDocx) {
            // href={SUBMISSION_FILE_DOWNLOAD.replace(':id', file.id)}
            API.get(
                SUBMISSION_TEMPLATE_DOWNLOAD,
                {
                    responseType: 'blob'
                }
            ).then((res) => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'RC_template.docx');
                document.body.appendChild(link);
                link.click();
            })
        } else {
            API.get(
                SUBMISSION_TEMPLATE_PDF_DOWNLOAD,
                {
                    responseType: 'blob'
                }
            ).then((res) => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'RC_template.pdf');
                document.body.appendChild(link);
                link.click();
            })
        }

    }

    return <div className="w-full">
        <h1 className="text-3xl font-bold text-slate mb-4">Template Instructions</h1>
        <p className="text-dark-gray">Download the given below .docx file for template required to send to the conference.</p>
        {/* <div className="flex flex-col lg:flex-row lg:space-x-4 items-center "> */}
        <button onClick={(e) => handleDownload(e, true)} className="my-4 px-16 lg:px-8 py-2 text-lg border-2 border-violet text-violet bg-white font-semibold w-fit rounded-lg shadow-lg">
            Download Template (.docx)
        </button>
        <button onClick={(e) => handleDownload(e, false)} className="ml-4 my-4 px-16 lg:px-8 py-2 text-lg border-2 border-violet text-violet bg-white font-semibold w-fit rounded-lg shadow-lg">
            Download Template (.pdf)
        </button>
        {/* </div> */}
        <h1 className="text-3xl font-bold text-slate my-4">Guidelines to Authors</h1>
        <p className="text-dark-gray mb-4">The authors are invited to submit their research work with the following guidelines:</p>
        <div className="flex space-y-4 flex-col w-full pl-4 mb-4">
            <p className="relative before:absolute before:content-[''] before:h-[6px] before:w-[6px] before:bg-violet before:top-[calc(50%-3px)] before:left-0 pl-4">The papers are to be submitted in IEEE Format.</p>
            <p className="relative before:absolute before:content-[''] before:h-[6px] before:w-[6px] before:bg-violet before:top-[calc(50%-3px)] before:left-0 pl-4">Number of pages is restricted to 6 to 8</p>
            <p className="relative before:absolute before:content-[''] before:h-[6px] before:w-[6px] before:bg-violet before:top-[calc(50%-3px)] before:left-0 pl-4">Authors are limited to FOUR, including corresponding author</p>
            <p className="relative before:absolute before:content-[''] before:h-[6px] before:w-[6px] before:bg-violet before:top-[calc(50%-3px)] before:left-0 pl-4">The organizing committee will be using the plagiarism software screening system to verify the originality (less than 15% similarity for research papers and 20% similarity for review article is acceptable) of the papers.</p>
            <p className="relative before:absolute before:content-[''] before:h-[6px] before:w-[6px] before:bg-violet before:top-[calc(50%-3px)] before:left-0 pl-4">The authors have to ensure the clarity of figures and tables in their papers.</p>
            <p className="relative before:absolute before:content-[''] before:h-[6px] before:w-[6px] before:bg-violet before:top-[calc(50%-3px)] before:left-0 pl-4">Authors are responsible for removing any information from their manuscripts that might lead a reviewer to discern their identities or affiliations. (Blinded Manuscript)</p>
        </div>
        <button onClick={e => navigate("/new-submission/type-title-abstract")} className="my-4 px-16 lg:px-8 py-2 text-lg border-2 border-violet bg-violet text-white font-semibold w-fit rounded-lg shadow-lg">
            Proceed to Submission
        </button>
    </div>
}

export default TemplateInstructions;