const subCategoriesData = [
    "Industrial Automation",
    "Advances in Manufacturing Engineering",
    "Metal and Mineral Processing",
    "Internet of Things (IoT) and Embedded Systems",
    "Wearable and Smart Textiles",
    "Cyber-Physical Systems",
    "Internet of Things (IoT)",
    "Cyber-Physical Systems",
    "Sustainable Smart Technologies",
    "Green Energy",
    "Electric Mobility",
    "Green Computing",
    "Sustainable and Technical Textiles",
    "Sustainable Civil Engineering Structures and Construction Materials ",
    "Earthquake and Sustainable Disaster Management",
    "Vibration and Acoustics",
    "Engineering Materials and Processing",
    "Design for Sustainability",
    "Artificial Intelligence and Data Science",
    "Blockchain and Cyber Security",
    "Wireless Communications and Networking",
    "Signal and Image Processing",
    "Robotics and Control Systems",
    "Power Electronics and Drives",
    "Micro and Nano Electromechanical Systems",
    "Digital Controllers and Real-Time Systems",
    "System on Chip",
    "Medical, Pharmaceutical and food Technology",
    "Bioinformatics",
    "Biomedical Instrumentation",
    "Bio-Materials /Biosynthesized Nanomaterials",
    "Theoretical Physics",
    "Electrochemistry",
    "Organic Synthesis",
    "Mathematical Modelling and Applications",
    "Literature and Human Values",
    "Linguistics, ELT and Contemporary English",
    "Economics and Management"
];

export default subCategoriesData;
