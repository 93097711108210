import axios from 'axios';
import { useContext } from 'react';
import { AuthContext, AUTH_LOCAL_STORAGE } from '../Auth/AuthContext';
import { AUTH_REFRESH_TOKEN, BASE_URL } from './Endpoints';
import UserManager from './UserManager';

export const API = axios.create({
    baseURL: BASE_URL,
    timeout: 5000,
    headers: {
        'Content-Type': 'application/json',
        'accept': 'application/json',
        'Access-Control-Allow-Origin': '*',
    }
});

API.interceptors.request.use(
    (config) => {
        const tokens = localStorage.getItem(AUTH_LOCAL_STORAGE);
        if (tokens) {
            const token = JSON.parse(tokens).accessToken;
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
)

API.interceptors.response.use(
    response => response,
    async error => {

        // try {

        const originalRequest = error.config;

        if (error.response.status === 401 && originalRequest.url === AUTH_REFRESH_TOKEN) {
            localStorage.removeItem(AUTH_LOCAL_STORAGE);
            // window.location.href = '/login';
            return Promise.reject(error);
        }

        if (error.response.status === 401 && !originalRequest._retry) {

            originalRequest._retry = true;

            const tokens = localStorage.getItem(AUTH_LOCAL_STORAGE);

            if (!tokens) {
                localStorage.removeItem(AUTH_LOCAL_STORAGE);
                // window.location.href = '/login';
                return Promise.reject(error);
            }

            const parsedTokens = JSON.parse(tokens);

            const res = await API.post(AUTH_REFRESH_TOKEN, {
                refreshToken: parsedTokens.refreshToken
            });

            const data = res.data.data;
            localStorage.setItem(AUTH_LOCAL_STORAGE, JSON.stringify(data));
            API.defaults.headers.common['Authorization'] = `Bearer ${data.accessToken}`;
            return await API(originalRequest);

        }

        return Promise.reject(error);

        // } catch (e) {
        //     return Promise.reject(error);
        // }

    }
)

export default API;