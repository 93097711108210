import React, {useContext, useState} from 'react'

const Payment = () => {

  

  return (
    <div className="">
      <h2 className="text-dark-gray text-lg">Step 6</h2>
      <h1 className="font-bold text-3xl">Payment</h1>
      <form className="flex flex-col space-y-4 items-center lg:items-start w-full py-6">
        <div className="flex space-y-4 lg:space-y-0 lg:space-x-4 flex-col lg:flex-row w-full"></div>
        
        <div className="flex flex-col lg:flex-row lg:space-x-4 items-center ">
          <button className="my-4 px-16 lg:px-8 py-2 text-lg border-2 border-violet text-violet bg-white font-semibold w-fit rounded-lg shadow-lg">
            Discard Information
          </button>
          <button className="my-4 px-16 lg:px-8 py-2 text-lg border-2 border-violet bg-violet text-white font-semibold w-fit rounded-lg shadow-lg">
            Save and Proceed
          </button>
        </div>
      </form>
    </div>
  );
}

export default Payment