import React, { useContext, useState } from "react";
import Inputfield from "../../components/Forms/Inputfield";
import { AiOutlineCheckCircle, AiFillCheckCircle } from "react-icons/ai";
import API from "../../components/Api/Axios";
import { AUTH_FORGOT_PASSWORD } from "../../components/Api/Endpoints";
import { PopupContext } from "../../components/PopupSystem/Popup";
import { BiCheck } from "react-icons/bi";
import { useNavigate } from "react-router-dom";

const EnterEmail = () => {

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const [, dispatch] = useContext(PopupContext);

  const navigate = useNavigate();

  const forgotPassword = (e) => {

    e.preventDefault();

    API.post(AUTH_FORGOT_PASSWORD, {
      email: email
    }).then((res) => {

      dispatch({
        type: 'SHOW',
        payload: {
          title: "Success",
          message: "An email has been sent to your account with instructions to reset your password.",
          theme: "green",
          icon: <BiCheck />,
          successText: "Ok",
          cancelText: "",
          noCancel: true,
          handleSuccess: function (e) {
            navigate("/login");
            dispatch({
              type: "HIDE",
            });
          },
          handleCancel: function (e) { },
        }
      });

    }).catch((err) => {
      // console.log(err);
    })

  }

  return (
    <div className="">
      <h1 className="font-bold text-3xl">Forgot your password ?</h1>
      <h2 className="text-dark-gray text-lg">Enter your email address</h2>

      <p className="my-2">
        A link will be sent to your email address. Please click on the link to
        reset your password.
      </p>

      <form className="flex flex-col space-y-4 items-center lg:items-start w-full py-6">
        <div className="flex w-full flex-col lg:flex-row space-y-4 lg:space-y-0 lg:space-x-4">
          <div className="flex space-y-4 flex-col w-full lg:w-1/2">
            <Inputfield
              errorState={[emailError, setEmailError]}
              valueState={[email, setEmail]}
              placeholder="youremail@example.com"
              type="email"
              title="Enter your email address"
              className="w-full"
            />
          </div>
        </div>
        <button onClick={forgotPassword} className="my-4 px-16 lg:px-8 py-2 text-lg bg-violet text-white font-semibold w-fit rounded-lg shadow-lg">
          Submit
        </button>
      </form>
    </div>
  );
};

export default EnterEmail;
