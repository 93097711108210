import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import DialogCard from "../../components/Forms/DialogCard";
import { RiShareBoxLine } from "react-icons/ri";
import { BsFillPatchCheckFill, BsPencil } from "react-icons/bs";
import dummyReviewsData from "../../data/dummyReviewsData";
import { PopupWindowContext } from "../../components/PopupWindow/PopupWindow";
import CommentWindow from "./CommentWindow";
import API from "../../components/Api/Axios";
import { REVIEW_GET_REVIEWS } from "../../components/Api/Endpoints";
/**
 ** Status List:
 * PENDING: Reviewer has not yet reviewed the paper
 * REVIEWED: Reviewer has reviewed and commented the paper
 */

const ReviewerDashboard = () => {
  const [filterValue, setFilterValue] = useState("EVERYTHING");

  const [reviews, setReviews] = useState([]);
  const [filteredReviews, setFilteredReviews] = useState([]);

  // useEffect(() => {
  //   setReviews(dummyReviewsData);
  // }, []);

  useEffect(() => {
    API.get(REVIEW_GET_REVIEWS)
      .then((res) => {
        // console.log(res.data);
        const data = res.data.data;
        setReviews(data);
      })
      .catch((err) => {
        // console.log(err);
      })
  }, []);

  useEffect(() => {
    setFilteredReviews(
      reviews.filter(
        (rev) => filterValue === "EVERYTHING" || rev.status === filterValue
      )
    );
  }, [reviews, filterValue]);

  return (
    <section className="pt-20 lg:pt-6 lg:px-[calc(100vw/12)] pb-6 font-nunito flex flex-col  lg:flex-row h-full">
      <nav className="w-full lg:w-1/4 px-6 py-4 lg:py-8 flex lg:items-start flex-col space-y-2">
        {" "}
        <h2 className="text-dark-gray font-bold text-lg">Filter</h2>
        <h3 className="text-dark-gray text-sm">By Status</h3>
        <div className="flex flex-col items-start space-y-1">
          <button
            onClick={() => setFilterValue("EVERYTHING")}
            className={`${filterValue === "EVERYTHING"
              ? "text-violet bg-light-violet py-0.5 rounded-full"
              : "text-gray hover:text-dark-gray"
              }  px-3 `}
          >
            Everything
          </button>
          <button
            onClick={() => setFilterValue("PENDING")}
            className={`${filterValue === "PENDING"
              ? "text-violet bg-light-violet py-0.5 rounded-full"
              : "text-gray hover:text-dark-gray"
              }  px-3 `}
          >
            Pending
          </button>
          <button
            onClick={() => setFilterValue("REVIEWED")}
            className={`${filterValue === "REVIEWED"
              ? "text-violet bg-light-violet py-0.5 rounded-full"
              : "text-gray hover:text-dark-gray"
              }  px-3 `}
          >
            Reviewed
          </button>
        </div>
      </nav>
      <article className="w-full lg:w-3/4 px-6 py-4 lg:py-8 h-full space-y-4">
        <h1 className="font-bold text-3xl text-slate">Your Reviews</h1>
        {filteredReviews.map((rev) => {
          if (rev.status === "PENDING") return <PendingCard context={rev} />;
          else if (rev.status === "REVIEWED")
            return <CompletedCard context={rev} />;
        })}
      </article>
    </section>
  );
};

export default ReviewerDashboard;

const PendingCard = ({ context }) => {

  const navigate = useNavigate();
  const [, dispatch] = useContext(PopupWindowContext);

  const handleWriteComment = (e) => {
    e.preventDefault();
    dispatch({
      type: "SHOW",
      payload: {
        title: "Write Comment",
        element: <CommentWindow paperID={context.id} navigate={navigate} />,
        handleClose: function (e) {
          e.preventDefault();
          dispatch({
            type: "HIDE",
          });
        },
      },
    });
  };

  return (
    <DialogCard theme="violet">
      <div className="flex flex-col lg:flex-row w-full lg:items-center select-none">
        <div className="flex flex-col">
          <h2 className="text-dark-gray text-sm">
            Last updated on:{" "}
            {`${context.lastUpdated.split("T")[0]}, ${context.lastUpdated.split("T")[1].split("-")[0]
              }`}
          </h2>
          <h1 className="font-bold text-xl">{context.title}</h1>
        </div>
        <div className="flex-1 my-2 lg:my-0"></div>
        <div className="flex items-center space-x-4">
          <Link to={`/reviews/details/${context.id}`}>
            <button className="text-gray hover:text-dark-gray flex items-center">
              <RiShareBoxLine size={24} />
            </button>
          </Link>
          <div className="flex items-center space-x-2">
            <button
              onClick={(e) => handleWriteComment(e)}
              className="flex space-x-2 items-center px-8 lg:px-8 py-2 border-2 border-violet bg-violet text-white font-semibold w-fit rounded-lg shadow-lg"
            >
              <BsPencil />
              <p className="">Write Comment</p>
            </button>
          </div>
        </div>
      </div>
    </DialogCard>
  );
};

const CompletedCard = ({ context }) => {
  return (
    <DialogCard theme="green">
      <div className="flex flex-col lg:flex-row w-full lg:items-center select-none">
        <div className="flex flex-col">
          <h2 className="text-dark-gray text-sm">
            Last updated on:{" "}
            {`${context.lastUpdated.split("T")[0]}, ${context.lastUpdated.split("T")[1].split("-")[0]
              }`}
          </h2>
          <h1 className="font-bold text-xl">{context.title}</h1>
        </div>
        <div className="flex-1 my-2 lg:my-0"></div>
        <div className="flex items-center space-x-4">
          <Link to={`/reviews/details/${context.id}`}>
            <button className="text-gray hover:text-dark-gray flex items-center">
              <RiShareBoxLine size={24} />
            </button>
          </Link>
          <div className="flex items-center space-x-2">
            <BsFillPatchCheckFill className="text-green" size={20} />
            <div className="font-semibold text-lg text-green">Completed !</div>
          </div>
        </div>
      </div>
    </DialogCard>
  );
};
