import React from "react";
import { AiFillExclamationCircle } from "react-icons/ai";

const Radiobox = ({
  className = "w-full",
  errorState = ["", () => { }],
  valueState = ["", () => { }],
  options = [],
  isDisabled = false,
  text = "Choose an option",
}) => {
  const [value, setValue] = valueState;
  const [error, setError] = errorState;

  return (
    <div className={`flex flex-col space-y-4 ${className}`}>
      <p className="text-dark-gray w-full">{text}</p>
      <div className="flex space-x-4 flex-wrap items-center">
        {options.map((option, index) => (
          <React.Fragment>
            <input
              disabled={isDisabled}
              type="radio"
              name="radio_box"
              value={option}
              checked={value === option}
              onChange={(e) => {
                e.preventDefault();
                setValue(option);
                setError("");
              }}
            />
            <label htmlFor={option}>{option}</label>
          </React.Fragment>
        ))}
      </div>
      {error.length !== 0 && (
        <div className="flex items-center space-x-2 text-xs text-red">
          <AiFillExclamationCircle />
          <p className="">{error}</p>
        </div>
      )}
    </div>
  );
};

export default Radiobox;
