import React, { useState, useContext } from "react";
import Dropdown from "../../components/Forms/Dropdown";
import Inputfield from "../../components/Forms/Inputfield";
import { PopupContext } from "../../components/PopupSystem/Popup";
import {
  validateEmail,
  validateNumber,
} from "../../components/Forms/validations";
import axios from "axios";
import { createSearchParams, useNavigate } from "react-router-dom";
import { BiError, BiCheck } from "react-icons/bi";
import API from "../../components/Api/Axios";
import { AUTH_REGISTER } from "../../components/Api/Endpoints";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import PhonenumberInput from "../../components/Forms/PhonenumberInput";

const AccountDetails = () => {
  const navigate = useNavigate();
  const [, dispatch] = useContext(PopupContext);

  const [reqSent, setReqSent] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [firstNameError, setFirstNameError] = useState("");

  const [lastName, setLastName] = useState("");
  const [lastNameError, setLastNameError] = useState("");

  const [title, setTitle] = useState("Mr.");
  const [titleError, setTitleError] = useState("");

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState("");

  const [orgName, setOrgName] = useState("");
  const [orgNameError, setOrgNameError] = useState("");

  const [orgCity, setOrgCity] = useState("");
  const [orgCityError, setOrgCityError] = useState("");

  const [profession, setProfession] = useState("Student");
  const [professionError, setProfessionError] = useState("");

  const [designation, setDesignation] = useState("");
  const [designationError, setDesignationError] = useState("");

  const [department, setDepartment] = useState("");
  const [departmentError, setDepartmentError] = useState("");

  const handleSaveAndProceed = (e) => {
    e.preventDefault();

    // if (reqSent) {
    //   return;
    // }

    // setReqSent(true);

    // navigate(
    //   `/register/set-password?${createSearchParams({
    //     token: "okaya,punda",
    //   })}`
    // );

    const formatTitle = (title) => {
      return title.toUpperCase().slice(0, title.length - 1);
    };

    const formatProfession = (profession) => {
      return profession.toUpperCase();
    };

    const isValid = () =>
      title.length > 0 &&
      firstName.length > 0 &&
      lastName.length > 0 &&
      email.length > 0 &&
      validateEmail(email) &&
      phone.length > 0 &&
      // validateNumber(phone) &&
      orgName.length > 0 &&
      orgCity.length > 0 &&
      profession.length > 0 &&
      designation.length > 0 &&
      department.length > 0;

    if (title.length <= 0) setTitleError("Select Designation");
    if (firstName.length <= 0) setFirstNameError("Enter field");
    if (lastName.length <= 0) setLastNameError("Enter field");
    if (email.length <= 0) setEmailError("Enter field");
    if (!validateEmail(email)) setEmailError("Invalid Email address");
    if (phone.length <= 0) setPhoneError("Enter field");
    // if (!validateNumber(phone)) setPhoneError("Invalid phone number");
    if (orgName.length <= 0) setOrgNameError("Enter field");
    if (orgCity.length <= 0) setOrgCityError("Enter field");
    if (profession.length <= 0) setProfession("Choose profession");
    if (designation.length <= 0) setDesignationError("Enter field");
    if (department.length <= 0) setDepartmentError("Enter field");
    if (!isValid()) {
      return;
    } else {
      let postBody = {
        email: email,
        title: formatTitle(title),
        name: `${firstName} ${lastName}`,
        mobileNumber: phone,
        collegeOrganisationName: orgName,
        collegeOrganisationCity: orgCity,
        profession: formatProfession(profession),
        programmeOrDesignation: designation,
        deptOrTeam: department,
      };

      // console.log(postBody);

      API.post(AUTH_REGISTER, postBody)
        .then((res) => {
          dispatch({
            type: "SHOW",
            payload: {
              title: res.data.message,
              body: "An email has been sent to you for verification",
              theme: "green",
              icon: <BiCheck />,
              successText: "Ok",
              cancelText: "",
              noCancel: true,
              handleSuccess: function (e) {
                navigate("/login");
                dispatch({
                  type: "HIDE",
                });
              },
              handleCancel: function (e) {},
            },
          });
        })
        .catch((err) => {
          // console.log(err);
        });

      // .then((res) => {
      //   setReqSent(false);
      //   res = res.json();
      //   console.log(res);
      //   dispatch({
      //     type: "SHOW",
      //     payload: {
      //       title: "User created Successfully",
      //       body: "you can now login using the credentials",
      //       theme: "red",
      //       icon: <BiCheck />,
      //       successText: "Ok",
      //       cancelText: "",
      //       handleSuccess: function (e) {
      //         navigate('/login');
      //       },
      //       handleCancel: function (e) {
      //         // dispatch({
      //         //   type: "HIDE",
      //         // });
      //       },
      //     },
      //   });
      // })
      // .catch((err) => {
      //   setReqSent(false);
      //   console.log(JSON.stringify(err.message));
      //   dispatch({
      //     type: "SHOW",
      //     payload: {
      //       title: err.message,
      //       body: "Try again after some time",
      //       theme: "red",
      //       icon: <BiError />,
      //       successText: "Ok",
      //       cancelText: "Cancel",
      //       handleSuccess: function (e) {
      //         dispatch({
      //           type: "HIDE",
      //         });
      //       },
      //       handleCancel: function (e) {
      //         dispatch({
      //           type: "HIDE",
      //         });
      //       },
      //     },
      //   });
      // });
    }
  };

  return (
    <div className="">
      <h1 className="font-bold text-3xl">Create new account</h1>
      <h2 className="text-dark-gray text-lg">Account details</h2>
      <form className="flex flex-col space-y-4 items-center lg:items-start w-full py-6">
        <div className="flex lg:items-start space-y-4 lg:space-y-0 lg:space-x-4 flex-col lg:flex-row w-full">
          <Dropdown
            errorState={[titleError, setTitleError]}
            valueState={[title, setTitle]}
            placeholder=""
            title="Title"
            className="lg:w-2/12 w-full"
            options={["Mr.", "Dr.", "Ms.", "Prof."]}
          />
          <Inputfield
            errorState={[firstNameError, setFirstNameError]}
            valueState={[firstName, setFirstName]}
            placeholder="John"
            type="text"
            title="First Name"
            className="lg:w-5/12 w-full"
          />
          <Inputfield
            errorState={[lastNameError, setLastNameError]}
            valueState={[lastName, setLastName]}
            placeholder="Doe"
            type="text"
            title="Last Name"
            className="lg:w-5/12 w-full"
          />
        </div>
        <div className="flex lg:items-start space-y-4 lg:space-y-0 lg:space-x-4 flex-col lg:flex-row w-full">
          <Inputfield
            errorState={[emailError, setEmailError]}
            valueState={[email, setEmail]}
            placeholder="johndoe@xmail.com"
            type="email"
            title="Email ID"
            className="lg:w-1/2 w-full"
          />
          {/* <Inputfield
            errorState={[phoneError, setPhoneError]}
            valueState={[phone, setPhone]}
            placeholder="+911234567890"
            type="text"
            title="Mobile Number (with Country Code)"
            className="lg:w-6/12 w-full"
          /> */}
          <PhonenumberInput
            errorState={[phoneError, setPhoneError]}
            valueState={[phone, setPhone]}
            placeholder="+911234567890"
            className="lg:w-1/2 w-full"
            title="Mobile Number"
          />
        </div>
        <div className="flex lg:items-start space-y-4 lg:space-y-0 lg:space-x-4 flex-col lg:flex-row w-full">
          <Inputfield
            errorState={[orgNameError, setOrgNameError]}
            valueState={[orgName, setOrgName]}
            placeholder=""
            type="text"
            title="College/Organization Name"
            className="lg:w-6/12 w-full"
          />
          <Inputfield
            errorState={[orgCityError, setOrgCityError]}
            valueState={[orgCity, setOrgCity]}
            placeholder=""
            type="text"
            title="College/Organization City"
            className="lg:w-6/12 w-full"
          />
        </div>
        <div className="flex lg:items-start space-y-4 lg:space-y-0 lg:space-x-4 flex-col lg:flex-row w-full">
          <Dropdown
            errorState={[professionError, setProfessionError]}
            valueState={[profession, setProfession]}
            placeholder=""
            title="Profession"
            className="lg:w-3/12 w-full"
            options={["Student", "Faculty", "Industry"]}
          />
          <Inputfield
            errorState={[designationError, setDesignationError]}
            valueState={[designation, setDesignation]}
            placeholder=""
            type="text"
            title="Programmee/Designation"
            className="lg:w-5/12 w-full"
          />
          <Inputfield
            errorState={[departmentError, setDepartmentError]}
            valueState={[department, setDepartment]}
            placeholder=""
            // isDisabled={true}
            type="text"
            title="Department/Team"
            className="lg:w-4/12 w-full"
          />
        </div>
        <div className="flex flex-col lg:flex-row lg:space-x-4 items-center ">
          <button
            onClick={(e) => handleSaveAndProceed(e)}
            className="my-4 px-16 lg:px-8 py-2 text-lg bg-violet text-white font-semibold w-fit rounded-lg shadow-lg"
          >
            Save and Proceed
          </button>
          <p className="text-center lg:text-left">
            By submitting, you accept to{" "}
            <span className="text-aqua">Terms and Conditions </span>
            and <span className="text-aqua">Privacy Policy</span>
          </p>
        </div>
      </form>
    </div>
  );
};

export default AccountDetails;
