import React from "react";
import { IoMdWarning } from "react-icons/io";
const ErrorDialog = ({
  className = "w-full",
  icon = <IoMdWarning />,
  theme="red",
  children,
}) => {
  return (
    <div
      className={`${className} ${
        !children || children.length <= 0 ? "hidden" : "flex"
      } items-center space-x-2 p-2 rounded-lg bg-light-${theme} border-2 border-${theme}`}
    >
      {React.cloneElement(icon, {
        className: `text-${theme}`,
        size: 24,
      })}
      <p className={`text-${theme} text-xs`}>{children}</p>
    </div>
  );
};

export default ErrorDialog;
