import React from "react";
import Home from "./pages/Home/Home";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import Login from "./pages/Auth/Login";
import Register from "./pages/Auth/Register";
import AccountDetails from "./pages/Auth/AccountDetails";
import SetPassword from "./pages/Auth/SetPassword";
import ForgotPassword from "./pages/Auth/ForgotPassword";
import ChangePassword from "./pages/Auth/ChangePassword";
import EnterEmail from "./pages/Auth/EnterEmail";
import Notifications from "./pages/Home/Notifications";
import Submissions from "./pages/Home/Submissions";
import NewSubmission from "./pages/Author/NewSubmission";
import TypeTitleAbstract from "./pages/Author/TypeTitleAbstract";
import FileUpload from "./pages/Author/FileUpload";
import Attributes from "./pages/Author/Attributes";
import Authors from "./pages/Author/Authors";
import Payment from "./pages/Author/Payment";
import Summary from "./pages/Author/Summary";
import Details from "./pages/Author/Details";
import AdminSubmissions from "./pages/Admin/AdminSubmissions";
import EventSettings from "./pages/Admin/EventSettings";
import TemplateInstructions from "./pages/Author/TemplateInstructions";
import PaperDetails from "./pages/Admin/PaperDetails";
import SubmissionDetails from "./pages/Home/SubmissionDetails";
import ContactUs from "./pages/Contact/ContactUs";
import Page404 from "./pages/Page404";
import SendtoReview from "./pages/Admin/SendtoReview";
import AdminHome from "./pages/Admin/AdminHome";
import ReviewersPanel from "./pages/Reviewers/ReviewersPanel";
import ReviewerDashboard from "./pages/Reviewers/ReviewerDashboard";
import ReviewerPaperDetails from "./pages/Reviewers/ReviewerPaperDetails";
import ReviewerHome from "./pages/Reviewers/ReviewerHome";
import AddRevision from "./pages/Home/AddRevision";

const AppRoute = () => {
  return (
    <Routes>
      <Route path="/forgot-password" element={<ForgotPassword />}>
        <Route
          index
          element={<Navigate to="/forgot-password/email-address" />}
        />
        <Route path="email-address" element={<EnterEmail />} />
        <Route path="change-password/:token" element={<ChangePassword />}>
          {/* <Route path=":token" element={<ChangePassword />} /> */}
        </Route>
      </Route>
      <Route path="/register" element={<Register />}>
        <Route index element={<Navigate to="/register/account-details" />} />
        <Route path="account-details" element={<AccountDetails />} />
        <Route path="set-password" element={<SetPassword />} />
      </Route>
      <Route path="/login" element={<Login />} />
      <Route path="/new-submission" element={<NewSubmission />}>
        <Route
          index
          element={<Navigate to="/new-submission/template-instructions" />}
        />
        <Route
          path="template-instructions"
          element={<TemplateInstructions />}
        />
        <Route path="type-title-abstract" element={<TypeTitleAbstract />} />
        <Route path="file-upload" element={<FileUpload />} />
        <Route path="attributes" element={<Attributes />} />
        <Route path="authors" element={<Authors />} />
        <Route path="details" element={<Details />} />
        <Route path="payment" element={<Payment />} />
        <Route path="summary" element={<Summary />} />
      </Route>
      <Route path="/admin" element={<AdminHome />}>
        <Route index element={<Navigate to="/admin/submissions" />} />
        <Route path="submissions" element={<AdminSubmissions />} />
        <Route path="event-settings" element={<EventSettings />} />
        <Route path="paper-details/:paperID" element={<PaperDetails />} />
        <Route path="sendto-review/:paperID" element={<SendtoReview />} />
        <Route path="reviewers" element={<ReviewersPanel />} />
      </Route>
      <Route path="/contact-us" element={<ContactUs />} />
      <Route
        path="submission-details/:paperID"
        element={<SubmissionDetails />}
      />
      <Route path="revision/:paperID" element={<AddRevision />} />
      <Route path="/reviews" element={<ReviewerHome />}>
        <Route index element={<ReviewerDashboard />} />
        <Route
          path="details/:paperID"
          element={<ReviewerPaperDetails />}
        />
      </Route>
      <Route path="/" element={<Home />}>
        <Route index element={<Navigate to="/notifications" />} />
        <Route path="notifications" element={<Notifications />} />
        <Route path="submissions" element={<Submissions />} />
      </Route>
      <Route path="*" element={<Page404 />} />
    </Routes>
  );
};

export default AppRoute;
