export const reviewerQuestions = `
Novelty of the work (5 point scale) : 
Technical content, discussion and conclusion (5 point scale) : 
Organization of the paper. Relevance and clarity of figures and tables (5 point scale) : 
Standard of English (5 point scale) : 
Industrial application (5 point scale) : 
References - adequate and correctly cited (5 point scale) : 
Overall Rating for the paper (5 point scale) :
Comments to the authors, if any:

`;

// export const chairAcceptComment = `
// Dear Authors,
// Congratulations on the acceptance of your abstract.
// Please revise the Abstract/Manuscript based on the reviewer comments and submit the Abstract/Manuscript (with correct author details) as per the template available in Conference website on or before 30.12.2022.
// Conference website- https://www.psgtech.edu/rcms2024/index.html
// Try to complete the registration process by December 30, 2022, if not yet completed.
// Registration link -https://forms.gle/KbDZkdHkZ5NPFqYa7
// Thank you for submitting your research work at RCMS-2024.

// Wishing you all the best.

// `;
export const chairAcceptComment = ``;