import React, { useState, useEffect, useContext } from "react";
import API from "../../components/Api/Axios";
import { REVIEW_COMMENT_SUBMISSION, REVIEW_GET_SUBMISSION } from "../../components/Api/Endpoints";
import { PopupWindowContext } from "../../components/PopupWindow/PopupWindow";
import ErrorDialog from "../../components/Forms/ErrorDialog";
import Inputfield from "../../components/Forms/Inputfield";
import TextArea from "../../components/Forms/TextArea";
import { dummyPaperDetails } from "../../data/dummyPaperDetails";
import { reviewerQuestions } from "../../data/reviewerCommetData";
import { useNavigate } from "react-router-dom";

const CommentWindow = ({ paperID, navigate }) => {

  const [, dispatch] = useContext(PopupWindowContext);

  const [commentInput, setCommentInput] = useState(reviewerQuestions);
  const [paperDetails, setPaperDetails] = useState({});

  // const navigate = useNavigate();

  // useEffect(() => {
  //   setPaperDetails(dummyPaperDetails(paperID));
  // }, []);

  useEffect(() => {

    API.get(REVIEW_GET_SUBMISSION.replace(':id', paperID))
      .then((res) => {
        const data = res.data.data;
        setPaperDetails(data);
        if (data.comment) {
          setCommentInput(data.comment);
        }
      })
      .catch((err) => {
        // console.log(err);
      });

  }, [])

  const handleConfirmAndSubmit = e => {
    e.preventDefault();
    API.post(REVIEW_COMMENT_SUBMISSION, {
      id: paperID,
      comment: commentInput
    })
      .then((res) => {
        // window.location.reload();
        dispatch({
          type: "HIDE",
        });
        navigate('/reviews');
      })
      .catch((err) => {
        // console.log(err);
      })

  }

  return (
    <div>
      <h2 className="text-dark-gray text-sm">Paper ID: {paperDetails.manuscriptId}</h2>
      <h1 className="font-bold text-2xl text-slate">{paperDetails.title}</h1>
      <TextArea
        placeholder="Maximum 200 words"
        className="w-full mt-4"
        title="Comment here"
        valueState={[commentInput, setCommentInput]}
      />
      <div className="flex gap-2 flex-wrap items-center mt-4">
        <button
          onClick={(e) => {
            e.preventDefault();
            setCommentInput("");
          }}
          className="my-4 px-16 lg:px-8 py-2 text-lg border-2 border-violet text-violet bg-white font-semibold w-fit rounded-lg shadow-lg"
        >
          Discard Information
        </button>
        <button
          onClick={handleConfirmAndSubmit}
          className="my-4 px-16 lg:px-8 py-2 text-lg border-2 border-violet bg-violet text-white font-semibold w-fit rounded-lg shadow-lg"
        >
          Confirm and Submit
        </button>
      </div>
      {/* <ErrorDialog
        className="w-full mt-4"
        theme="aqua"
        icon={<SiMinutemailer />}
      >
        Invite will be sent to {"francissharp@gmail.com"}
      </ErrorDialog> */}
    </div>
  );
};

export default CommentWindow;
