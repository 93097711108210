import React, { useEffect } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import { AiOutlineEdit } from "react-icons/ai";
import { BsShieldLock } from "react-icons/bs";
const Register = () => {
  const location = useLocation();

  // useEffect(() => {
  //   console.log(location.pathname);
  // }, []);

  return (
    <section className="pt-20 lg:pt-6 lg:px-[calc(100vw/12)] pb-6 font-nunito flex flex-col divide-y-2 lg:divide-y-0 lg:flex-row divide-gray h-full">
      <nav className="w-full lg:w-1/5 px-6 py-8 lg:flex flex-col space-y-2">
        <div
          className={`flex items-center space-x-4 w-full px-2 py-1 rounded-md ${
            location.pathname === "/register" ||
            location.pathname === "/register/account-details"
              ? "bg-light-violet text-violet"
              : "text-slate"
          }`}
        >
          <AiOutlineEdit />
          <p className="text-left">Account Details</p>
        </div>
        <div
          className={`flex items-center space-x-4 w-full px-2 py-1 rounded-md ${
            location.pathname === "/register/set-password"
              ? "bg-light-violet text-violet"
              : "text-slate"
          }`}
        >
          <BsShieldLock />
          <p className="text-left">Set Password</p>
        </div>
      </nav>
      <article className="w-full lg:w-4/5 px-6 py-8 h-full">
        <Outlet />
      </article>
    </section>
  );
};

export default Register;
