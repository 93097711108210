import React from 'react'

const Footer = () => {
  return (
    <footer className="w-screen bg-gray text-xs text-slate px-6 lg:px-[calc(100vw/12)] py-2">
      Copyright © 2022 PSG College of Technology. All Rights Reserved.
    </footer>
  );
}

export default Footer