import React from "react";
import { AiFillExclamationCircle } from "react-icons/ai";
const Checkbox = ({
  text,
  isDisabled = false,
  className = "w-full",
  valueState = [false, () => { }],
  errorState = ["", () => { }],
}) => {
  const [value, setValue] = valueState;
  const [error, setError] = errorState;
  return (
    <div className={`flex flex-col space-y-4 ${className}`}>
      <div className={`flex space-x-4 items-center`}>
        <button
          disabled={isDisabled}
          className={`w-4 h-4 aspect-square border-gray border-2 p-[1px]`}
          onClick={(e) => {
            e.preventDefault();
            setError("");
            setValue(!value);
          }}
        >
          {value && <div className="bg-aqua w-full h-full"></div>}
        </button>
        <p className="text-dark-gray">{text}</p>
      </div>
      {error.length !== 0 && (
        <div className="flex items-center space-x-2 text-xs text-red">
          <AiFillExclamationCircle />
          <p className="">{error}</p>
        </div>
      )}
    </div>
  );
};

export default Checkbox;
