import React, { useState, useContext, useEffect } from "react";
import Inputfield from "../../components/Forms/Inputfield";
import { AiOutlinePlus, AiFillExclamationCircle } from "react-icons/ai";
import { IoMdClose } from "react-icons/io";
import { useLocation, useNavigate } from "react-router-dom";
import { NewSubmissionContext } from "./NewSubmission";
import API from '../../components/Api/Axios';
import { SUBMISSION_UPDATE_DRAFT } from "../../components/Api/Endpoints";

const Attributes = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { draft, setDraft } = useContext(NewSubmissionContext);

  const [keyword, setKeyword] = useState(draft.keywords);
  const [keywordError, setKeywordError] = useState("");

  const [inputvalueError, setInputvalueError] = useState("");
  const [inputvalue, setInputvalue] = useState("");

  const handleSaveAndProceed = (e) => {

    e.preventDefault();

    // console.log(keyword);
    const isValid = keyword.length > 0;
    if (keyword.length === 0) setKeywordError("Please add a keyword");
    if (!isValid) return;
    else {

      if (keyword && keyword.length > 0) {

        API.post(SUBMISSION_UPDATE_DRAFT, {
          id: draft.id,
          keywords: keyword,
        }).then((res) => {
          setDraft({
            ...draft,
            keywords: keyword,
          });
        }).then(() => {
          navigate("/new-submission/authors");
        }).catch((err) => {
          // console.log(err);
        })

      }

    }
  };

  // useEffect(() => {

  //   setDraft({
  //     ...draft,
  //     progressStep: false,
  //   })

  // }, []);

  useEffect(() => {

    // const { keywords } = draft;
    if (draft.keywords && draft.keywords.length > 0) {
      setKeyword(draft.keywords);
    }

  }, [draft]);

  return (
    <div className="">
      <h2 className="text-dark-gray text-lg">Step 3</h2>
      <h1 className="font-bold text-3xl">Add Attributes</h1>
      <form className="flex flex-col space-y-4 items-center lg:items-start w-full py-6">
        <div className="flex space-x-4 flex-row w-full">
          <Inputfield
            errorState={[inputvalueError, setInputvalueError]}
            valueState={[inputvalue, setInputvalue]}
            placeholder="Type Keyword here"
            type="text"
            title="Keywords"
            className="w-full"
          />
          <div className="h-full flex justify-end ">
            <button
              onClick={(e) => {
                e.preventDefault();
                if (inputvalue === "") {
                  setInputvalueError("Please enter a keyword");
                } else if (!keyword.includes(inputvalue)) {
                  setKeyword([...keyword, inputvalue]);
                  setKeywordError("");
                  setInputvalue("");
                  setInputvalueError("");
                } else {
                  setInputvalueError("Keyword already exists");
                }
              }}
              className="flex items-center mt-8 space-x-2 px-6 py-2 bg-dark-gray text-white font-semibold w-fit rounded-lg"
            >
              <AiOutlinePlus />
              <p className="">Add</p>
            </button>
          </div>
        </div>
        <div className="flex space-x-4 flex-wrap items-center">
          {keyword.map((item, index) => (
            <div key={index} className="flex space-x-2 bg-cloud rounded-lg shadow-md py-2 px-4">
              <p className="">{item}</p>
              <button
                className="text-slate hover:text-red"
                onClick={(e) => {
                  e.preventDefault();
                  setKeyword([
                    ...keyword.slice(0, index),
                    ...keyword.slice(index + 1),
                  ]);
                  setKeywordError("");
                }}
              >
                <IoMdClose className="text-slate hover:text-red" />
              </button>
            </div>
          ))}
        </div>
        {keywordError.length !== 0 && (
          <div className="flex items-center space-x-2 text-xs text-red">
            <AiFillExclamationCircle />
            <p className="">{keywordError}</p>
          </div>
        )}
        <div className="flex flex-col lg:flex-row lg:space-x-4 items-center ">
          <button
            onClick={(e) => {
              e.preventDefault();
              setKeyword([]);
              setKeywordError("");
            }}
            className="my-4 px-16 lg:px-8 py-2 text-lg border-2 border-violet text-violet bg-white font-semibold w-fit rounded-lg shadow-lg"
          >
            Discard Information
          </button>
          <button
            onClick={(e) => handleSaveAndProceed(e)}
            className="my-4 px-16 lg:px-8 py-2 text-lg border-2 border-violet bg-violet text-white font-semibold w-fit rounded-lg shadow-lg"
          >
            Save and Proceed
          </button>
        </div>
      </form>
    </div>
  );
};

export default Attributes;
