import React, { useState, useEffect, useContext } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { HiDownload } from "react-icons/hi";
import { PopupWindowContext } from "../../components/PopupWindow/PopupWindow";
import { dummyPaperDetails } from "../../data/dummyPaperDetails";
import TextOutput from "../../components/Forms/TextOutput";
import dummyCommentsData from "../../data/dummyCommentsData";
import { AiFillExclamationCircle } from "react-icons/ai";
import API from "../../components/Api/Axios";
import { SUBMISSION_ADD_REVISION, SUBMISSION_DETAILS, SUBMISSION_FILE_DOWNLOAD, SUBMISSION_FILE_UPLOAD, SUBMISSION_SUBMIT_REVISION } from "../../components/Api/Endpoints";
import { AuthContext } from "../../components/Auth/AuthContext";

const AddRevision = () => {

  const navigate = useNavigate();
  const { auth } = useContext(AuthContext);

  const { paperID } = useParams();
  const [, dispatch] = useContext(PopupWindowContext);

  const [paperDetails, setPaperDetails] = useState({
    manuscriptId: "",
    title: "",
    fileUpload: "",
  });

  const [comments, setComments] = useState([]);
  const [file, setFile] = useState("");
  const [fileError, setFileError] = useState("");


  // useEffect(() => {
  //   setComments(dummyCommentsData);
  // }, []);

  useEffect(() => {
    API.get(SUBMISSION_DETAILS.replace(':id', paperID))
      .then((res) => {

        const data = res.data.data;

        setPaperDetails({
          manuscriptId: data.id,
          title: data.title,
          fileUpload: data.fileUpload,
        });

        setComments(data.comments);

      })
      .catch((err) => {
        // console.log(err);
      })

  }, []);

  const handleUpload = (e) => {

    e.preventDefault();

    const file = e.target.files[0];

    const dataForm = new FormData();
    dataForm.append('file', file);

    // upload to server
    API.post(SUBMISSION_FILE_UPLOAD, dataForm, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then((res) => {

      const data = res.data.data;
      // console.log(data);
      setFileError("");

      API.post(SUBMISSION_ADD_REVISION, {
        id: paperID,
        fileUpload: data.id,
      }).then((res) => {
        setFile(data);
      }).catch((err) => {
        // console.log(err);
      })

    }).catch((err) => {
      if (err.response) {
        if (err.response.status === 400) {
          setFileError(err.response.data.message);
        }
      }
    });

  }

  const handleDownload = (e, file) => {
    e.preventDefault();
    // href={SUBMISSION_FILE_DOWNLOAD.replace(':id', file.id)}
    API.get(SUBMISSION_FILE_DOWNLOAD.replace(":id", file.id), {
      responseType: "blob",
    }).then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", file.fileName); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  };

  const handleConfirm = (e) => {
    e.preventDefault();

    API.post(SUBMISSION_SUBMIT_REVISION, { id: paperID })
      .then((res) => {
        // console.log(res);
        navigate('/submissions');
      })
      .catch((err) => {
        // console.log(err);
      });

  };

  useEffect(() => {
    if (auth === null || auth === undefined || auth === {}) {
      navigate('/login');
    }
  }, [auth])


  // TODO: File upload and stuffs

  return (
    <section className="pt-2 0 lg:pt-6 lg:px-[calc(100vw/12)] pb-6 font-nunito flex flex-col  lg:flex-row h-full">
      <nav className="w-full lg:w-1/4 px-6 py-4 lg:py-8 lg:flex flex-col space-y-3">
        <h2 className="mt-2 text-dark-gray">PaperID: {paperDetails.manuscriptId}</h2>
        <h1 className="text-2xl font-bold mt-2 text-slate">
          {paperDetails.title}
        </h1>
        <button
          onClick={(e) => {
            handleDownload(e, paperDetails.fileUpload);
          }}
          className="border-violet border-2 rounded-lg bg-light-violet text-violet px-4 w-full py-2 flex items-center justify-center space-x-2"
        >
          <HiDownload className="" />
          <p className="">Download Submission</p>
        </button>
        <button
          onClick={(e) => {
            handleConfirm(e);
          }}
          className="border-violet border-2 rounded-lg bg-violet text-white px-4 w-full py-2 flex items-center justify-center space-x-2"
        >
          <p className="font-bold">Confirm & Submit</p>
        </button>
      </nav>
      <article className="w-full lg:w-3/4 px-6 py-4 lg:py-8 h-full space-y-4">
        <div className="flex-1 ">
          <h1 className="font-bold text-3xl text-slate mb-4">Add Revision</h1>
        </div>
        <form className="flex flex-col space-y-4 items-center lg:items-start w-full py-6">
          <p className="">
            Upload your <b>Revised File</b> here in DOC/DOCX Format
          </p>
          <div className="bg-cloud rounded-lg w-full p-6 flex">
            <label className="cursor-pointer bg-dark-gray px-6 py-2 rounded-lg shadow-md text-white h-fit inline-block ">
              <input
                type="file"
                className="hidden"
                onChange={(e) => handleUpload(e)}
              />
              File Upload
            </label>
            {file && (
              <div className="flex-1 px-6">
                Uploaded {/* <Link to=> */}
                <button
                  onClick={(e) => {
                    handleDownload(e);
                  }}
                >
                  <span className="text-aqua hover:underline">
                    {file.fileName}
                  </span>
                </button>
                {/* </Link> */}
              </div>
            )}
          </div>
          {fileError.length !== 0 && (
            <div className="flex items-center space-x-2 text-xs text-red">
              <AiFillExclamationCircle />
              <p className="">{fileError}</p>
            </div>
          )}
        </form>
        <h2 className="text-lg text-dark-gray">Reviewers' Comments</h2>
        <div className="space-y-4">
          {/* {comments.map((cmnt, idx) => (
            <TextOutput className={"whitespace-pre-line"} text={cmnt} subtext={`Reviewer #${idx + 1}`} />
          ))} */}
          {comments.filter((a) => a.isChair).map((cmnt, idx) => (
            <TextOutput className={'whitespace-pre-line'} text={cmnt.comment} subtext={``} />
          ))}

          {comments.filter((a) => !a.isChair).map((cmnt, idx) => (
            <TextOutput className={'whitespace-pre-line'} text={cmnt.comment} subtext={`Reviewer #${idx + 1}`} />
          ))}
        </div>
      </article>
    </section>
  );
};

export default AddRevision;
