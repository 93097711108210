import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { NewSubmissionContext } from "./NewSubmission";
import { AiFillExclamationCircle } from "react-icons/ai";
import API from '../../components/Api/Axios';
import { SUBMISSION_FILE_DOWNLOAD, SUBMISSION_FILE_UPLOAD, SUBMISSION_UPDATE_DRAFT } from "../../components/Api/Endpoints";

const FileUpload = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const { draft, setDraft } =
    useContext(NewSubmissionContext);

  const [file, setFile] = useState("");
  const [fileError, setFileError] = useState("");
  // const [progressValue, setProgressValue] = useState(0);
  // const progressBar = useRef(null);

  const handleUpload = (e) => {

    e.preventDefault();

    const file = e.target.files[0];

    const dataForm = new FormData();
    dataForm.append('file', file);

    // upload to server
    API.post(SUBMISSION_FILE_UPLOAD, dataForm, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      // onUploadProgress: (event) => {
        // setProgressValue((event.loaded / event.total) * 100); 
        // progressBar.current.value = Math.round((event.loaded / event.total) * 100);
      // }
    }).then((res) => {
      const data = res.data.data;

      API.post(SUBMISSION_UPDATE_DRAFT, {
        id: draft.id,
        fileUpload: data.id,
      }).then((res) => {
        setFile(data);
        setDraft({ ...draft, ...{ fileUpload: data } });
        // setTimeout(() => { progressBar.current.value = 0; }, 1300);
      }).catch((err) => {
        // console.log(err);
      })

    }).catch((err) => {
      if (err.response) {
        if (err.response.status === 400) {
          setFileError(err.response.data.message);
        }
      }
    });

  }

  const handleDownload = (e) => {

    e.preventDefault();
    // href={SUBMISSION_FILE_DOWNLOAD.replace(':id', file.id)}
    API.get(
      SUBMISSION_FILE_DOWNLOAD.replace(':id', file.id),
      {
        responseType: 'blob'
      }
    ).then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', file.fileName); //or any other extension
      document.body.appendChild(link);
      link.click();
    })

  }

  const handleSaveAndProceed = (e) => {

    e.preventDefault();

    const isValid = file;
    if (!file) setFileError("Please select a file");
    if (!isValid) return;
    else {
      navigate(`/new-submission/attributes`);
    }

  };

  useEffect(() => {

    if (draft.fileUpload) {
      setFile(draft.fileUpload);
    }

  }, [draft]);

  return (
    <div className="">
      <h2 className="text-dark-gray text-lg">Step 2</h2>
      <h1 className="font-bold text-3xl">Upload your file</h1>
      <form className="flex flex-col space-y-4 items-center lg:items-start w-full py-6">
        <p className="">Upload your file here in DOC/DOCX Format</p>
        <div className="bg-cloud rounded-lg w-full p-6 flex">
          <label className="cursor-pointer bg-dark-gray px-6 py-2 rounded-lg shadow-md text-white h-fit inline-block ">
            <input
              type="file"
              className="hidden"
              onChange={(e) => handleUpload(e)}
            />
            File Upload
          </label>
          <div className="flex-1 px-6">
            {file && <div>
              Uploaded{" "}
              {/* <Link to=> */}
              <button onClick={(e) => { handleDownload(e) }}>
                <span className="text-aqua hover:underline">{file.fileName}</span>
              </button>
              {/* </Link> */}
            </div>}
            {/* <progress value="0" ref={progressBar} max="100" className="w-full progress-bar" /> */}
          </div>
        </div>
        {/* <p className="relative before:absolute before:content-[''] before:h-[6px] before:w-[6px] before:bg-red before:top-[calc(50%-3px)] before:left-0 pl-4 text-red">Please make sure that, all author identities are removed from the manuscript (Blinded Manuscript)</p> */}
        {fileError.length !== 0 && (
          <div className="flex items-center space-x-2 text-xs text-red">
            <AiFillExclamationCircle />
            <p className="">{fileError}</p>
          </div>
        )}
        <div className="flex flex-col lg:flex-row lg:space-x-4 items-center ">
          <button
            onClick={(e) => {
              e.preventDefault();
              setFile(null);
              setFileError("");
            }}
            className="my-4 px-16 lg:px-8 py-2 text-lg border-2 border-violet text-violet bg-white font-semibold w-fit rounded-lg shadow-lg"
          >
            Discard Information
          </button>
          <button
            onClick={(e) => handleSaveAndProceed(e)}
            className="my-4 px-16 lg:px-8 py-2 text-lg border-2 border-violet bg-violet text-white font-semibold w-fit rounded-lg shadow-lg"
          >
            Save and Proceed
          </button>
        </div>
      </form>
    </div>
  );
};

export default FileUpload;
