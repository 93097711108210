import React, { createContext, useContext, useEffect } from "react";
import { IoMdClose } from "react-icons/io";
import { HiOutlineTrash } from "react-icons/hi";
import { IoCloseSharp } from 'react-icons/io5';

export const PopupContext = createContext();

const Popup = () => {
  const [
    {
      title,
      body,
      icon,
      isVisible,
      successText,
      cancelText,
      noCancel,
      handleSuccess,
      handleCancel,
      theme,
    }, dispatch
  ] = useContext(PopupContext);

  return (
    <div
      className={`${isVisible && "z-30 w-screen h-screen"
        } select-none absolute top-1/2 right-1/2 translate-x-1/2 -translate-y-1/2 flex justify-center items-center`}
    >
      <div
        className={`${!isVisible && "hidden"
          } bg-white border-2 border-dark-gray h-fit w-96 shadow-xl rounded-lg flex flex-col space-y-4 items-start justify-center font-nunito p-6`}
      >
        <div className="flex flex-row items-center justify-between w-full">
          <div
            className={`h-fit p-2 rounded-full aspect-square bg-light-${theme}`}
          >
            {React.cloneElement(icon, {
              className: `text-${theme}`,
              size: 32,
            })}
          </div>
          <button
            className={`h-fit p-2 rounded-full aspect-square bg-light-red`}
            onClick={(e) => { dispatch({ type: "HIDE" }) }}
          >
            <IoCloseSharp className="text-red"></IoCloseSharp>
          </button>
        </div>
        <h1 className="text-slate font-bold text-2xl">{title}</h1>
        <p className="text-dark-gray">{body}</p>
        <div className="flex items-center space-x-4 w-full">
          <button
            onClick={(e) => handleCancel(e)}
            className={`px-6 py-2 rounded-lg ${noCancel ? "hidden" : "w-1/2"
              } bg-cloud text-slate`}
          >
            {cancelText}
          </button>
          <button
            onClick={(e) => handleSuccess(e)}
            className={`px-6 py-2 rounded-lg ${noCancel ? "w-full" : "w-1/2"
              } bg-${theme} text-white`}
          >
            {successText}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Popup;
