import React, { useEffect, useContext, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import API from "../../components/Api/Axios";
import {
  SUBMISSION_DELETE_DRAFT,
  SUBMISSION_GET_DRAFT,
  SUBMISSION_NEW_DRAFT,
} from "../../components/Api/Endpoints";
import { AuthContext } from "../../components/Auth/AuthContext";
import DialogCard from "../../components/Forms/DialogCard";
import { BsFillPatchExclamationFill } from "react-icons/bs";

const Notifications = () => {
  const [draftAvailable, setDraftAvailable] = useState(false);
  const [draftId, setDraftId] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    API.get(SUBMISSION_GET_DRAFT).then((res) => {
      const data = res.data.data;
      if (Object.keys(data).length) {
        setDraftAvailable(true);
        setDraftId(data.id);
      } else {
        setDraftAvailable(false);
      }
    });
  });

  // useEffect(() => {
  //   API.get(SUBMISSION_YET_TO_PAY).then((res) => {
  //     const data = res.data.data;
  //     setPendingSubmissions(data);
  //   });
  // }, []);


  const handleDiscard = (e) => {
    e.preventDefault();

    API.delete(SUBMISSION_DELETE_DRAFT, {
      data: {
        id: draftId,
      },
    })
      .then(() => {
        setDraftAvailable(false);
      })
      .catch(() => {
        setDraftAvailable(false);
      });
  };

  return (
    <div className="flex flex-col space-y-4">
      {/* {draftAvailable ? (
        <DraftAvailableCard handleDiscard={handleDiscard} />
      ) : (
        <CreateNewSubmissionCard />
      )} */}
      <p>Submissions have been closed.</p>
      {/* <SubmissionRevisedCard />
      <ProceedtoPaymentCard />
      <SubmissionRejectedCard /> */}
    </div>
  );
};

export default Notifications;

const CreateNewSubmissionCard = () => {
  const navigate = useNavigate();

  const handleNewSubmission = (e) => {
    API.post(SUBMISSION_NEW_DRAFT, {})
      .then((res) => {
        // const data = res.data.data;
        navigate(`/new-submission`);
      })
      .catch((err) => {
        // console.log("ERROR", err);
        if (err.response) {
          if (err.response.status === 400) {
            // console.log("Error failed with 400 error");
            API.get(SUBMISSION_GET_DRAFT)
              .then((res) => {
                // console.log();
                navigate("/new-submission");
              })
              .catch((err) => {
                // console.log("ERROR", err);
              });
          }
        }
      });
  };

  return (
    <DialogCard>
      <div className="flex flex-col lg:flex-row w-full lg:items-center">
        <div className="flex flex-col">
          <h2 className="text-dark-gray text-sm">New to this portal?</h2>
          <h1 className="font-semibold text-slate text-lg">
            Create your first submission
          </h1>
        </div>
        <div className="flex-1"></div>
        {/* <Link to="/new-submission"> */}
        <button
          onClick={handleNewSubmission}
          className="mt-4 lg:mt-0 px-8 py-2 bg-violet text-white font-semibold w-fit rounded-lg shadow-lg"
        >
          New Submission
        </button>
        {/* </Link> */}
      </div>
    </DialogCard>
  );
};

const DraftAvailableCard = ({ handleDiscard }) => {
  return (
    <DialogCard theme="red">
      <div className="flex flex-col lg:flex-row w-full lg:items-center">
        <div className="flex flex-col">
          <h2 className="text-dark-gray text-sm">
            You have an incomplete submission
          </h2>
          <h1 className="font-semibold text-slate text-lg">
            Finish your submission
          </h1>
        </div>
        <div className="flex-1"></div>
        <div className="flex items-center space-x-2">
          <Link to="/new-submission">
            <button className="mt-4 lg:mt-0 px-8 py-2 border-2 border-red bg-red text-white font-semibold w-fit rounded-lg shadow-lg">
              Proceed
            </button>
          </Link>
          <button
            onClick={handleDiscard}
            className="mt-4 lg:mt-0 px-8 py-2 box-content border-red text-red border-2 font-semibold w-fit rounded-lg shadow-lg"
          >
            Discard
          </button>
        </div>
      </div>
    </DialogCard>
  );
};

const SubmissionRevisedCard = () => {
  return (
    <DialogCard theme="yellow">
      <div className="flex flex-col lg:flex-row w-full lg:items-center">
        <div className="flex flex-col">
          <h2 className="text-dark-gray text-sm">
            Your submission has been reviewed!
          </h2>
          <h1 className="font-semibold text-slate text-lg">
            Revise your submission
          </h1>
        </div>
        <div className="flex-1"></div>
        {/* <Link to="/new-submission"> */}
        <button className="mt-4 lg:mt-0 px-8 py-2 bg-yellow text-white font-semibold w-fit rounded-lg shadow-lg">
          Add Revision
        </button>
        {/* </Link> */}
      </div>
    </DialogCard>
  );
};

const ProceedtoPaymentCard = () => {
  return (
    <DialogCard theme="green">
      <div className="flex flex-col lg:flex-row w-full lg:items-center">
        <div className="flex flex-col">
          <h2 className="text-dark-gray text-sm">
            Your submission has been approved!
          </h2>
          <h1 className="font-semibold text-slate text-lg">
            Proceed to Payment
          </h1>
        </div>
        <div className="flex-1"></div>
        {/* <Link to="/new-submission"> */}
        <button className="mt-4 lg:mt-0 px-8 py-2 bg-green text-white font-semibold w-fit rounded-lg shadow-lg">
          Proceed
        </button>
        {/* </Link> */}
      </div>
    </DialogCard>
  );
};

const SubmissionRejectedCard = () => {
  return (
    <DialogCard theme="red">
      <div className="flex flex-col lg:flex-row w-full lg:items-center">
        <div className="flex flex-col">
          <h2 className="text-dark-gray text-sm">
            Your submission has been rejected :(
          </h2>
          <h1 className="font-semibold text-slate text-lg">
            Thankyou for your time!
          </h1>
        </div>
        <div className="flex-1"></div>
        {/* <Link to="/new-submission"> */}
        <div className="flex items-center space-x-2">
          <BsFillPatchExclamationFill className="text-red" size={20} />
          <div className="font-semibold text-lg text-red">Rejected</div>
        </div>
        {/* </Link> */}
      </div>
    </DialogCard>
  );
};