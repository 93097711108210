import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import API from "../../components/Api/Axios";
import { CHAIR_ADD_REVIEWERS, CHAIR_GET_REVIEWER_PANEL, CHAIR_GET_SUBMISSION, CHAIR_GET_SUBMISSIONS, CHAIR_SUBMISSION_DETAILS, SUBMISSION_FILE_DOWNLOAD } from "../../components/Api/Endpoints";
import Inputfield from "../../components/Forms/Inputfield";
import { validateEmail } from "../../components/Forms/validations";
import dummyReviewerPanel from "../../data/dummyReviewerPanel";
import dummyReviewers from "../../data/dummyReviewers";
import { HiDownload } from "react-icons/hi";
import { dummyPaperDetails } from "../../data/dummyPaperDetails";
import categoriesData from "../../data/categoriesData";
import { BsExclamationCircle } from "react-icons/bs";
import { IoMdClose } from "react-icons/io";
import { NilCard } from "./SubmissionList";
import { PopupWindowContext } from "../../components/PopupWindow/PopupWindow";
import AddReviewer from "./AddReviewer";

const SendtoReview = () => {
  const { paperID } = useParams();
  const [, dispatch] = useContext(PopupWindowContext);

  const navigate = useNavigate();

  const [paperDetails, setPaperDetails] = useState({
    id: "",
    status: "",
    type: "",
    title: "",
    abstract: "",
    fileUpload: null,
    keywords: [],
    author: {},
    authors: [],
    coverLetter: null,
    numberOfFigures: "",
    numberOfTables: "",
    numberOfWords: "",
    category: "",
    subCategory: "",
  });

  // useEffect(() => {
  //   setPaperDetails(dummyPaperDetails(paperID));
  // }, []);

  // const [categoriesFilter, setCategoriesFilter] = useState([]);

  const [reviewersNotInPanel, setReviewersNotInPanel] = useState([]);
  const [reviewersAdded, setReviewersAdded] = useState([]);
  const [reviewers, setReviewers] = useState([]);
  const [filteredReviewers, setFilteredReviewers] = useState([]);
  const [reviewerInput, setReviewerInput] = useState("");
  const [reviewerInputError, setReviewerInputError] = useState("");

  useEffect(() => {

    API.get(CHAIR_SUBMISSION_DETAILS.replace(':id', paperID))
    .then((res) => {
      const data = res.data.data;
      setPaperDetails(data);
      // console.log(res);
    })
    .catch((err) => {
      navigate('/submissions');
    });


    API.get(CHAIR_GET_SUBMISSION.replace(':id', paperID))
      .then((res) => {

        const data = res.data.data;
        const reviewers = data.reviewers;

        // setPaperDetails({
        //   manuscriptId: data.manuscriptId,
        //   title: data.title,
        //   authorName: data.authorName
        // });

        setReviewersAdded(
          reviewers.map((reviewer) => ({
            ...reviewer,
            invitedNow: false
          }))
        )

      })
      .catch((err) => {
        // console.log(err);
      })

    API.get(CHAIR_GET_REVIEWER_PANEL)
      .then((res) => {

        const data = res.data.data;

        if (data) {

          setReviewers(data.map((reviewer) => ({
            ...reviewer,
            invitedNow: true,
          })));

        } else {
          setReviewers([]);
        }

      })
      .catch((err) => {
        // console.log(err);
      })

  }, []);

  // useEffect(() => {
  //   setReviewers(
  //     dummyReviewerPanel.map((reviewer) => {
  //       return {
  //         ...reviewer,
  //         invitedNow: false,
  //       };
  //     })
  //   );
  // }, []);

  useEffect(() => {
    setFilteredReviewers(
      reviewers.filter(
        (rev) =>
          // (categoriesFilter.length <= 0 ||
          //   categoriesFilter.includes(rev.category)) &&
          (reviewerInput.length <= 0 ||
            rev.deptOrTeam.toLowerCase().includes(reviewerInput.toLowerCase()) ||
            rev.category.toLowerCase().includes(reviewerInput.toLowerCase()) ||
            rev.name.toLowerCase().includes(reviewerInput.toLowerCase()) ||
            rev.email.toLowerCase().includes(reviewerInput.toLowerCase()))
      )
    );
  }, [reviewers, reviewerInput]);

  const handleInviteReviewer = (e) => {
    e.preventDefault();
    let isValid = true;
    if (reviewerInput.length === 0) {
      setReviewerInputError("Required field to add.");
      isValid = false;
    }
    if (!validateEmail(reviewerInput)) {
      setReviewerInputError("Enter valid email address");
      isValid = false;
    }
    if (!isValid) return;
    else {
      setReviewerInputError("");
      // setReviewersAdded([
      //   ...reviewersAdded,
      //   {
      //     name: "",
      //     id: "",
      //     category: "",
      //     email: reviewerInput,
      //     invitedNow: true,
      //   },
      // ]);
      // setReviewerInput("");
      dispatch({
        type: "SHOW",
        payload: {
          title: "Invite Reviewer",
          element: (
            <AddReviewer
              addedReviewerState={[reviewersAdded, setReviewersAdded]}
              emailInitial={reviewerInput}
              handleInvite={() => {
                setReviewerInput("");
                dispatch({
                  type: "HIDE",
                });
              }}
            />
          ),
          handleClose: function (e) {
            e.preventDefault();
            dispatch({
              type: "HIDE",
            });
          },
        },
      });
    }
  };

  const handleDownload = (e, file) => {
    e.preventDefault();
    // href={SUBMISSION_FILE_DOWNLOAD.replace(':id', file.id)}
    API.get(SUBMISSION_FILE_DOWNLOAD.replace(":id", file.id), {
      responseType: "blob",
    }).then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", file.fileName); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  };

  const handleConfirm = (e) => {

    e.preventDefault();

    API.post(CHAIR_ADD_REVIEWERS, {
      id: paperID,
      reviewers: reviewersAdded,
    })
      .then((res) => {

        setReviewersAdded(
          reviewersAdded.map((reviewer) => ({
            ...reviewer,
            invitedNow: false
          }))
        )

        navigate('/admin');

      })
      .catch((err) => {
        // console.log(err);
      });

  };

  return (
    <section className="pt-20 lg:pt-6 lg:px-[calc(100vw/12)] pb-6 font-nunito flex flex-col  lg:flex-row h-full">
      <nav className="w-full lg:w-1/4 px-6 py-4 lg:py-8 lg:flex flex-col space-y-3">
        <h2 className="mt-2 text-dark-gray">PaperID: {paperDetails.id}</h2>
        <h1 className="text-2xl font-bold mt-2 text-slate">
          {paperDetails.title}
        </h1>
        <h2 className="mt-2 text-slate">
          Posted By: <b>{paperDetails.author.name}</b>
        </h2>
        <button
          onClick={(e) => {
            handleDownload(e, paperDetails.fileUpload);
          }}
          className="border-violet border-2 rounded-lg bg-light-violet text-violet px-4 w-full py-2 flex items-center justify-center space-x-2"
        >
          <HiDownload className="" />
          <p className="">Download Paper</p>
        </button>
        <button
          onClick={(e) => {
            handleConfirm(e);
          }}
          className="border-violet border-2 rounded-lg bg-violet text-white px-4 w-full py-2 flex items-center justify-center space-x-2"
        >
          <p className="font-bold">Confirm & Submit</p>
        </button>
      </nav>
      <article className="w-full lg:w-3/4 px-6 py-4 lg:py-8 h-full space-y-4">
        <div className="flex-1 ">
          <h1 className="font-bold text-3xl text-slate mb-4">
            Send to Review
          </h1>
        </div>

        <div className="flex flex-col lg:flex-row items-center lg:space-x-2  lg:items-start w-full mt-4">
          <Inputfield
            valueState={[reviewerInput, setReviewerInput]}
            errorState={[reviewerInputError, setReviewerInputError]}
            placeholder="Enter reviewer email"
            title="Add Reviewer"
            className="flex-1 w-full"
          />
          <button
            onClick={(e) => handleInviteReviewer(e)}
            className="flex items-center mt-4 lg:mt-[2rem] h-fit space-x-2 px-6 py-2 bg-dark-gray text-white font-semibold w-fit rounded-lg"
          >
            <p className="w-fit">Invite Reviewer</p>
          </button>
        </div>

        {reviewersAdded.length <= 0 ? (
          <div className="flex items-center space-x-2">
            <BsExclamationCircle className="text-dark-gray" />
            <p className="text-dark-gray">No Reviewers added.</p>
          </div>
        ) : (
          <div className="flex gap-2 items-center flex-wrap">
            {reviewersAdded.map((reviewer) => (
              <div
                className={`px-4 rounded-full py-1 flex items-center space-x-2 ${reviewer.invitedNow
                  ? "bg-light-aqua text-aqua"
                  : "bg-light-violet text-violet"
                  }`}
              >
                <p className="">
                  {(reviewer.name && reviewer.name.length > 0) ? reviewer.name : reviewer.email}
                </p>
                <button
                  className=""
                  onClick={(e) => {
                    e.preventDefault();
                    setReviewersAdded(
                      reviewersAdded.filter(
                        (rev) => rev.email !== reviewer.email
                      )
                    );
                  }}
                >
                  <IoMdClose
                    className={`${reviewer.invitedNow ? "text-aqua" : "text-violet"
                      } hover:text-red`}
                  />
                </button>
              </div>
            ))}
          </div>
        )}

        <div className="w-full flex flex-col mt-4 items-start lg:flex-row space-y-4 lg:space-y-0 lg:space-x-4">
          {/* <section className="w-full lg:w-1/4">
            <h3 className="text-dark-gray">Filter</h3>
            <div className="flex items-center">
              <h3 className="text-slate font-bold">By Categories</h3>
              <div className="flex-1"></div>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setCategoriesFilter([]);
                }}
                className="bg-light-red text-red px-3 border border-red rounded-lg py-1 text-xs"
              >
                Clear
              </button>
            </div>
            <div className="overflow-y-scroll pr-2 h-[calc(100vh-28rem)] mt-4 gap-2 flex flex-col">
              {categoriesData.map((cat) =>
                categoriesFilter.includes(cat) ? (
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setCategoriesFilter(
                        categoriesFilter.filter((c) => c !== cat)
                      );
                    }}
                    className="rounded-md px-2 py-1 w-fit text-left text-ellipsis text-xs text-violet bg-light-violet"
                  >
                    {cat}
                  </button>
                ) : (
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setCategoriesFilter([...categoriesFilter, cat]);
                    }}
                    className="w-full px-2 text-left text-ellipsis  text-xs"
                  >
                    {cat}
                  </button>
                )
              )}
            </div>
          </section> */}
          <section className="w-full">
            {filteredReviewers.length <= 0 ? (
              <div className="w-full py-4 px-4 flex items-center justify-center h-full">
                <NilCard text="No Reviewers found! :(" />
              </div>
            ) : (
              <div className="grid w-full grid-cols-1 lg:grid-cols-3">
                {filteredReviewers.map((rev) => (
                  <div
                    onClick={(e) => {
                      e.preventDefault();
                      if (!reviewersAdded.find((r) => r.email === rev.email)) {
                        setReviewersAdded([...reviewersAdded, rev]);
                      }
                    }}
                    className="px-4 py-4 flex flex-col hover:bg-cloud cursor-pointer"
                  >
                    <p className="font-bold text-slate text-lg">{rev.name}</p>
                    <p className="text-dark-gray text-sm break-words">
                      {rev.email}
                    </p>
                    <p className="mt-2 text-dark-gray bg-cloud px-3 py-1 w-fit text-xs rounded-full">
                      {rev.category}
                    </p>
                  </div>
                ))}
              </div>
            )}
          </section>
        </div>
      </article>
    </section>
  );
};

export default SendtoReview;
