import React, { useEffect, useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { HiDownload } from "react-icons/hi";
import TextOutput from "../../components/Forms/TextOutput";
import { IoMdClose } from "react-icons/io";
import { BsCheck2 } from "react-icons/bs";
import { HiOutlinePencil } from "react-icons/hi";
import { PopupContext } from "../../components/PopupSystem/Popup";
import API from "../../components/Api/Axios";
import { SUBMISSION_DETAILS, SUBMISSION_FILE_DOWNLOAD, SUBMISSION_FINAL_TEMPLATE_DOWNLOAD } from "../../components/Api/Endpoints";
import { dummyPaperDetails } from "../../data/dummyPaperDetails";
import { AuthContext } from "../../components/Auth/AuthContext";
import { PopupWindowContext } from "../../components/PopupWindow/PopupWindow";
import CertificateDetails from "./CertificateDetails";

const SubmissionDetails = () => {

  const { paperID } = useParams();
  const [, dispatch] = useContext(PopupContext);

  const [, dispatchWindow] = useContext(PopupWindowContext);

  const { auth } = useContext(AuthContext);

  const navigate = useNavigate();

  const [paperDetails, setPaperDetails] = useState({
    id: "",
    status: "",
    type: "",
    title: "",
    abstract: "",
    fileUpload: null,
    keywords: [],
    authors: [],
    certificateDetails: [],
    coverLetter: null,
    numberOfFigures: "",
    numberOfTables: "",
    isCoAuthor: true,
    numberOfWords: "",
    category: "",
    subCategory: "",
  });

  const [comments, setComments] = useState([]);


  const getColorFromStatus = () => {
    const colorCodes = [
      {
        value: "APPROVED",
        text: "Accepted",
        color: "green",
      },
      {
        value: "APPROVED_PAID",
        text: "Accepted & Paid",
        color: "green",
      },
      {
        value: "FINAL_SUBMITTED",
        text: "Final Submitted",
        color: "teal",
      },
      {
        value: "REJECTED",
        text: "Rejected",
        color: "red",
      },
      {
        value: "REVISE",
        text: "Sent to Revision",
        color: "yellow",
      },
      {
        value: "UNDER_REVIEW",
        text: "Under Review",
        color: "aqua",
      },
      {
        value: "UNREAD",
        text: "Submitted",
        color: "violet",
      },
    ];

    let a = colorCodes.find((color) => color.value === paperDetails.status);
    return a ? a.color : "";
  };

  useEffect(() => {
    if (auth === null || auth === undefined || auth === {}) {
      navigate('/login');
    }
  }, [auth])


  // useEffect(() => {
  //   setPaperDetails(dummyPaperDetails(paperID));
  // }, []);

  useEffect(() => {

    API.get(SUBMISSION_DETAILS.replace(':id', paperID))
      .then((res) => {
        const data = res.data.data;
        setPaperDetails(data);
        setComments(data.comments ? data.comments : []);
        // console.log(res);
      })
      .catch((err) => {
        navigate('/submissions');
      });

  }, []);

  const handleDownload = (e, file) => {

    e.preventDefault();
    // href={SUBMISSION_FILE_DOWNLOAD.replace(':id', file.id)}
    API.get(
      SUBMISSION_FILE_DOWNLOAD.replace(':id', file.id),
      {
        responseType: 'blob'
      }
    ).then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', file.fileName); //or any other extension
      document.body.appendChild(link);
      link.click();
    })

  };

  const handleTemplateDownload = (e) => {
    e.preventDefault();
    API.get(
      SUBMISSION_FINAL_TEMPLATE_DOWNLOAD,
      {
        responseType: 'blob'
      }
    ).then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Final_template.docx');
      document.body.appendChild(link);
      link.click();
    })
  }

  return (
    <section className="px-3 lg:px-[calc(100vw/12)] pt-20 lg:pt-6 pb-6 font-nunito flex flex-col  lg:flex-row h-full">
      <nav className="w-full lg:w-1/4 px-6 py-4 lg:py-8 lg:flex flex-col space-y-3">
        <button
          onClick={(e) => {
            handleDownload(e, paperDetails.fileUpload);
          }}
          className="border-violet border-2 rounded-lg bg-light-violet text-violet px-4 w-full py-2 flex items-center justify-center space-x-2"
        >
          <HiDownload className="" />
          <p className="">Download Paper</p>
        </button>
        <button
          onClick={(e) => {
            handleDownload(e, paperDetails.coverLetter);
          }}
          className="border-violet border-2 rounded-lg bg-light-violet text-violet px-4 w-full py-2 flex items-center justify-center space-x-2"
        >
          <HiDownload className="" />
          <p className="">Download Turnitin Plagiarism Report with % of Similarity</p>
        </button>
        <div className="flex items-start space-x-2">
          <p className="text-dark-gray w-[10ch] ">Type</p>
          <div className="flex-1  ">
            <p className="font-bold">{paperDetails.type}</p>
          </div>
        </div>
        <div className="flex items-start space-x-2">
          <p className="text-dark-gray w-[10ch] ">Category</p>
          <div className="flex-1  ">
            <p className="font-bold">{paperDetails.category}</p>
          </div>
        </div>
        <div className="flex items-start space-x-2">
          <p className="text-dark-gray w-[10ch] ">Subcategory</p>
          <div className="flex-1  ">
            <p className="font-bold">{paperDetails.subCategory}</p>
          </div>
        </div>
        <div className="flex items-center space-x-2">
          <p className="text-dark-gray w-[10ch] ">Status</p>
          <div className="">
            <p
              className={`py-1 px-2 text-xs rounded-xl bg-light-${getColorFromStatus()} text-${getColorFromStatus()}`}
            >
              {paperDetails.status === "UNREAD" ? "SUBMITTED" : paperDetails.status}
            </p>
          </div>
        </div>
        <div className="flex items-start space-x-2">
          <p className="text-dark-gray w-[10ch] ">Authors</p>
          <div className="flex-1  ">
            {paperDetails.authors.map((author) => (
              <React.Fragment>
                {" "}
                <p className="font-bold">{author.name}</p>
                <p className="text-xs font-normal">{author.email}</p>
              </React.Fragment>
            ))}
          </div>
        </div>
        {paperDetails.status === "REVISE" && !paperDetails.isCoAuthor && <button
          onClick={(e) => { navigate('/revision/' + paperID); }}
          className="border-yellow border-2 rounded-lg bg-light-yellow text-yellow px-4 w-full py-2 flex items-center justify-center space-x-2 mt-4"
        >
          {/* <HiDownload className="" /> */}
          <p className="">Add Revision</p>
        </button>
        }

        {paperDetails.status === "APPROVED_PAID" && !paperDetails.isCoAuthor &&
          <>
            <button
              onClick={(e) => { handleTemplateDownload(e) }}
              className="my-4 px-16 lg:px-8 py-2 text-lg bg-green text-white font-semibold w-full rounded-lg shadow-lg"
            >
              Download Template
            </button>
            <button
              onClick={(e) => { navigate('/revision/' + paperID); }}
              className="border-green border-2 rounded-lg bg-light-green text-green px-4 w-full py-2 flex items-center justify-center space-x-2 mt-4"
            >
              <p className="">Add Camera Ready Manuscript</p>
            </button>
            {/* <h2 className="text-dark-gray">Kindly add a camera ready copy of the Manuscript with all the author data.</h2> */}
          </>
        }

        {(paperDetails.status === "FINAL_SUBMITTED" || paperDetails.status === "APPROVED_PAID") && !paperDetails.isCoAuthor &&
          <div className="mt-5">
            <h2 className="text-dark-gray">Kindly update author details for certificate purposes, check twice before submitting.</h2>
            <button
              onClick={(e) => {
                e.preventDefault();
                dispatchWindow({
                  type: "SHOW",
                  payload: {
                    title: "Details",
                    element: (<CertificateDetails authors={paperDetails.certificateDetails.length > 0 ? paperDetails.certificateDetails : paperDetails.authors.map((author) => ({ title: "", name: author.name, designation: "", collegeOrOrganizationName: "", department: "" }))} submissionId={paperID} dispatchWindow={dispatchWindow}></CertificateDetails>),
                    handleClose: function (e) {
                      e.preventDefault();
                      dispatchWindow({
                        type: "HIDE",
                      });
                    },
                  },
                });
              }}
              className="border-green border-2 rounded-lg bg-light-green text-green px-4 w-full py-2 flex items-center justify-center space-x-2 mt-4"
            >
              <p className="">Update author details</p>
            </button>
          </div>
        }

      </nav>
      <article className="w-full lg:w-3/4 px-6 py-4 lg:py-8 h-full space-y-4">
        <div className="flex-1">
          <h2 className="text-dark-gray">Paper ID: {paperDetails.id}</h2>
          <h1 className="font-bold text-3xl text-slate">
            {paperDetails.title}
          </h1>
        </div>
        <TextOutput
          text={paperDetails.abstract}
          subtext="Abstract"
          className="w-full"
        />
        <TextOutput
          subtext="Keywords"
          text={
            <div className="flex items-center flex-wrap gap-x-2 gap-y-2">
              {paperDetails.keywords.map((keyword, index) => (
                <div
                  className="h-fit py-1 px-3 rounded-full bg-cloud text-sm"
                  key={index}
                >
                  {keyword}
                </div>
              ))}
            </div>
          }
          className="w-full lg:w-2/3"
        />
        <div className="grid grid-cols-2 lg:grid-cols-3 gap-4 w-full">
          <TextOutput
            text={paperDetails.numberOfFigures}
            subtext="Number of Figures"
            className="w-full"
          />
          <TextOutput
            text={paperDetails.numberOfTables}
            subtext="Number of Tables"
            className="w-full"
          />
          <TextOutput
            text={paperDetails.numberOfWords}
            subtext="Number of Words"
            className="w-full"
          />
        </div>
        {comments.length > 0 && <h2 className="text-lg text-dark-gray">Reviewers' Comments</h2>}
        <div className="space-y-4">

          {comments.filter((a) => a.isChair).map((cmnt, idx) => (
            <TextOutput className={'whitespace-pre-line'} text={cmnt.comment} subtext={``} />
          ))}

          {comments.filter((a) => !a.isChair).map((cmnt, idx) => (
            <TextOutput className={'whitespace-pre-line'} text={cmnt.comment} subtext={`Reviewer #${idx + 1}`} />
          ))}

        </div>
        {/* <h2 className="font-bold text-2xl text-slate">Revisions</h2>
        <div className="grid grid-cols-2 lg:grid-cols-3 gap-4 w-full">
          {paperDetails.revisions.map((revision, index) => (
            <TextOutput
              text={
                <div className="flex items-center space-x-2" key={revision.index}>
                  <p className="text-sm">{revision.submittedAt}</p><br />
                  <button
                    onClick={(e) => {
                      handleDownload(e);
                    }}
                    className="border-red border-2 rounded-lg bg-light-red text-red px-4 w-full py-2 flex items-center justify-center space-x-2"
                  >
                    <HiDownload className="" />
                    <p className="">Download Revision</p>
                  </button>
                </div>
              }
              subtext={`Revision ${index + 1}`}
            />
          ))}
        </div> */}

        {/* <TextOutput text={paperDetails.coverLetter} subtext="Cover Letter" /> */}
      </article>
    </section>
  );
};

export default SubmissionDetails;
