import React, {useState} from 'react'
import Inputfield from '../../components/Forms/Inputfield'
import { AiOutlineCheckCircle, AiFillCheckCircle } from "react-icons/ai";


const SetPassword = () => {

  const [password1, setPassword1] = useState("")
  const [password2, setPassword2] = useState("")
  const [password1Error, setPassword1Error] = useState("")
  const [password2Error, setPassword2Error] = useState("")

  const hasMin8 = pw => pw.length >= 8
  const hasUpper = pw => /[A-Z]/.test(pw)
  const hasNumber = pw => /[0-9]/.test(pw)
  const hasSpecial = pw => /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(pw)

  return (
    <div className="">
      <h1 className="font-bold text-3xl">Create new account</h1>
      <h2 className="text-dark-gray text-lg">Set Password</h2>
      {false ? (
        <p className="my-2">
          A link will be sent to your email address. Please click on the link to
          set your password.
        </p>
      ) : (
        <form className="flex flex-col space-y-4 items-center lg:items-start w-full py-6">
          <div className="flex w-full flex-col lg:flex-row space-y-4 lg:space-y-0 lg:space-x-4">
            <div className="flex space-y-4 flex-col w-full lg:w-1/2">
              <Inputfield
                errorState={[password1Error, setPassword1Error]}
                valueState={[password1, setPassword1]}
                placeholder=""
                type="password"
                title="Enter your password"
                className=" w-full"
              />
              <Inputfield
                errorState={[password2Error, setPassword2Error]}
                valueState={[password2, setPassword2]}
                placeholder=""
                type="password"
                title="Confirm password"
                className="w-full"
              />
            </div>
            <div className="flex space-y-4 flex-col w-full lg:w-1/2 h-full justify-center lg:p-6">
              <div className="flex space-x-2 items-center text-violet">
                {hasMin8(password1) ? (
                  <AiFillCheckCircle />
                ) : (
                  <AiOutlineCheckCircle />
                )}
                <p className="text-sm">Has Minimum 8 characters</p>
              </div>
              <div className="flex space-x-2 items-center text-violet">
                {hasUpper(password1) ? (
                  <AiFillCheckCircle />
                ) : (
                  <AiOutlineCheckCircle />
                )}
                <p className="text-sm">Has an Uppercase letter</p>
              </div>
              <div className="flex space-x-2 items-center text-violet">
                {hasNumber(password1) ? (
                  <AiFillCheckCircle />
                ) : (
                  <AiOutlineCheckCircle />
                )}
                <p className="text-sm">Has a number</p>
              </div>
              <div className="flex space-x-2 items-center text-violet">
                {hasSpecial(password1) ? (
                  <AiFillCheckCircle />
                ) : (
                  <AiOutlineCheckCircle />
                )}
                <p className="text-sm">Has a special character</p>
              </div>
            </div>
          </div>
          <button className="my-4 px-16 lg:px-8 py-2 text-lg bg-violet text-white font-semibold w-fit rounded-lg shadow-lg">
            Set Password
          </button>
        </form>
      )}
    </div>
  );
}

export default SetPassword