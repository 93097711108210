import React from "react";
import { Link } from "react-router-dom";

const Page404 = () => {
  return (
    <section className="space-y-4 pt-20 lg:pt-6 lg:px-[calc(100vw/12)] pb-6 font-nunito flex flex-col items-center justify-center w-full h-[calc(100vh-3rem)] lg:h-full">
      <div className=" space-y-4 drop-shadow-lg flex flex-col text-center items-center">
        <h1 className="text-7xl text-violet font-extrabold ">404</h1>
        <h2 className="text-3xl text-dark-gray font-bold">
          Oops, Page not found!
        </h2>
      </div>
      <Link to="/">
        <p className="text-aqua hover:underline text-lg">Go to Homepage</p>
      </Link>
    </section>
  );
};

export default Page404;
