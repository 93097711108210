// const categoriesData = [
//     "Aeronautical Engineering",
//     "Aerospace Engineering",
//     "Agriculture",
//     "Apparel & Fashion Design",
//     "Applied Mathematics",
//     "Applied science",
//     "Applied Mathematics and Computational Sciences",
//     "Architecture and design",
//     "Astronomy",
//     "Automobile Engineering",
//     "Biology",
//     "Biomedical Engineering",
//     "Biotechnology Engineering",
//     "Business",
//     "Ceramic Engineering",
//     "Chemical Engineering",
//     "Chemistry",
//     "Civil Engineering",
//     // "Comics studies",
//     "Communications Engineering",
//     "Comparative literature",
//     "Computer Application",
//     // "Computer Science",
//     // "Computer Science Engineering",
//     "Computer Science and Engineering",
//     "Construction Engineering",
//     "Creative writing",
//     "Earth sciences",
//     "Economics",
//     "Education",
//     "Electrical and Electronics Engineering",
//     "Electrical Engineering",
//     "Electronics and Communication Engineering",
//     "Electronics Engineering",
//     "English",
//     "English literature",
//     "Environmental Engineering",
//     "Environmental studies and forestry",
//     "Fashion Technology",
//     "Geography",
//     "Geology",
//     "Geophysics",
//     "Geosciences",
//     "History of literature",
//     "Humanities",
//     "Industrial Engineering",
//     "Information Technology",
//     "Instrumentation and Control Engineering",
//     "Linguistics",
//     "Literature thoery",
//     "Management Sciences",
//     "Marine Engineering",
//     "Mathematics",
//     "Mechanical Engineering",
//     "Mechatronics Engineering",
//     "Medicine and health",
//     "Metallurgical Engineering",
//     "Military sciences",
//     "Mining Engineering",
//     "Petroleum Engineering",
//     "Physics",
//     // "Poetry",
//     "Power Engineering",
//     "Production Engineering",
//     "Robotics and Automation Engineering",
//     "Space sciences",
//     "Statistics",
//     "Structural Engineering",
//     "Telecommunication Engineering",
//     "Textile Engineering",
//     "Tool Engineering",
//     "Transportation Engineering",
//     "World literature",
// ];

const categoriesData = [
    "Automation and Digital Transformation",
    "Green Energy and Sustainable Development",
    "Disruptive Technologies in Computing",
    "Electronic Systems Technology",
    "Advances in Biomaterials and Biomedical Technology",
    "Theoretical and Computational Sciences",
    "Humanities",
];


export default categoriesData;
