import React, { useState, useEffect, useContext } from "react";
import Inputfield from "../../components/Forms/Inputfield";
import { validateEmail } from "../../components/Forms/validations";
import PaperDetails from "./PaperDetails";
import { IoMdClose } from "react-icons/io";
import ErrorDialog from "../../components/Forms/ErrorDialog";
import { SiMinutemailer } from "react-icons/si";
import { dummyPaperDetails } from "../../data/dummyPaperDetails";
import dummyReviewers from "../../data/dummyReviewers";
import dummyReviewerPanel from "../../data/dummyReviewerPanel";
import TextArea from "../../components/Forms/TextArea";
import dummyCommentsData from "../../data/dummyCommentsData";
import TextOutput from "../../components/Forms/TextOutput";
import { BiCheck } from 'react-icons/bi';
import API from "../../components/Api/Axios";
import { CHAIR_COMMENT_SUBMISSION, CHAIR_GET_SUBMISSION } from "../../components/Api/Endpoints";
import { PopupWindowContext } from "../../components/PopupWindow/PopupWindow";
import { useNavigate } from "react-router-dom";
import { PopupContext } from "../../components/PopupSystem/Popup";
import { chairAcceptComment } from "../../data/reviewerCommetData";

const RevisionWindow = ({ paperID, navigate }) => {

  const [, dispatch] = useContext(PopupWindowContext);
  const [, dispatchPopup] = useContext(PopupContext);

  const [paperDetails, setPaperDetails] = useState({
    manuscriptId: "",
    title: "",
    authorName: ""
  });

  // useEffect(() => {
  //   setPaperDetails(dummyPaperDetails(paperID));
  // }, []);

  const [reviewInput, setReviewInput] = useState(chairAcceptComment);
  const [reviewInputError, setReviewInputError] = useState("");

  const [comments, setComments] = useState([]);

  // useEffect(() => {
  //   setComments(dummyCommentsData);
  // }, []);

  useEffect(() => {

    API.get(CHAIR_GET_SUBMISSION.replace(':id', paperID))
      .then((res) => {
        // console.log(res.data);
        const data = res.data.data;
        if (data) {
          const comments = data.comments;
          setComments(comments);

          if (comments.length > 0) {
            const filtered = comments.filter((comment) => comment.name === "CHAIR");
            if (filtered.length > 0) {
              setReviewInput(filtered[0].comment);
            }
          }

          setPaperDetails({ manuscriptId: data.manuscriptId, title: data.title, authorName: data.authorName });
        }
      })
      .catch((err) => {
        // console.log(err);
      })

  }, []);

  const handleConfirmSend = (e, type, isInternal, paperType) => {
    e.preventDefault();
    API.post(CHAIR_COMMENT_SUBMISSION, { id: paperID, comment: reviewInput, type, isInternal, paperType })
      .then((res) => {
        dispatch({
          type: "HIDE",
        });
        navigate('/admin');
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  return (
    <div className="">
      <h2 className="text-dark-gray text-sm">Paper ID: {paperDetails.manuscriptId}</h2>
      <h1 className="font-bold text-2xl text-slate">{paperDetails.title}</h1>
      <h2 className="mt-2 text-slate">
        Posted By: <b>{paperDetails.authorName}</b>
      </h2>
      <div className="flex flex-col items-center lg:items-start w-full mt-4">
        <TextArea
          valueState={[reviewInput, setReviewInput]}
          errorState={[reviewInputError, setReviewInputError]}
          placeholder="Max words 200."
          title="Your Review"
          className="flex-1 w-full"
        />
        <div className="flex flex-col lg:flex-row lg:space-x-2 mt-4 w-full flex-wrap">
          {comments.filter((cmnt) => cmnt.name !== "CHAIR").map((cmnt) => (
            <div className="w-96">
              {/* <div className={`flex flex-col items-start justify-center font-nunito space-y-2`}> */}
              <label className="text-dark-gray">{cmnt.name}</label>
              <div className="flex space-x-2 items-center w-full">
                <textarea
                  disabled={true}
                  value={cmnt.comment}
                  wrap="soft"
                  className={`h-48 px-4 py-2 w-full rounded-lg text-slate bg-cloud bg-clip-padding bg-no-repeat first-letter:transition ease-in-out m-0 focus:outline-none focus:border-mid-violet`}
                />
              </div>
              {/* </div> */}
            </div>
          ))}
        </div>
        <div className="flex flex-col lg:flex-row lg:space-x-4 items-center ">
          <button
            onClick={(e) => {
              e.preventDefault();
              setReviewInput("");
              setReviewInputError("");
            }}
            className="my-4 px-16 lg:px-8 py-2 text-lg border-2 border-violet text-violet bg-white font-semibold w-fit rounded-lg shadow-lg"
          >
            Discard Information
          </button>
          <button
            onClick={(e) => handleConfirmSend(e, "REVISE", false, "STUDENT_PAPER")}
            className="my-4 px-16 lg:px-8 py-2 text-lg border-2 border-violet bg-violet text-white font-semibold w-fit rounded-lg shadow-lg"
          >
            Send to Revision
          </button>
          <button
            onClick={(e) => handleConfirmSend(e, "REJECT", false, "STUDENT_PAPER")}
            className="my-4 px-16 lg:px-8 py-2 text-lg border-2 border-red bg-red text-white font-semibold w-fit rounded-lg shadow-lg"
          >
            Reject
          </button>
        </div>
        <div className="flex flex-col lg:flex-row lg:space-x-4 items-center ">
          <button
            onClick={(e) => handleConfirmSend(e, "ACCEPT", true, "STUDENT_PAPER")}
            className="my-4 px-16 lg:px-8 py-2 text-lg border-2 border-green bg-green text-white font-semibold w-fit rounded-lg shadow-lg"
          >
            Accept
          </button>
          {/* <button
            onClick={(e) => handleConfirmSend(e, "ACCEPT", false, "STUDENT_PAPER")}
            className="my-4 px-16 lg:px-8 py-2 text-lg border-2 border-green bg-green text-white font-semibold w-fit rounded-lg shadow-lg"
          >
            External Paper / Student
          </button>
          <button
            onClick={(e) => handleConfirmSend(e, "ACCEPT", false, "FACULTY_PAPER")}
            className="my-4 px-16 lg:px-8 py-2 text-lg border-2 border-green bg-green text-white font-semibold w-fit rounded-lg shadow-lg"
          >
            External Paper / Faculty
          </button>
          <button
            onClick={(e) => handleConfirmSend(e, "ACCEPT", false, "INDUSTRY_PAPER")}
            className="my-4 px-16 lg:px-8 py-2 text-lg border-2 border-green bg-green text-white font-semibold w-fit rounded-lg shadow-lg"
          >
            External Paper / Industry
          </button> */}
        </div>
        {/* <button
          onClick={(e) => {
            // dispatch({})
            dispatchPopup({
              type: "SHOW",
              payload: {
                title: "Select the type of paper",
                body: "Internal Paper - Any of the authors belong to PSG College of Technology. External Paper - Any of the authors don't belong to PSG College of Technology.",
                theme: "green",
                icon: <BiCheck />,
                successText: "External Paper",
                cancelText: "Internal Paper",
                handleSuccess: function (e) {
                  handleConfirmSend(e, "ACCEPT", false);
                  dispatchPopup({
                    type: "HIDE",
                  });
                },
                handleCancel: function (e) {
                  handleConfirmSend(e, "ACCEPT", true);
                  dispatchPopup({
                    type: "HIDE",
                  });
                },
              },
            });
          }}
          className="my-4 px-16 lg:px-8 py-2 text-lg border-2 border-green bg-green text-white font-semibold w-fit rounded-lg shadow-lg"
        >
          Accept
        </button> */}
      </div>
    </div>
  );
};

export default RevisionWindow;
