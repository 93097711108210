import { useState } from "react";
import Dropdown from "../../components/Forms/Dropdown";
import Inputfield from "../../components/Forms/Inputfield";
import { collegeData } from "../../data/collegeData";
import { departmentData } from "../../data/departmentData";
import API from '../../components/Api/Axios';
import { SUBMISSION_ADD_CERTIFICATE_DETAILS } from "../../components/Api/Endpoints";

const CertificateDetails = ({ dispatchWindow, submissionId, authors }) => {

    const [authorDetails, setAuthorDetails] = useState(authors);

    const handleSave = (e) => {
        e.preventDefault();
        console.log(submissionId);
        API.post(SUBMISSION_ADD_CERTIFICATE_DETAILS, { submissionId, certificateDetails: authorDetails })
            .then((res) => {
                console.log(res);
            })
            .catch((err) => {
                console.log(err);
            });

        dispatchWindow({
            type: 'HIDE'
        });

    }

    return (
        <div className="">
            <div className="flex flex-row flex-wrap space">
                {
                    authorDetails.map((details, index) => (
                        <div className="flex flex-col space-y-2 w-1/2 p-2">
                            <div className="flex flex-row space-x-1">
                                <Dropdown
                                    valueState={[details.title, (v) => {
                                        const currentAuthor = authorDetails[index];
                                        currentAuthor.title = v;
                                        setAuthorDetails([...authors.slice(0, index), currentAuthor, ...authors.slice(index + 1)]);
                                    }]}
                                    placeholder=""
                                    title="Title"
                                    className="w-1/5"
                                    options={["Mr.", "Dr.", "Ms.", "Prof."]}
                                />
                                <Inputfield
                                    valueState={[details.name, (v) => {
                                        const currentAuthor = authorDetails[index];
                                        currentAuthor.name = v;
                                        setAuthorDetails([...authors.slice(0, index), currentAuthor, ...authors.slice(index + 1)]);
                                    }]}
                                    placeholder="Name"
                                    type="text"
                                    title="Name"
                                    className="w-4/5"
                                />
                            </div>
                            <div className="flex flex-row space-x-1">
                                <Dropdown
                                    valueState={[details.designation, (v) => {
                                        const currentAuthor = authorDetails[index];
                                        currentAuthor.designation = v;
                                        setAuthorDetails([...authors.slice(0, index), currentAuthor, ...authors.slice(index + 1)]);
                                    }]}
                                    placeholder=""
                                    title="Designation"
                                    className="w-1/3"
                                    options={["PG Student", "UG Student", "Research Scholar", "Assistant Professor", "Associate Professor", "Professor", "Lecturer", "Sr. Lecturer", "Co-Founder"]}
                                />
                                <Dropdown
                                    valueState={[details.collegeOrOrganizationName, (v) => {
                                        const currentAuthor = authorDetails[index];
                                        currentAuthor.collegeOrOrganizationName = v;
                                        setAuthorDetails([...authors.slice(0, index), currentAuthor, ...authors.slice(index + 1)]);
                                    }]}
                                    placeholder=""
                                    title="College / Org Name"
                                    className="w-1/3"
                                    options={collegeData}
                                />
                                <Dropdown
                                    valueState={[details.department, (v) => {
                                        const currentAuthor = authorDetails[index];
                                        currentAuthor.department = v;
                                        setAuthorDetails([...authors.slice(0, index), currentAuthor, ...authors.slice(index + 1)]);
                                    }]}
                                    placeholder=""
                                    title="Department"
                                    className="w-1/3"
                                    options={departmentData}
                                />
                            </div>
                        </div>
                    ))
                }
            </div>
            <button
                onClick={handleSave}
                className="my-4 px-16 lg:px-8 py-2 text-lg bg-violet text-white font-semibold w-fit rounded-lg shadow-lg"
            >
                Save
            </button>
        </div>
    );

}

export default CertificateDetails;