import React from "react";
import { Link } from "react-router-dom";
import { FiGithub } from "react-icons/fi";

const ContactUs = () => {
  return (
    <section className="pt-20 lg:pt-6 lg:px-[calc(100vw/12)] pb-6 font-nunito flex flex-col divide-y-2 lg:divide-y-0 lg:divide-x-2 lg:flex-row divide-gray h-full items-center">
      <article className="w-full lg:w-1/2 space-y-4 h-full p-6">
        <h1 className="text-3xl font-bold">Development Team</h1>
        <div className="w-full">
          <p className="text-dark-gray mb-2 text-lg">Faculty-in-charge</p>
          <p className="text-xl font-bold text-slate mt-4">
            Dr. V. Senthil Kumaran
          </p>
          <p className="text-slate text-xs">
            Department of Applied Mathematics and Computer Science,
            <br />
            PSG College of Technology
          </p>
        </div>
        <div className="w-fit">
          <p className="text-dark-gray mb-2 text-lg">Developers</p>
          <div className="flex items-center w-full mt-4 space-x-8">
            <p className="text-xl font-bold text-slate ">P. Rhubanraj</p>
            <a
              href="https://github.com/mrRhuezzler/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="">
                <FiGithub size={18} />
              </button>
            </a>
          </div>
          <p className="text-slate text-xs">
            Department of Applied Mathematics and Computer Science,
            <br />
            PSG College of Technology
          </p>
          <div className="flex items-center w-full mt-4 space-x-8">
            <p className="text-xl font-bold text-slate ">T. G. Ashwin Kumar</p>
            <a
              href="https://github.com/tgashwinkumar"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="">
                <FiGithub size={18} />
              </button>
            </a>
          </div>
          <p className="text-slate text-xs">
            Department of Computer Science and Engineering,
            <br />
            PSG College of Technology
          </p>
        </div>
      </article>
      <article className="w-full lg:w-1/2 h-full p-6 space-y-4">
        <h1 className="text-3xl font-bold">For Contact</h1>
        <h2 className="text-lg text-dark-gray ">
          For any queries or feedback related to submission portal:
        </h2>
        <div className="w-full">
          <p className="text-xl font-bold text-slate mt-4">
            Dr. V. Senthil Kumaran
          </p>
          <p className="text-slate text-xs">
            Department of Applied Mathematics and Computer Science,
            <br />
            PSG College of Technology
          </p>
          <p className="text-slate text-sm">
            Email:{" "}
            <a
              className="text-aqua hover:underline"
              href="mailto:vsk.amcs@psgtech.ac.in"
            >
              vsk.amcs@psgtech.ac.in
            </a>{" "}
          </p>
        </div>
      </article>
    </section>
  );
};

export default ContactUs;
