import React, { useState, useEffect, useContext } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import API from "../../components/Api/Axios";
import { AUTH_LOGIN, AUTH_REFRESH_TOKEN } from "../../components/Api/Endpoints";
import Inputfield from "../../components/Forms/Inputfield";
import { PopupContext } from "../../components/PopupSystem/Popup";
import { Buffer } from "buffer";
import { AuthContext } from "../../components/Auth/AuthContext";
import ErrorDialog from "../../components/Forms/ErrorDialog";
import PhonenumberInput from "../../components/Forms/PhonenumberInput";
import DelegateRegistration from "../Delegate/DelegateRegistration";
import { PopupWindowContext } from "../../components/PopupWindow/PopupWindow";

const Login = () => {

  const { auth, setAuthState } = useContext(AuthContext);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const [loginError, setLoginError] = useState("");

  const [, dispatch] = useContext(PopupContext);

  const [, dispatchWindow] = useContext(PopupWindowContext);

  const navigate = useNavigate();

  const handleLogin = (e) => {
    e.preventDefault();

    const basicAuthString = `${email}:${password}`;
    const base64Encoded = Buffer.from(basicAuthString, "utf-8").toString(
      "base64"
    );
    // console.log(base64Encoded);

    setLoginError("");

    API.get(AUTH_LOGIN, {
      headers: {
        Authorization: "Basic " + base64Encoded,
      },
    })
      .then((res) => {
        const data = res.data.data;
        setAuthState(data);

        if (data.role === "AUTHOR") {
          navigate("/");
        } else if (data.role === "CHAIR" || data.role === "ADMIN") {
          // console.log(data.role);
          navigate("/admin");
        }
      })
      .catch((err) => {
        // TODO: Add conditions here for login errors according to API response
        setLoginError("Wrong credentials. Please try again.");
      });
  };

  useEffect(() => {
    if (auth) {
      if (auth.role === "CHAIR" || auth.role === "ADMIN") {
        navigate("/admin");
      } else {
        navigate("/");
      }
    }
  }, [auth]);

  return (
    <section className="pt-20 lg:pt-6 lg:px-[calc(100vw/12)] pb-6 font-nunito flex flex-col divide-y-2 lg:divide-y-0 lg:flex-row lg:divide-x-2 divide-gray h-full ">
      <article className="w-full lg:w-1/2 px-6 py-8 lg:flex flex-col justify-center">

        <div>
          <h2 className="text-dark-gray lg:text-lg">
            Welcome to the official submission portal of
          </h2>
          <h1 className="font-bold text-4xl mb-6">Research Conclave 2024</h1>
          <p className="text-dark-gray my-1">
            To begin with this site,{" "}
            <Link to="/login">
              <span className="text-aqua hover:underline">Log in</span>
            </Link>{" "}
            with your user ID and password. If you are unsure about whether or not
            you have an account, or if you have lost your password, goto the{" "}
            <Link to="/forgot-password">
              <span className="text-aqua hover:underline">Forgot Password</span>
            </Link>{" "}
            screen.
          </p>

          <div className="hidden lg:block">
            <p className="text-dark-gray my-2 text-lg">Don't have an account ?</p>
            <Link to="/register">
              <button className="my-1 px-8 py-2 text-lg bg-violet text-white font-semibold w-fit rounded-lg shadow-lg">
                Register
              </button>
            </Link>
          </div>
        </div>
        {/* <div>
          <h2 className="text-dark-gray lg:text-lg">
            Register to be a part of
          </h2>
          <h1 className="font-bold text-4xl mb-6">National Conference on Recent Advances in Construction Materials and Structures.</h1>
          <p className="text-dark-gray my-1">
            You can register yourself, to witness speakers present their noble works in various fields. For registrations, kindly fill the form below.
          </p>
          <button
            className="my-1 px-8 py-2 text-lg bg-violet text-white font-semibold w-fit rounded-lg shadow-lg"
          >
            Comming Soon !
          </button>
          <button
            className="my-1 px-8 py-2 text-lg bg-violet text-white font-semibold w-fit rounded-lg shadow-lg"
            onClick={(e) => {
              e.preventDefault();
              dispatchWindow({
                type: "SHOW",
                payload: {
                  title: "Register",
                  element: (<DelegateRegistration dispatchWindow={dispatchWindow}></DelegateRegistration>),
                  handleClose: function (e) {
                    e.preventDefault();
                    dispatchWindow({
                      type: "HIDE",
                    });
                  },
                },
              });
            }}
          >
            Sign me in
          </button>
        </div> */}
      </article>

      <article className="w-full lg:w-1/2 py-8 flex flex-col justify-center items-center lg:items-start px-6 space-y-4">
        <h1 className="font-bold text-4xl w-full">Login</h1>
        <ErrorDialog>{loginError}</ErrorDialog>
        <Inputfield
          errorState={[emailError, setEmailError]}
          valueState={[email, setEmail]}
          placeholder="Enter email here"
          type="email"
          title="Email"
        />
        <Inputfield
          errorState={[passwordError, setPasswordError]}
          valueState={[password, setPassword]}
          placeholder="Enter password here"
          type="password"
          title="Password"
        />
        <button
          onClick={(e) => handleLogin(e)}
          className="my-1 px-16 lg:px-8 py-2 text-lg bg-violet text-white font-semibold w-fit rounded-lg shadow-lg"
        >
          Sign In
        </button>
        <div className="h-[2px] w-full bg-gray lg:hidden"></div>
        <div className="flex flex-col items-center w-full lg:hidden">
          <p className="text-dark-gray my-2 text-lg">Don't have an account ?</p>
          <Link to="/register">
            <button className="my-1 px-16 lg:px-8 py-2 text-lg bg-violet text-white font-semibold w-fit rounded-lg shadow-lg">
              Create a new account
            </button>
          </Link>
        </div>
      </article>
    </section>
  );
};

export default Login;
