import React, { useContext, useEffect } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import Navbar from "../../components/Navbar";
import { HiOutlineDocumentSearch, HiOutlineBell } from "react-icons/hi";
import { AuthContext } from "../../components/Auth/AuthContext";
const Home = () => {

  const { auth } = useContext(AuthContext);
  const navigate = useNavigate();

  const location = useLocation();

  useEffect(() => {
    if (auth === null || auth === undefined || auth === {}) {
      navigate('/login');
    }
  }, [auth])

  return (
    <section className="pt-20 lg:pt-6 lg:px-[calc(100vw/12)] pb-6 font-nunito flex flex-col divide-y-2 lg:divide-y-0 lg:flex-row divide-gray h-full">
      <nav className="w-full lg:w-1/5 px-6 py-8 lg:flex flex-col space-y-2">
        <Link to="/notifications">
          <button
            className={`flex items-center space-x-4 w-full px-2 py-1 rounded-md ${location.pathname === "/" ||
              location.pathname === "/notifications"
              ? "bg-light-violet text-violet"
              : "text-slate"
              }`}
          >
            <HiOutlineBell />
            <p className="text-left">Notifications</p>
          </button>
        </Link>
        <Link to="/submissions">
          <button
            className={`flex items-center space-x-4 w-full px-2 py-1 rounded-md ${location.pathname === "/submissions"
              ? "bg-light-violet text-violet"
              : "text-slate"
              }`}
          >
            <HiOutlineDocumentSearch />
            <p className="text-left">Submissions</p>
          </button>
        </Link>
        {/* {location.pathname.startsWith("/submission-details") &&
          <button
            className={`flex items-center space-x-4 w-full px-2 py-1 rounded-md ${location.pathname.startsWith("/submission-details")
              ? "bg-light-violet text-violet"
              : "text-slate"
              }`}
          >
            <HiOutlineDocumentSearch />
            <p className="text-left">Submission Details</p>
          </button>} */}
      </nav>
      <article className="w-full lg:w-4/5 px-6 py-8 h-full">
        <Outlet />
      </article>
    </section>
  );
};

export default Home;
